/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */
/* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/no-shadow, no-use-before-define */

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  FileUpload: any;
  GUID: GUID;
  IdentityKey: Key;
  Instant: Instant;
  JsonString: JsonString;
  Key: Key;
  LocalDate: LocalDate;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: number;
  /** The name scalar represents a valid GraphQL name as specified in the spec and can be used to refer to fields or types. */
  Name: string;
  Offset: any;
  OlsonTimeZone: OlsonTimeZone;
  WindowsTimeZone: WindowsTimeZone;
};

export enum AccountStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  Forgottenpassword = 'FORGOTTENPASSWORD',
  Frozen = 'FROZEN',
  Locked = 'LOCKED',
  Pending = 'PENDING',
  Transient = 'TRANSIENT',
  Unknown = 'UNKNOWN',
}

export enum AccountType {
  Customer = 'CUSTOMER',
  EndUser = 'END_USER',
  Legacy = 'LEGACY',
  PushpayAdmin = 'PUSHPAY_ADMIN',
}

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['Instant']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  primary: Scalars['Boolean'];
  state?: Maybe<Scalars['String']>;
  type: AddressType;
};

export type AddressArguments = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  primary?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AddressType>;
};

export enum AddressType {
  Home = 'HOME',
  Mailing = 'MAILING',
  Other = 'OTHER',
  Unknown = 'UNKNOWN',
  Work = 'WORK',
}

export type AdministeredOrganization = {
  __typename?: 'AdministeredOrganization';
  isMerchantAdmin: Scalars['Boolean'];
  key: Scalars['String'];
  logoUrl: Scalars['String'];
  name: Scalars['String'];
};

export enum AgeCategory {
  Adult = 'ADULT',
  Child = 'CHILD',
  Unknown = 'UNKNOWN',
}

export type Aggregations = {
  __typename?: 'Aggregations';
  avg?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  sum?: Maybe<Scalars['Float']>;
};

export type AggregationsAvgArgs = {
  applyWithin?: InputMaybe<Scalars['Boolean']>;
  field: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export type AggregationsCountArgs = {
  applyWithin?: InputMaybe<Scalars['Boolean']>;
  field: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export type AggregationsMaxArgs = {
  applyWithin?: InputMaybe<Scalars['Boolean']>;
  field: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export type AggregationsMinArgs = {
  applyWithin?: InputMaybe<Scalars['Boolean']>;
  field: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export type AggregationsSumArgs = {
  applyWithin?: InputMaybe<Scalars['Boolean']>;
  field: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export type AllIdentityPermissions = {
  __typename?: 'AllIdentityPermissions';
  organizationPermissions: Array<OrganizationPermissions>;
  permissions: Array<Permission>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
}

export type Attendance = {
  __typename?: 'Attendance';
  averageAttendance?: Maybe<Scalars['Int']>;
  checkinAttendance: Scalars['Int'];
  familyAttendance: Scalars['Int'];
  firstTimeGuests: Scalars['Int'];
  headCountAttendance: Scalars['Int'];
  individualAttendance: Scalars['Int'];
  servingAttendance: Scalars['Int'];
  streamingAttendance: Scalars['Int'];
  totalAttendance: Scalars['Int'];
  uniqueFamilyAttendance: Scalars['Int'];
  uniqueIndividualAttendance: Scalars['Int'];
};

export type AttendanceComparisonHighlight = {
  __typename?: 'AttendanceComparisonHighlight';
  last: Scalars['Int'];
  previous?: Maybe<Scalars['Int']>;
};

export type AttendanceOverviewInput = {
  attendanceQueryFilters?: InputMaybe<AttendanceQueryFilters>;
  dateRange: DateRangeInput;
  granularity: Granularity;
};

export type AttendanceQueryFilters = {
  attendanceGroupingNames?: InputMaybe<Array<Scalars['String']>>;
  attendanceType?: InputMaybe<Array<AttendanceType>>;
  campusNames?: InputMaybe<Array<Scalars['String']>>;
  departmentNames?: InputMaybe<Array<Scalars['String']>>;
  eventDays?: InputMaybe<Array<Scalars['String']>>;
  eventNames?: InputMaybe<Array<Scalars['String']>>;
  eventStartTimes?: InputMaybe<EventStartTimeInput>;
  groupNames?: InputMaybe<Array<Scalars['String']>>;
  groupTypeNames?: InputMaybe<Array<Scalars['String']>>;
  membershipTypeIds?: InputMaybe<MembershipTypeIdsInput>;
};

export type AttendanceQueryFiltersOutput = {
  __typename?: 'AttendanceQueryFiltersOutput';
  attendanceGroupingNames: Array<Scalars['String']>;
  campusNames: Array<Scalars['String']>;
  departmentNames: Array<Scalars['String']>;
  eventNames: Array<Scalars['String']>;
  groupNames: Array<Scalars['String']>;
  groupTypeNames: Array<Scalars['String']>;
  membershipTypeIds: Array<Scalars['Int']>;
  streamingEventNames: Array<Scalars['String']>;
};

export enum AttendanceStatus {
  FirstTime = 'FirstTime',
  Lapsed = 'Lapsed',
  NonAttenders = 'NonAttenders',
  Occasional = 'Occasional',
  Regular = 'Regular',
  SecondTime = 'SecondTime',
}

export enum AttendanceType {
  Headcount = 'Headcount',
  Individual = 'Individual',
  Streaming = 'Streaming',
}

export enum AttenderListType {
  All = 'All',
  AtRisk = 'AtRisk',
  Curious = 'Curious',
  FirstTime = 'FirstTime',
  Lapsed = 'Lapsed',
  NonAttenders = 'NonAttenders',
  Occasional = 'Occasional',
  Potential = 'Potential',
  PotentialInactive = 'PotentialInactive',
  RecentNew = 'RecentNew',
  Regular = 'Regular',
  Returning = 'Returning',
  SecondTime = 'SecondTime',
}

export type Campus = {
  __typename?: 'Campus';
  key: Scalars['String'];
  name: Scalars['String'];
};

export type CampusPermissions = {
  __typename?: 'CampusPermissions';
  campusKey: Scalars['Key'];
  permissions: Array<Permission>;
};

export type CampusesPagedOutput = {
  __typename?: 'CampusesPagedOutput';
  items: Array<Chms_Campus>;
  paging: CursorPagingOutput;
};

export type CancelExportJobInput = {
  jobKey: Scalars['String'];
};

export enum CatholicEntity {
  Cluster = 'CLUSTER',
  Diocese = 'DIOCESE',
  Other = 'OTHER',
  Parish = 'PARISH',
  Unknown = 'UNKNOWN',
}

export type CcbIndividual = {
  __typename?: 'CcbIndividual';
  deepLinkUrl: Scalars['String'];
  individualId: Scalars['Int'];
};

export type CcbMembershipTypeFilterInput = {
  ccbMembershipTypeIds?: InputMaybe<Array<Scalars['Long']>>;
  filterMethod: FilterMethod;
};

export type ChMsOrganization = {
  __typename?: 'ChMSOrganization';
  campuses: CampusesPagedOutput;
  chmsOrgId: Scalars['Int'];
  membershipTypes: Array<MembershipType>;
  processes: ProcessesPagedOutput;
  queues: ProcessQueuesPagedOutput;
};

export type ChMsOrganizationCampusesArgs = {
  paging: Chms_CursorPagingInput;
};

export type ChMsOrganizationProcessesArgs = {
  paging: Chms_CursorPagingInput;
  processSearchInput?: InputMaybe<ProcessSearchInput>;
};

export type ChMsOrganizationQueuesArgs = {
  paging: Chms_CursorPagingInput;
  processQueueSearchInput: ProcessQueueSearchInput;
};

export type ChmsInfo = {
  __typename?: 'ChmsInfo';
  contractStatus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isProvisioned: Scalars['Boolean'];
  tenantUrl?: Maybe<Scalars['String']>;
};

export type ClientField = {
  __typename?: 'ClientField';
  field: Scalars['String'];
};

export type Community = {
  __typename?: 'Community';
  ageOfIndependence: Scalars['Int'];
  isInSync: Scalars['Boolean'];
  isSyncEnabled: Scalars['Boolean'];
  isSyncRunning: Scalars['Boolean'];
};

export type CommunityMember = {
  __typename?: 'CommunityMember';
  address?: Maybe<Address>;
  addresses: Array<Address>;
  age?: Maybe<Scalars['Int']>;
  ageCategory?: Maybe<AgeCategory>;
  archived: Scalars['Boolean'];
  archivedOn?: Maybe<Scalars['Instant']>;
  campusKey?: Maybe<Scalars['String']>;
  ccbIndividual?: Maybe<CcbIndividual>;
  ccbMembershipTypeId?: Maybe<Scalars['Int']>;
  community: Community;
  communityMemberKey: Scalars['String'];
  communityMemberType?: Maybe<CommunityMemberType>;
  createdOn?: Maybe<Scalars['Instant']>;
  dateOfBirth?: Maybe<DateStamp>;
  dateOfDeath?: Maybe<DateStamp>;
  dateStampOfBirth?: Maybe<DateStampOfBirth>;
  dateStampOfDeath?: Maybe<DateStampOfDeath>;
  deceased: Scalars['Boolean'];
  deceasedSetOn?: Maybe<Scalars['Instant']>;
  dependent?: Maybe<Scalars['Boolean']>;
  detailsUpdatedOn?: Maybe<Scalars['Instant']>;
  donor?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Email>;
  emailAddress?: Maybe<Scalars['String']>;
  emails: Array<Email>;
  engagement: CommunityMemberEngagement;
  externalIds: Array<ExternalId>;
  externalReference?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  formerLastNames: Array<FormerLastName>;
  gender?: Maybe<Gender>;
  householdMoveOutStatus: HouseholdMoveOutStatus;
  households: Array<Household>;
  lastActiveOn?: Maybe<Scalars['Instant']>;
  lastGiftFund?: Maybe<Fund>;
  lastGiftFundKey?: Maybe<Scalars['String']>;
  lastGiftMerchant?: Maybe<Merchant>;
  lastGiftMerchantId?: Maybe<Scalars['String']>;
  lastGiftMerchantIntegerId?: Maybe<Scalars['Long']>;
  lastName?: Maybe<Scalars['String']>;
  lastPaymentOn?: Maybe<Scalars['Instant']>;
  legalFirstName?: Maybe<Scalars['String']>;
  legalFullName?: Maybe<Scalars['String']>;
  lifetimeLargestGiftFundKey?: Maybe<Scalars['String']>;
  lifetimeLargestGiftMerchantId?: Maybe<Scalars['String']>;
  middleNames?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<PhoneNumber>;
  phoneNumbers: Array<PhoneNumber>;
  preferredFirstName?: Maybe<Scalars['String']>;
  preferredFullName?: Maybe<Scalars['String']>;
  preferredFullNameWithNickname?: Maybe<Scalars['String']>;
  preferredName?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  primaryCustomerInputExternalId?: Maybe<Scalars['String']>;
  primaryHousehold?: Maybe<Household>;
  profileCreatedOn?: Maybe<Scalars['Instant']>;
  profileImages?: Maybe<ProfileImages>;
  suffix?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  timeZoneId?: Maybe<Scalars['String']>;
  updatedOn?: Maybe<Scalars['Instant']>;
  yearToDateLargestGiftFundKey?: Maybe<Scalars['String']>;
  yearToDateLargestGiftMerchantId?: Maybe<Scalars['String']>;
  yearToDateLargestGiftMerchantIntegerId?: Maybe<Scalars['Long']>;
  ytdLargestGiftFund?: Maybe<Fund>;
  ytdLargestGiftMerchant?: Maybe<Merchant>;
};

export type CommunityMemberTagArgs = {
  current?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type CommunityMemberCollectionResult = {
  __typename?: 'CommunityMemberCollectionResult';
  communityMembers?: Maybe<Array<CommunityMember>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CommunityMemberDetailsArguments = {
  addresses: Array<AddressArguments>;
  age?: InputMaybe<Scalars['Int']>;
  ageCategory?: InputMaybe<AgeCategory>;
  dateOfBirth?: InputMaybe<DateStampArguments>;
  dateOfDeath?: InputMaybe<DateStampArguments>;
  dateStampOfBirth?: InputMaybe<DateStampOfBirthArguments>;
  dateStampOfDeath?: InputMaybe<DateStampOfDeathArguments>;
  deceased?: InputMaybe<Scalars['Boolean']>;
  dependent?: InputMaybe<Scalars['Boolean']>;
  emails: Array<EmailArguments>;
  externalReference?: InputMaybe<Scalars['String']>;
  formerLastNames: Array<FormerLastNameArguments>;
  gender?: InputMaybe<Gender>;
  lastName: Scalars['String'];
  legalFirstName: Scalars['String'];
  middleNames?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  phoneNumbers: Array<PhoneNumberArguments>;
  preferredFirstName?: InputMaybe<Scalars['String']>;
  prefix?: InputMaybe<Scalars['String']>;
  primaryCustomerInputExternalId?: InputMaybe<Scalars['String']>;
  suffix?: InputMaybe<Scalars['String']>;
};

export type CommunityMemberEngagement = {
  __typename?: 'CommunityMemberEngagement';
  isEngaged: Scalars['Boolean'];
  lastActiveOn?: Maybe<Scalars['Instant']>;
  lastAppActivity?: Maybe<Scalars['Instant']>;
  lastPayment?: Maybe<Scalars['Instant']>;
};

export type CommunityMemberResult = {
  __typename?: 'CommunityMemberResult';
  communityMember?: Maybe<CommunityMember>;
};

export enum CommunityMemberType {
  Individual = 'INDIVIDUAL',
  Organization = 'ORGANIZATION',
  Unknown = 'UNKNOWN',
}

export type ComparisonDatesInput = {
  last: DateRangeInput;
  previous?: InputMaybe<DateRangeInput>;
};

export type CreateExportJobInput = {
  attendanceStatus?: InputMaybe<Array<AttendanceStatus>>;
  campusKeys?: InputMaybe<Array<Scalars['ID']>>;
  donorStatus?: InputMaybe<Array<DonorStatus>>;
  membershipTypeIds?: InputMaybe<MembershipTypeIdsInput>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<ExportJobSortInput>;
  timeZoneId?: InputMaybe<Scalars['String']>;
  type: ExportTypeInput;
  viewBy: ExportJobViewBy;
  volunteerStatus?: InputMaybe<Array<VolunteerStatus>>;
};

export enum Currency {
  Aud = 'AUD',
  Cad = 'CAD',
  Nzd = 'NZD',
  Unspecified = 'UNSPECIFIED',
  Usd = 'USD',
}

export type CursorPagedOutput = {
  paging: CursorPagingOutput;
};

export type CursorPagedOutputOfTopCommunityMember = {
  __typename?: 'CursorPagedOutputOfTopCommunityMember';
  items: Array<TopCommunityMember>;
  paging: CursorPagingOutput;
};

export type CursorPagedOutputOfTopHousehold = {
  __typename?: 'CursorPagedOutputOfTopHousehold';
  items: Array<TopHousehold>;
  paging: CursorPagingOutput;
};

export type CursorPagingInput = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  size: Scalars['Int'];
  sortings?: InputMaybe<Array<InputMaybe<SortingInput>>>;
};

export type CursorPagingOutput = {
  __typename?: 'CursorPagingOutput';
  first?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  last?: Maybe<Scalars['String']>;
  size: Scalars['Int'];
  totalItemCount: Scalars['Int'];
};

export type CursorPagingOutputOfCommunityMember = CursorPagedOutput & {
  __typename?: 'CursorPagingOutputOfCommunityMember';
  aggregations: Aggregations;
  items: Array<CommunityMember>;
  paging: CursorPagingOutput;
};

export type CursorPagingOutputOfHousehold = CursorPagedOutput & {
  __typename?: 'CursorPagingOutputOfHousehold';
  aggregations: Aggregations;
  items: Array<Household>;
  paging: CursorPagingOutput;
};

export type DateRangeInput = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  timeZoneId: Scalars['String'];
};

export type DateStamp = {
  __typename?: 'DateStamp';
  day?: Maybe<Scalars['Int']>;
  month?: Maybe<MonthOfYear>;
  year?: Maybe<Scalars['Int']>;
};

export type DateStampArguments = {
  day?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<MonthOfYear>;
  year?: InputMaybe<Scalars['Int']>;
};

export type DateStampOfBirth = {
  __typename?: 'DateStampOfBirth';
  day: Scalars['Int'];
  month: MonthOfYear;
  year?: Maybe<Scalars['Int']>;
};

export type DateStampOfBirthArguments = {
  day: Scalars['Int'];
  month: MonthOfYear;
  year?: InputMaybe<Scalars['Int']>;
};

export type DateStampOfDeath = {
  __typename?: 'DateStampOfDeath';
  day?: Maybe<Scalars['Int']>;
  month?: Maybe<MonthOfYear>;
  year: Scalars['Int'];
};

export type DateStampOfDeathArguments = {
  day?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<MonthOfYear>;
  year: Scalars['Int'];
};

export type DeleteExportJobInput = {
  jobKey: Scalars['String'];
};

export enum DonorListType {
  All = 'All',
  AtRisk = 'AtRisk',
  FirstTime = 'FirstTime',
  Lapsed = 'Lapsed',
  NewRecurring = 'NewRecurring',
  NonDonors = 'NonDonors',
  Occasional = 'Occasional',
  Potential = 'Potential',
  RecentNew = 'RecentNew',
  Recurring = 'Recurring',
  Regular = 'Regular',
  SecondTime = 'SecondTime',
  TopDonors = 'TopDonors',
}

export enum DonorStatus {
  FirstTime = 'FirstTime',
  Lapsed = 'Lapsed',
  NonDonors = 'NonDonors',
  Occasional = 'Occasional',
  Recurring = 'Recurring',
  Regular = 'Regular',
  SecondTime = 'SecondTime',
}

export type Email = {
  __typename?: 'Email';
  address: Scalars['String'];
  createdOn?: Maybe<Scalars['Instant']>;
  primary: Scalars['Boolean'];
  type: EmailType;
  verified: Scalars['Boolean'];
};

export type EmailAddressValidationError = IValidationError & {
  __typename?: 'EmailAddressValidationError';
  fieldPath: Array<Scalars['String']>;
};

export type EmailArguments = {
  address: Scalars['String'];
  primary: Scalars['Boolean'];
  type: EmailType;
};

export type EmailContact = {
  __typename?: 'EmailContact';
  emailAddress?: Maybe<Scalars['String']>;
  verified?: Maybe<Scalars['Boolean']>;
};

export enum EmailType {
  Family = 'FAMILY',
  Other = 'OTHER',
  Personal = 'PERSONAL',
  Unknown = 'UNKNOWN',
  Work = 'WORK',
}

export type Entitlement = {
  __typename?: 'Entitlement';
  displayName: Scalars['String'];
  key: EntitlementKey;
};

export enum EntitlementKey {
  ApplePay = 'APPLE_PAY',
  AppBlankify = 'APP_BLANKIFY',
  AppChms = 'APP_CHMS',
  AppContent = 'APP_CONTENT',
  AppCustomization = 'APP_CUSTOMIZATION',
  AppCustomizationAdvanced = 'APP_CUSTOMIZATION_ADVANCED',
  AppDashboard = 'APP_DASHBOARD',
  AppDashboardAdvanced = 'APP_DASHBOARD_ADVANCED',
  AppDynamicHomeScreen = 'APP_DYNAMIC_HOME_SCREEN',
  AppGivingDraggable = 'APP_GIVING_DRAGGABLE',
  AppMyGiving = 'APP_MY_GIVING',
  AppMyList = 'APP_MY_LIST',
  AppPnAdvanced = 'APP_PN_ADVANCED',
  AppPnBasic = 'APP_PN_BASIC',
  AppProfile = 'APP_PROFILE',
  AppUsersInsights = 'APP_USERS_INSIGHTS',
  Campus = 'CAMPUS',
  DonorDevelopment = 'DONOR_DEVELOPMENT',
  GivingLocalization = 'GIVING_LOCALIZATION',
  Insights = 'INSIGHTS',
  Integrations = 'INTEGRATIONS',
  OneTimeFutureGifts = 'ONE_TIME_FUTURE_GIFTS',
  PreConfiguredGivingUrl = 'PRE_CONFIGURED_GIVING_URL',
  ProcessQueues = 'PROCESS_QUEUES',
  Unknown = 'UNKNOWN',
}

export type EventAttendance = {
  __typename?: 'EventAttendance';
  attendance: Attendance;
  attendanceGroupingName?: Maybe<Scalars['String']>;
  campusName?: Maybe<Scalars['String']>;
  departmentId?: Maybe<Scalars['Int']>;
  departmentName?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  eventName: Scalars['String'];
  groupId?: Maybe<Scalars['Int']>;
  groupName?: Maybe<Scalars['String']>;
  groupTypeName?: Maybe<Scalars['String']>;
  membershipTypeIds?: Maybe<Array<Scalars['Int']>>;
  occurrence: Scalars['DateTime'];
};

export type EventAttendanceSummary = {
  __typename?: 'EventAttendanceSummary';
  attendanceGroupingNames: Array<Scalars['String']>;
  attendanceSummary: Attendance;
  campusNames: Array<Scalars['String']>;
  departmentNames: Array<Scalars['String']>;
  endDate: Scalars['DateTime'];
  eventNames: Array<Scalars['String']>;
  groupNames: Array<Scalars['String']>;
  groupTypeNames: Array<Scalars['String']>;
  membershipTypeIds: Array<Scalars['Int']>;
  startDate: Scalars['DateTime'];
  total: Scalars['Int'];
};

export type EventAttendanceSummaryDataInput = {
  attendanceQueryFilters?: InputMaybe<AttendanceQueryFilters>;
  dateRange: DateRangeInput;
};

export type EventStartTimeInput = {
  maxTime?: InputMaybe<Scalars['String']>;
  minTime?: InputMaybe<Scalars['String']>;
  operator: TimeFilterOperator;
  timeZoneId: Scalars['String'];
  times?: InputMaybe<Array<Scalars['String']>>;
};

export type ExportJob = {
  __typename?: 'ExportJob';
  createdAt: Scalars['Float'];
  exportJobOptions: ExportJobOptions;
  exportLink?: Maybe<Scalars['String']>;
  exportSizeBytes?: Maybe<Scalars['Float']>;
  filename: Scalars['String'];
  jobKey: Scalars['String'];
  status: ExportJobStatus;
};

export type ExportJobCanceledOutput = {
  __typename?: 'ExportJobCanceledOutput';
  export: ExportJob;
};

export type ExportJobDeletedOutput = {
  __typename?: 'ExportJobDeletedOutput';
  export: ExportJob;
};

export type ExportJobOptions = {
  __typename?: 'ExportJobOptions';
  attendanceStatus?: Maybe<Array<AttendanceStatus>>;
  campusKeys?: Maybe<Array<Scalars['ID']>>;
  donorStatus?: Maybe<Array<DonorStatus>>;
  membershipTypeIds?: Maybe<MembershipTypeIdsOutput>;
  searchTerm?: Maybe<Scalars['String']>;
  sort?: Maybe<ExportJobSort>;
  type: ExportTypeOutput;
  viewBy: ExportJobViewBy;
  volunteerStatus?: Maybe<Array<VolunteerStatus>>;
};

export type ExportJobSort = {
  __typename?: 'ExportJobSort';
  columnName: Scalars['String'];
  direction: Scalars['String'];
};

export enum ExportJobSortColumns {
  AttendanceStatus = 'AttendanceStatus',
  Email = 'Email',
  FirstName = 'FirstName',
  GivingStatus = 'GivingStatus',
  HomeCampusName = 'HomeCampusName',
  HouseholdName = 'HouseholdName',
  LastName = 'LastName',
  Members = 'Members',
  ServingStatus = 'ServingStatus',
}

export enum ExportJobSortDirection {
  Asc = 'Asc',
  Desc = 'Desc',
}

export type ExportJobSortInput = {
  columnName: ExportJobSortColumns;
  direction: ExportJobSortDirection;
};

export enum ExportJobStatus {
  Canceled = 'Canceled',
  Deleted = 'Deleted',
  Failed = 'Failed',
  Pending = 'Pending',
  Queued = 'Queued',
  Success = 'Success',
}

export enum ExportJobViewBy {
  CommunityMember = 'CommunityMember',
  Household = 'Household',
}

export type ExportQueuedOutput = {
  __typename?: 'ExportQueuedOutput';
  export: ExportJob;
};

export type ExportTypeInput = {
  attenderListType?: InputMaybe<AttenderListType>;
  donorListType?: InputMaybe<DonorListType>;
  volunteerListType?: InputMaybe<VolunteerListType>;
};

export type ExportTypeOutput = {
  __typename?: 'ExportTypeOutput';
  attenderListType?: Maybe<AttenderListType>;
  donorListType?: Maybe<DonorListType>;
  volunteerListType?: Maybe<VolunteerListType>;
};

export type ExternalId = {
  __typename?: 'ExternalId';
  createdOn: Scalars['Instant'];
  primary: Scalars['Boolean'];
  type: ExternalIdType;
  value: Scalars['String'];
};

export enum ExternalIdType {
  Ccbfamilyid = 'CCBFAMILYID',
  Ccbmembershiptype = 'CCBMEMBERSHIPTYPE',
  Ccbperson = 'CCBPERSON',
  Customerinput = 'CUSTOMERINPUT',
  Unknown = 'UNKNOWN',
}

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
};

export type Filter = {
  applyWithin?: InputMaybe<Scalars['Boolean']>;
  field: Scalars['String'];
  isEmpty?: InputMaybe<Scalars['Boolean']>;
  max?: InputMaybe<Scalars['String']>;
  min?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
  values?: InputMaybe<Array<Scalars['String']>>;
};

export enum FilterMethod {
  Any = 'ANY',
  HasMembershipType = 'HAS_MEMBERSHIP_TYPE',
  NoMembershipType = 'NO_MEMBERSHIP_TYPE',
  Unknown = 'UNKNOWN',
}

export type FormerHouseholdMember = {
  __typename?: 'FormerHouseholdMember';
  communityMember: CommunityMember;
  createdOn: Scalars['Instant'];
  formerHouseholdMemberPosition: HouseholdMemberPosition;
};

export type FormerLastName = {
  __typename?: 'FormerLastName';
  changedAwayOn?: Maybe<Scalars['Instant']>;
  createdOn: Scalars['Instant'];
  lastName: Scalars['String'];
};

export type FormerLastNameArguments = {
  changedAwayOn?: InputMaybe<Scalars['Instant']>;
  lastName: Scalars['String'];
};

export type Fund = {
  __typename?: 'Fund';
  name?: Maybe<Scalars['String']>;
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Notspecified = 'NOTSPECIFIED',
  Other = 'OTHER',
  Unknown = 'UNKNOWN',
}

export type GivingOrganization = {
  __typename?: 'GivingOrganization';
  fund: Fund;
  merchant: Merchant;
};

export type GivingOrganizationFundArgs = {
  fundKey: Scalars['String'];
};

export type GivingOrganizationMerchantArgs = {
  merchantId: Scalars['Long'];
};

export type GranularTimeRangeInput = {
  fromDate: Scalars['String'];
  granularity: PaymentQueryGranularity;
  startDay: StartOfWeek;
  timeZoneId?: InputMaybe<Scalars['String']>;
  toDate: Scalars['String'];
};

export enum Granularity {
  Daily = 'Daily',
  Monthly = 'Monthly',
  Weekly = 'Weekly',
  Yearly = 'Yearly',
}

export type GroupParticipationComparisonHighlight = {
  __typename?: 'GroupParticipationComparisonHighlight';
  last: Scalars['Int'];
  previous?: Maybe<Scalars['Int']>;
};

export type GroupParticipationFilterInput = {
  campusNames?: InputMaybe<Array<Scalars['String']>>;
  departmentNames?: InputMaybe<Array<Scalars['String']>>;
  groupInteractionTypes?: InputMaybe<Array<Scalars['String']>>;
  groupNames?: InputMaybe<Array<Scalars['String']>>;
  groupTypeNames?: InputMaybe<Array<Scalars['String']>>;
  membershipTypeIds?: InputMaybe<MembershipTypeIdsInput>;
};

export type GroupParticipationFilterValuesOutput = {
  __typename?: 'GroupParticipationFilterValuesOutput';
  activeGroupNames: Array<Scalars['String']>;
  campusNames: Array<Scalars['String']>;
  departmentNames: Array<Scalars['String']>;
  groupNames: Array<Scalars['String']>;
  groupTypeNames: Array<Scalars['String']>;
  inactiveGroups: Array<InactiveGroup>;
  membershipTypeIds: Array<Scalars['Int']>;
};

export type GroupParticipationHighlights = {
  __typename?: 'GroupParticipationHighlights';
  groupLeadersTotal: Scalars['Int'];
  groupMembersTotal: Scalars['Int'];
  groupParticipantHouseholdsTotal: Scalars['Int'];
  groupParticipantHouseholdsUnique: Scalars['Int'];
  groupParticipantsAvg: Scalars['Int'];
  groupParticipantsAvgMedian: Scalars['Int'];
  groupParticipantsTotal: Scalars['Int'];
  groupsTotal: Scalars['Int'];
};

export type GroupParticipationOverviewInput = {
  dateRange: DateRangeInput;
  filters?: InputMaybe<GroupParticipationFilterInput>;
  granularity: Granularity;
};

export type GroupParticipationSuccessResult = {
  __typename?: 'GroupParticipationSuccessResult';
  success: Scalars['Boolean'];
};

export type GroupParticipationSummary = {
  __typename?: 'GroupParticipationSummary';
  campusNames: Array<Scalars['String']>;
  departmentNames: Array<Scalars['String']>;
  endDate: Scalars['DateTime'];
  groupNames: Array<Scalars['String']>;
  groupTypeNames: Array<Scalars['String']>;
  membershipTypeIds: Array<Scalars['String']>;
  startDate: Scalars['DateTime'];
  total: Scalars['Int'];
};

export type Household = {
  __typename?: 'Household';
  address?: Maybe<Address>;
  archived: Scalars['Boolean'];
  archivedOn?: Maybe<Scalars['Instant']>;
  campusKey?: Maybe<Scalars['String']>;
  community: Community;
  createdOn: Scalars['Instant'];
  donor: Scalars['Boolean'];
  email?: Maybe<Email>;
  externalIds: Array<ExternalId>;
  formerMembers: Array<FormerHouseholdMember>;
  householdKey: Scalars['String'];
  lastGiftFund?: Maybe<Fund>;
  lastGiftFundKey?: Maybe<Scalars['String']>;
  lastGiftMerchant?: Maybe<Merchant>;
  lastGiftMerchantId?: Maybe<Scalars['String']>;
  lastGiftMerchantIntegerId?: Maybe<Scalars['Long']>;
  lifetimeLargestGiftFundKey?: Maybe<Scalars['String']>;
  lifetimeLargestGiftMerchantId?: Maybe<Scalars['String']>;
  listName: Scalars['String'];
  members: Array<HouseholdMember>;
  name: Scalars['String'];
  numberOfMembers: Scalars['Int'];
  phone?: Maybe<PhoneNumber>;
  primaryContact?: Maybe<HouseholdContact>;
  secondaryContact?: Maybe<HouseholdContact>;
  tag?: Maybe<Scalars['String']>;
  updatedOn: Scalars['Instant'];
  yearToDateLargestGiftFundKey?: Maybe<Scalars['String']>;
  yearToDateLargestGiftMerchantId?: Maybe<Scalars['String']>;
  yearToDateLargestGiftMerchantIntegerId?: Maybe<Scalars['Long']>;
  yourHouseholdId?: Maybe<Scalars['String']>;
  ytdLargestGiftFund?: Maybe<Fund>;
  ytdLargestGiftMerchant?: Maybe<Merchant>;
};

export type HouseholdTagArgs = {
  current?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type HouseholdContact = {
  __typename?: 'HouseholdContact';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type HouseholdMember = {
  __typename?: 'HouseholdMember';
  communityMember: CommunityMember;
  createdOn: Scalars['Instant'];
  household: Household;
  householdKey: Scalars['String'];
  position: HouseholdMemberPosition;
  primary: Scalars['Boolean'];
  updatedOn: Scalars['Instant'];
};

export enum HouseholdMemberPosition {
  Inactive = 'INACTIVE',
  Other = 'OTHER',
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Tertiary = 'TERTIARY',
  Unknown = 'UNKNOWN',
}

export type HouseholdMoveOutStatus = {
  __typename?: 'HouseholdMoveOutStatus';
  moveOutAttempted: Scalars['Boolean'];
  moveOutDate?: Maybe<Scalars['Instant']>;
};

export type HouseholdResult = {
  __typename?: 'HouseholdResult';
  household?: Maybe<Household>;
};

export type IValidationError = {
  fieldPath: Array<Scalars['String']>;
};

export type Identity = {
  __typename?: 'Identity';
  accountType?: Maybe<AccountType>;
  administeredOrganizations?: Maybe<Array<AdministeredOrganization>>;
  allPermissions?: Maybe<AllIdentityPermissions>;
  createdOn?: Maybe<Scalars['String']>;
  emailAddresses: Array<Maybe<EmailContact>>;
  firstName?: Maybe<Scalars['String']>;
  identityKey: Scalars['IdentityKey'];
  lastName?: Maybe<Scalars['String']>;
  olsonTimeZone?: Maybe<Scalars['OlsonTimeZone']>;
  primaryEmailAddress?: Maybe<EmailContact>;
  timeZoneId?: Maybe<Scalars['WindowsTimeZone']>;
};

export type ImageSizeInput = {
  height: Scalars['Int'];
  width: Scalars['Int'];
};

export type InactiveGroup = {
  __typename?: 'InactiveGroup';
  groupName: Scalars['String'];
  inactivatedDate: Scalars['String'];
};

export type MassAddIndividualsToProcessQueueResult = {
  __typename?: 'MassAddIndividualsToProcessQueueResult';
  message?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  success: Scalars['Boolean'];
};

export type MaxLengthValidationError = IValidationError & {
  __typename?: 'MaxLengthValidationError';
  fieldPath: Array<Scalars['String']>;
};

export type MembershipType = {
  __typename?: 'MembershipType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type MembershipTypeIdsInput = {
  isEmpty?: InputMaybe<Scalars['Boolean']>;
  values: Array<Scalars['Int']>;
};

export type MembershipTypeIdsOutput = {
  __typename?: 'MembershipTypeIdsOutput';
  isEmpty: Scalars['Boolean'];
  values: Array<Scalars['Int']>;
};

export type Merchant = {
  __typename?: 'Merchant';
  name: Scalars['String'];
};

export type MinLengthValidationError = IValidationError & {
  __typename?: 'MinLengthValidationError';
  fieldPath: Array<Scalars['String']>;
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['String'];
  currency: Currency;
};

export type MoneyAggregate = {
  __typename?: 'MoneyAggregate';
  count: Scalars['Int'];
  totalValue: Money;
};

export enum MonthOfYear {
  April = 'APRIL',
  August = 'AUGUST',
  December = 'DECEMBER',
  February = 'FEBRUARY',
  January = 'JANUARY',
  July = 'JULY',
  June = 'JUNE',
  March = 'MARCH',
  May = 'MAY',
  November = 'NOVEMBER',
  October = 'OCTOBER',
  September = 'SEPTEMBER',
  Unknown = 'UNKNOWN',
}

export type Mutation = {
  __typename?: 'Mutation';
  cancelExportJobMutation: ExportJobCanceledOutput;
  deleteExportJobMutation: ExportJobDeletedOutput;
  massAddIndividualsToProcessQueue: MassAddIndividualsToProcessQueueResult;
  queueExportJob: ExportQueuedOutput;
};

export type MutationCancelExportJobMutationArgs = {
  cancelExportJobInput: CancelExportJobInput;
  organizationKey: Scalars['String'];
};

export type MutationDeleteExportJobMutationArgs = {
  deleteExportJobInput: DeleteExportJobInput;
  organizationKey: Scalars['String'];
};

export type MutationMassAddIndividualsToProcessQueueArgs = {
  individualIds: Array<Scalars['ID']>;
  organizationKey: Scalars['ID'];
  processQueueId: Scalars['ID'];
};

export type MutationQueueExportJobArgs = {
  createExportInput: CreateExportJobInput;
  organizationKey: Scalars['String'];
};

export type Organization = {
  __typename?: 'Organization';
  communityMembersCursor?: Maybe<CursorPagingOutputOfCommunityMember>;
  details?: Maybe<OrganizationDetails>;
  featureFlags: Array<FeatureFlag>;
  fund: Fund;
  households?: Maybe<CursorPagingOutputOfHousehold>;
  key: Scalars['String'];
  membershipTypes?: Maybe<Array<MembershipType>>;
  organizationCursoredTopCommunityMembers?: Maybe<CursorPagedOutputOfTopCommunityMember>;
  organizationCursoredTopHouseholds?: Maybe<CursorPagedOutputOfTopHousehold>;
  organizationGivingSummariesByPeriod?: Maybe<OrganizationGivingPeriodSummariesResult>;
  organizationGivingSummariesByPeriodNew?: Maybe<OrganizationGivingPeriodSummariesNew>;
  processes?: Maybe<ProcessesPagedOutput>;
  queues?: Maybe<ProcessQueuesPagedOutput>;
  topCommunityMemberDonorsCursor?: Maybe<CursorPagedOutputOfTopCommunityMember>;
  topHouseholdDonors?: Maybe<CursorPagedOutputOfTopHousehold>;
};

export type OrganizationCommunityMembersCursorArgs = {
  campusKeys?: InputMaybe<Array<Scalars['String']>>;
  paging: CursorPagingInput;
  queryParams?: InputMaybe<QueryParams>;
};

export type OrganizationFundArgs = {
  fundKey?: InputMaybe<Scalars['String']>;
};

export type OrganizationHouseholdsArgs = {
  campusKeys?: InputMaybe<Array<Scalars['String']>>;
  paging: CursorPagingInput;
  queryParams?: InputMaybe<QueryParams>;
};

export type OrganizationOrganizationCursoredTopCommunityMembersArgs = {
  cursor: PqsCursorPagingInput;
  queryFilter: TopCommunityMemberQueryFilterInput;
};

export type OrganizationOrganizationCursoredTopHouseholdsArgs = {
  cursor: PqsCursorPagingInput;
  queryFilter: TopHouseholdQueryFilterInput;
};

export type OrganizationOrganizationGivingSummariesByPeriodArgs = {
  organizationQueryFilter: OrganizationQueryFilterInput;
  timeRange: GranularTimeRangeInput;
};

export type OrganizationOrganizationGivingSummariesByPeriodNewArgs = {
  organizationQueryFilter: OrganizationQueryFilterInput;
  timeRange: GranularTimeRangeInput;
};

export type OrganizationProcessesArgs = {
  paging: Chms_CursorPagingInput;
  processSearchInput?: InputMaybe<ProcessSearchInput>;
};

export type OrganizationQueuesArgs = {
  paging: Chms_CursorPagingInput;
  processQueueSearchInput?: InputMaybe<ProcessQueueSearchInput>;
};

export type OrganizationTopCommunityMemberDonorsCursorArgs = {
  cursor: CursorPagingInput;
  queryFilter: TopCommunityMemberQueryFilterInput;
};

export type OrganizationTopHouseholdDonorsArgs = {
  cursor: CursorPagingInput;
  queryFilter: TopHouseholdQueryFilterInput;
};

export type OrganizationAttendanceOverview = {
  __typename?: 'OrganizationAttendanceOverview';
  comparisonHighlight?: Maybe<AttendanceComparisonHighlight>;
  data: Array<EventAttendanceSummary>;
  highlights: Attendance;
};

export type OrganizationAttendanceOverviewComparisonHighlightArgs = {
  attendanceOverviewInput?: InputMaybe<AttendanceOverviewInput>;
  comparisonDatesInput?: InputMaybe<ComparisonDatesInput>;
};

export type OrganizationAttendanceOverviewDataArgs = {
  attendanceOverviewInput: AttendanceOverviewInput;
};

export type OrganizationAttendanceOverviewHighlightsArgs = {
  attendanceOverviewInput: AttendanceOverviewInput;
};

export type OrganizationDetails = {
  __typename?: 'OrganizationDetails';
  campuses: Array<Campus>;
  catholicEntity: CatholicEntity;
  catholicSoftwareStatus: Scalars['Boolean'];
  ecgId?: Maybe<Scalars['String']>;
  entitlements: Array<Entitlement>;
  isGivingPlatformActive?: Maybe<Scalars['Boolean']>;
  isMasAppPresent?: Maybe<Scalars['Boolean']>;
  isSalesforceCustomAppPresent?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  productInformation: ProductInfo;
};

export type OrganizationGivingPeriodSummariesNew = {
  __typename?: 'OrganizationGivingPeriodSummariesNew';
  communityMembersCount: Scalars['Int'];
  householdsCount: Scalars['Int'];
  periods: Array<OrganizationGivingPeriodSummaryNew>;
};

export type OrganizationGivingPeriodSummariesResult = {
  __typename?: 'OrganizationGivingPeriodSummariesResult';
  communityMembersCount: Scalars['Int'];
  householdCount: Scalars['Int'];
  periods: Array<OrganizationGivingPeriodSummary>;
};

export type OrganizationGivingPeriodSummary = {
  __typename?: 'OrganizationGivingPeriodSummary';
  fromDate: Scalars['LocalDate'];
  nonCashGiftsAggregate: MoneyAggregate;
  paymentsAggregate: MoneyAggregate;
  toDate: Scalars['LocalDate'];
};

export type OrganizationGivingPeriodSummaryNew = {
  __typename?: 'OrganizationGivingPeriodSummaryNew';
  fromDate: Scalars['LocalDate'];
  nonCashGiftsAggregate: MoneyAggregate;
  paymentsAggregate: MoneyAggregate;
  toDate: Scalars['LocalDate'];
};

export type OrganizationGroupParticipationOverview = {
  __typename?: 'OrganizationGroupParticipationOverview';
  comparisonHighlight?: Maybe<GroupParticipationComparisonHighlight>;
  data: Array<GroupParticipationSummary>;
  highlights: GroupParticipationHighlights;
};

export type OrganizationGroupParticipationOverviewComparisonHighlightArgs = {
  comparisonDatesInput?: InputMaybe<ComparisonDatesInput>;
  groupParticipationOverviewInput?: InputMaybe<GroupParticipationOverviewInput>;
};

export type OrganizationGroupParticipationOverviewDataArgs = {
  groupParticipationOverviewInput?: InputMaybe<GroupParticipationOverviewInput>;
};

export type OrganizationGroupParticipationOverviewHighlightsArgs = {
  groupParticipationOverviewInput?: InputMaybe<GroupParticipationOverviewInput>;
};

export type OrganizationPermissions = {
  __typename?: 'OrganizationPermissions';
  campusPermissions: Array<CampusPermissions>;
  organization?: Maybe<OrganizationDetails>;
  organizationKey: Scalars['Key'];
  permissions: Array<Permission>;
};

export type OrganizationQueryFilterInput = {
  ccbMembershipTypeFilter?: InputMaybe<CcbMembershipTypeFilterInput>;
  donorPlatformMerchantIds?: InputMaybe<Array<Scalars['Long']>>;
};

export type OrganizationServingOverview = {
  __typename?: 'OrganizationServingOverview';
  comparisonHighlight?: Maybe<ServingComparisonHighlight>;
  data: Array<ServingOpportunitySummary>;
  highlights: ServingHighlights;
};

export type OrganizationServingOverviewComparisonHighlightArgs = {
  comparisonDatesInput?: InputMaybe<ComparisonDatesInput>;
  servingOverviewInput?: InputMaybe<ServingOverviewInput>;
};

export type OrganizationServingOverviewDataArgs = {
  servingOverviewInput?: InputMaybe<ServingOverviewInput>;
};

export type OrganizationServingOverviewHighlightsArgs = {
  servingOverviewInput?: InputMaybe<ServingOverviewInput>;
};

export type OrganizationServingSnapshots = {
  __typename?: 'OrganizationServingSnapshots';
  snapshots: ServingSnapshots;
};

export type OrganizationServingSnapshotsSnapshotsArgs = {
  servingSnapshotsInput: ServingSnapshotsInput;
};

export type PqsCursorPagingInput = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  size: Scalars['Int'];
};

export type PagingInput = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sortings?: InputMaybe<Array<InputMaybe<SortingInput>>>;
};

export type PagingOutput = {
  __typename?: 'PagingOutput';
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export enum PaymentQueryGranularity {
  Day = 'DAY',
  Month = 'MONTH',
  Unknown = 'UNKNOWN',
  Week = 'WEEK',
}

export type Permission = {
  __typename?: 'Permission';
  name: Scalars['String'];
  permissionKey: Scalars['Key'];
};

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  countryCode?: Maybe<Scalars['String']>;
  createdOn?: Maybe<Scalars['Instant']>;
  nationalNumber?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  plainNationalNumber?: Maybe<Scalars['String']>;
  primary: Scalars['Boolean'];
  type: PhoneType;
  verified: Scalars['Boolean'];
  verifiedOn?: Maybe<Scalars['Instant']>;
};

export type PhoneNumberArguments = {
  countryCode?: InputMaybe<Scalars['String']>;
  number: Scalars['String'];
  primary: Scalars['Boolean'];
  type: PhoneType;
};

export enum PhoneType {
  Home = 'HOME',
  Mobile = 'MOBILE',
  Other = 'OTHER',
  Unknown = 'UNKNOWN',
  Work = 'WORK',
}

export type Process = {
  __typename?: 'Process';
  campus: Chms_Campus;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ProcessQueue = {
  __typename?: 'ProcessQueue';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ProcessQueueSearchInput = {
  processId: Scalars['Int'];
};

export type ProcessQueuesPagedOutput = {
  __typename?: 'ProcessQueuesPagedOutput';
  items: Array<ProcessQueue>;
  paging: CursorPagingOutput;
};

export type ProcessSearchInput = {
  id?: InputMaybe<Array<Scalars['Int']>>;
};

export type ProcessesPagedOutput = {
  __typename?: 'ProcessesPagedOutput';
  items: Array<Process>;
  paging: CursorPagingOutput;
};

export type ProductInfo = {
  __typename?: 'ProductInfo';
  chmsInformation: ChmsInfo;
  streamingInformation: StreamingInfo;
};

export type ProfileImages = {
  __typename?: 'ProfileImages';
  fullUrl?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  attendanceQueryFilters: AttendanceQueryFiltersOutput;
  exportJobs: Array<ExportJob>;
  groupParticipationFilterValues: GroupParticipationFilterValuesOutput;
  group_participation_query_ping: Scalars['String'];
  insightsExportPing: Scalars['String'];
  isGuideValid: Scalars['Boolean'];
  me?: Maybe<Identity>;
  organization?: Maybe<Organization>;
  organizationAttendanceOverview?: Maybe<OrganizationAttendanceOverview>;
  organizationGroupParticipationOverview: OrganizationGroupParticipationOverview;
  organizationServingOverview: OrganizationServingOverview;
  organizationServingSnapshots: OrganizationServingSnapshots;
  ping: Scalars['String'];
  servingFilterValues: ServingFilterValuesOutput;
  serving_query_ping: Scalars['String'];
};

export type QueryAttendanceQueryFiltersArgs = {
  organizationKey: Scalars['String'];
};

export type QueryExportJobsArgs = {
  organizationKey: Scalars['String'];
};

export type QueryGroupParticipationFilterValuesArgs = {
  organizationKey: Scalars['String'];
};

export type QueryGroup_Participation_Query_PingArgs = {
  organizationKey: Scalars['String'];
};

export type QueryInsightsExportPingArgs = {
  organizationKey: Scalars['String'];
};

export type QueryIsGuideValidArgs = {
  hash: Scalars['String'];
};

export type QueryOrganizationArgs = {
  organizationKey: Scalars['String'];
};

export type QueryOrganizationAttendanceOverviewArgs = {
  organizationKey: Scalars['String'];
};

export type QueryOrganizationGroupParticipationOverviewArgs = {
  organizationKey: Scalars['String'];
};

export type QueryOrganizationServingOverviewArgs = {
  organizationKey: Scalars['String'];
};

export type QueryOrganizationServingSnapshotsArgs = {
  organizationKey: Scalars['String'];
};

export type QueryPingArgs = {
  organizationKey: Scalars['String'];
};

export type QueryServingFilterValuesArgs = {
  organizationKey: Scalars['String'];
};

export type QueryServing_Query_PingArgs = {
  organizationKey: Scalars['String'];
};

export type QueryParams = {
  crossCampusQuery?: InputMaybe<Scalars['Boolean']>;
  endDate?: InputMaybe<Scalars['Instant']>;
  entityKeys?: InputMaybe<Array<Scalars['String']>>;
  filters?: InputMaybe<Array<Filter>>;
  search?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Instant']>;
};

export type RangeValidationError = IValidationError & {
  __typename?: 'RangeValidationError';
  fieldPath: Array<Scalars['String']>;
};

export type RegularExpressionValidationError = IValidationError & {
  __typename?: 'RegularExpressionValidationError';
  fieldPath: Array<Scalars['String']>;
};

export type RequiredValidationError = IValidationError & {
  __typename?: 'RequiredValidationError';
  fieldPath: Array<Scalars['String']>;
};

export type ServingComparisonHighlight = {
  __typename?: 'ServingComparisonHighlight';
  last: Scalars['Int'];
  previous?: Maybe<Scalars['Int']>;
};

export type ServingFilterValuesOutput = {
  __typename?: 'ServingFilterValuesOutput';
  campusNames: Array<Scalars['String']>;
  categoryNames: Array<Scalars['String']>;
  membershipTypeIds: Array<Scalars['Int']>;
  positionNames: Array<Scalars['String']>;
  teamNames: Array<Scalars['String']>;
};

export type ServingHighlights = {
  __typename?: 'ServingHighlights';
  averageServingOpportunities: Scalars['Int'];
  uniqueHouseholds: Scalars['Int'];
  uniqueIndividuals: Scalars['Int'];
  uniquePositions: Scalars['Int'];
  uniqueTeams: Scalars['Int'];
};

export type ServingOpportunitySummary = {
  __typename?: 'ServingOpportunitySummary';
  campusNames: Array<Scalars['String']>;
  categoryNames: Array<Scalars['String']>;
  endDate: Scalars['DateTime'];
  positionNames: Array<Scalars['String']>;
  scheduleStartDateTimes: Array<Scalars['String']>;
  servingSummary: ServingSummary;
  startDate: Scalars['DateTime'];
  teamNames: Array<Scalars['String']>;
  total: Scalars['Int'];
};

export type ServingOverviewFiltersInput = {
  campusNames?: InputMaybe<Array<Scalars['String']>>;
  categoryNames?: InputMaybe<Array<Scalars['String']>>;
  membershipTypeIds?: InputMaybe<MembershipTypeIdsInput>;
  positionNames?: InputMaybe<Array<Scalars['String']>>;
  teamNames?: InputMaybe<Array<Scalars['String']>>;
};

export type ServingOverviewInput = {
  dateRange: DateRangeInput;
  filters?: InputMaybe<ServingOverviewFiltersInput>;
  granularity: Granularity;
};

export type ServingSnapshots = {
  __typename?: 'ServingSnapshots';
  availableVolunteers: Scalars['Int'];
  servingOpportunities: Scalars['Int'];
  servingOpportunitiesUnfilled: Scalars['Int'];
};

export type ServingSnapshotsFiltersInput = {
  membershipTypeIds?: InputMaybe<MembershipTypeIdsInput>;
};

export type ServingSnapshotsInput = {
  dateRange: DateRangeInput;
  filters?: InputMaybe<ServingSnapshotsFiltersInput>;
};

export type ServingSummary = {
  __typename?: 'ServingSummary';
  totalRolesFilled: Scalars['Int'];
  uniqueHouseholds: Scalars['Int'];
  uniqueIndividuals: Scalars['Int'];
  uniquePositions: Scalars['Int'];
  uniqueTeams: Scalars['Int'];
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SortingInput = {
  field: Scalars['String'];
  order: SortOrder;
};

export enum StartOfWeek {
  Dayoffromdate = 'DAYOFFROMDATE',
  Monday = 'MONDAY',
  Sunday = 'SUNDAY',
  Unknown = 'UNKNOWN',
}

export type StreamingInfo = {
  __typename?: 'StreamingInfo';
  id?: Maybe<Scalars['ID']>;
};

export type StringLengthValidationError = IValidationError & {
  __typename?: 'StringLengthValidationError';
  fieldPath: Array<Scalars['String']>;
};

export type SuccessResult = {
  __typename?: 'SuccessResult';
  success?: Maybe<Scalars['Boolean']>;
};

export enum TimeFilterOperator {
  Is = 'Is',
  IsAfter = 'IsAfter',
  IsBefore = 'IsBefore',
  IsBetween = 'IsBetween',
}

export type TimeZone = {
  __typename?: 'TimeZone';
  olsonName: Scalars['String'];
  windowsName: Scalars['String'];
};

export type TopCommunityMember = {
  __typename?: 'TopCommunityMember';
  communityMember?: Maybe<CommunityMember>;
  communityMemberKey: Scalars['String'];
  giftAmount: Money;
  nonCashCount: Scalars['Int'];
  paymentCount: Scalars['Int'];
};

export type TopCommunityMemberCommunityMemberArgs = {
  endDate?: InputMaybe<Scalars['Instant']>;
  startDate?: InputMaybe<Scalars['Instant']>;
};

export type TopCommunityMemberQueryFilterInput = {
  dateRange: Payment_DateRangeInput;
};

export type TopHousehold = {
  __typename?: 'TopHousehold';
  giftAmount: Money;
  household?: Maybe<Household>;
  householdKey: Scalars['String'];
  nonCashCount: Scalars['Int'];
  paymentCount: Scalars['Int'];
};

export type TopHouseholdHouseholdArgs = {
  endDate?: InputMaybe<Scalars['Instant']>;
  startDate?: InputMaybe<Scalars['Instant']>;
};

export type TopHouseholdQueryFilterInput = {
  dateRange: Payment_DateRangeInput;
};

export type UrlValidationError = IValidationError & {
  __typename?: 'UrlValidationError';
  fieldPath: Array<Scalars['String']>;
};

export type ValidationError = IValidationError & {
  __typename?: 'ValidationError';
  fieldPath: Array<Scalars['String']>;
};

export type ValidationErrors = {
  __typename?: 'ValidationErrors';
  errors: Array<IValidationError>;
};

export enum VolunteerListType {
  All = 'All',
  AtRisk = 'AtRisk',
  Curious = 'Curious',
  FirstTime = 'FirstTime',
  Lapsed = 'Lapsed',
  NonVolunteers = 'NonVolunteers',
  Occasional = 'Occasional',
  Potential = 'Potential',
  RecentNew = 'RecentNew',
  Regular = 'Regular',
  Returning = 'Returning',
  SecondTime = 'SecondTime',
}

export enum VolunteerStatus {
  FirstTime = 'FirstTime',
  Lapsed = 'Lapsed',
  NonVolunteers = 'NonVolunteers',
  Occasional = 'Occasional',
  Regular = 'Regular',
  SecondTime = 'SecondTime',
}

export type Chms_Campus = {
  __typename?: 'chms_Campus';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Chms_CursorPagingInput = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  size: Scalars['Int'];
};

export type Group_Participation_Query_Mutation = {
  __typename?: 'group_participation_query_Mutation';
  groupParticipationDeleteLastProcessCursors: GroupParticipationSuccessResult;
};

export type Payment_DateRangeInput = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
  timeZoneId?: InputMaybe<Scalars['String']>;
};

export type OrganizationAttendanceOverviewDataFragment = {
  __typename?: 'EventAttendanceSummary';
  attendanceGroupingNames: Array<string>;
  campusNames: Array<string>;
  startDate: any;
  endDate: any;
  total: number;
  eventNames: Array<string>;
  groupNames: Array<string>;
  departmentNames: Array<string>;
  groupTypeNames: Array<string>;
  membershipTypeIds: Array<number>;
  attendanceSummary: {
    __typename?: 'Attendance';
    averageAttendance?: number | null;
    checkinAttendance: number;
    familyAttendance: number;
    firstTimeGuests: number;
    headCountAttendance: number;
    individualAttendance: number;
    servingAttendance: number;
    streamingAttendance: number;
    totalAttendance: number;
    uniqueFamilyAttendance: number;
    uniqueIndividualAttendance: number;
  };
};

export type OrganizationAttendanceOverviewHighlightsFragment = {
  __typename?: 'Attendance';
  averageAttendance?: number | null;
  checkinAttendance: number;
  familyAttendance: number;
  firstTimeGuests: number;
  headCountAttendance: number;
  individualAttendance: number;
  servingAttendance: number;
  streamingAttendance: number;
  totalAttendance: number;
  uniqueFamilyAttendance: number;
  uniqueIndividualAttendance: number;
};

export type OrganizationGroupParticipationOverviewDataFragment = {
  __typename?: 'GroupParticipationSummary';
  startDate: any;
  endDate: any;
  total: number;
  campusNames: Array<string>;
  departmentNames: Array<string>;
  groupNames: Array<string>;
  groupTypeNames: Array<string>;
  membershipTypeIds: Array<string>;
};

export type ExportJobFragment = {
  __typename?: 'ExportJob';
  createdAt: number;
  exportLink?: string | null;
  exportSizeBytes?: number | null;
  jobKey: string;
  status: ExportJobStatus;
  exportJobOptions: {
    __typename?: 'ExportJobOptions';
    searchTerm?: string | null;
    viewBy: ExportJobViewBy;
    sort?: { __typename?: 'ExportJobSort'; columnName: string; direction: string } | null;
    type: {
      __typename?: 'ExportTypeOutput';
      attenderListType?: AttenderListType | null;
      donorListType?: DonorListType | null;
      volunteerListType?: VolunteerListType | null;
    };
  };
};

export type OrganizationGivingPeriodSummaryFragment = {
  __typename?: 'OrganizationGivingPeriodSummariesResult';
  communityMembersCount: number;
  householdCount: number;
  periods: Array<{
    __typename?: 'OrganizationGivingPeriodSummary';
    fromDate: LocalDate;
    toDate: LocalDate;
    paymentsAggregate: {
      __typename?: 'MoneyAggregate';
      count: number;
      totalValue: { __typename?: 'Money'; amount: string; currency: Currency };
    };
    nonCashGiftsAggregate: {
      __typename?: 'MoneyAggregate';
      count: number;
      totalValue: { __typename?: 'Money'; amount: string; currency: Currency };
    };
  }>;
};

export type OrganizationGivingPeriodSummaryNewFragment = {
  __typename?: 'OrganizationGivingPeriodSummariesNew';
  communityMembersCount: number;
  householdsCount: number;
  periods: Array<{
    __typename?: 'OrganizationGivingPeriodSummaryNew';
    fromDate: LocalDate;
    toDate: LocalDate;
    paymentsAggregate: {
      __typename?: 'MoneyAggregate';
      count: number;
      totalValue: { __typename?: 'Money'; amount: string; currency: Currency };
    };
    nonCashGiftsAggregate: {
      __typename?: 'MoneyAggregate';
      count: number;
      totalValue: { __typename?: 'Money'; amount: string; currency: Currency };
    };
  }>;
};

export type OrganizationServingOverviewDataFragment = {
  __typename?: 'ServingOpportunitySummary';
  startDate: any;
  endDate: any;
  total: number;
  campusNames: Array<string>;
  categoryNames: Array<string>;
  teamNames: Array<string>;
  positionNames: Array<string>;
  scheduleStartDateTimes: Array<string>;
  servingSummary: {
    __typename?: 'ServingSummary';
    uniqueIndividuals: number;
    uniqueHouseholds: number;
    uniqueTeams: number;
    uniquePositions: number;
    totalRolesFilled: number;
  };
};

export type FeatureFlagsFragment = {
  __typename?: 'Organization';
  featureFlags: Array<{ __typename?: 'FeatureFlag'; name: string; enabled: boolean }>;
};

export type MeFragment = {
  __typename?: 'Identity';
  accountType?: AccountType | null;
  identityKey: Key;
  firstName?: string | null;
  lastName?: string | null;
  createdOn?: string | null;
  olsonTimeZone?: OlsonTimeZone | null;
  timeZoneId?: WindowsTimeZone | null;
  primaryEmailAddress?: { __typename?: 'EmailContact'; emailAddress?: string | null } | null;
  allPermissions?: {
    __typename?: 'AllIdentityPermissions';
    organizationPermissions: Array<{
      __typename?: 'OrganizationPermissions';
      organizationKey: Key;
      permissions: Array<{ __typename?: 'Permission'; name: string }>;
      campusPermissions: Array<{
        __typename?: 'CampusPermissions';
        campusKey: Key;
        permissions: Array<{ __typename?: 'Permission'; name: string }>;
      }>;
    }>;
  } | null;
  administeredOrganizations?: Array<{
    __typename?: 'AdministeredOrganization';
    name: string;
    key: string;
    logoUrl: string;
  }> | null;
};

export type MassAddToProcessQueueMutationMutationVariables = Exact<{
  organizationKey: Scalars['ID'];
  processQueueId: Scalars['ID'];
  individualIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type MassAddToProcessQueueMutationMutation = {
  __typename?: 'Mutation';
  massAddIndividualsToProcessQueue: {
    __typename?: 'MassAddIndividualsToProcessQueueResult';
    success: boolean;
    status: string;
    message?: string | null;
  };
};

export type CancelExportJobMutationVariables = Exact<{
  organizationKey: Scalars['String'];
  cancelExportJobInput: CancelExportJobInput;
}>;

export type CancelExportJobMutation = {
  __typename?: 'Mutation';
  cancelExportJobMutation: {
    __typename?: 'ExportJobCanceledOutput';
    export: {
      __typename?: 'ExportJob';
      createdAt: number;
      exportLink?: string | null;
      exportSizeBytes?: number | null;
      jobKey: string;
      status: ExportJobStatus;
      exportJobOptions: {
        __typename?: 'ExportJobOptions';
        searchTerm?: string | null;
        viewBy: ExportJobViewBy;
        sort?: { __typename?: 'ExportJobSort'; columnName: string; direction: string } | null;
        type: {
          __typename?: 'ExportTypeOutput';
          attenderListType?: AttenderListType | null;
          donorListType?: DonorListType | null;
          volunteerListType?: VolunteerListType | null;
        };
      };
    };
  };
};

export type DeleteExportJobMutationVariables = Exact<{
  organizationKey: Scalars['String'];
  deleteExportJobInput: DeleteExportJobInput;
}>;

export type DeleteExportJobMutation = {
  __typename?: 'Mutation';
  deleteExportJobMutation: {
    __typename?: 'ExportJobDeletedOutput';
    export: {
      __typename?: 'ExportJob';
      createdAt: number;
      exportLink?: string | null;
      exportSizeBytes?: number | null;
      jobKey: string;
      status: ExportJobStatus;
      exportJobOptions: {
        __typename?: 'ExportJobOptions';
        searchTerm?: string | null;
        viewBy: ExportJobViewBy;
        sort?: { __typename?: 'ExportJobSort'; columnName: string; direction: string } | null;
        type: {
          __typename?: 'ExportTypeOutput';
          attenderListType?: AttenderListType | null;
          donorListType?: DonorListType | null;
          volunteerListType?: VolunteerListType | null;
        };
      };
    };
  };
};

export type QueueExportJobMutationVariables = Exact<{
  organizationKey: Scalars['String'];
  createExportInput: CreateExportJobInput;
}>;

export type QueueExportJobMutation = {
  __typename?: 'Mutation';
  queueExportJob: {
    __typename?: 'ExportQueuedOutput';
    export: {
      __typename?: 'ExportJob';
      createdAt: number;
      exportLink?: string | null;
      exportSizeBytes?: number | null;
      jobKey: string;
      status: ExportJobStatus;
      exportJobOptions: {
        __typename?: 'ExportJobOptions';
        searchTerm?: string | null;
        viewBy: ExportJobViewBy;
        sort?: { __typename?: 'ExportJobSort'; columnName: string; direction: string } | null;
        type: {
          __typename?: 'ExportTypeOutput';
          attenderListType?: AttenderListType | null;
          donorListType?: DonorListType | null;
          volunteerListType?: VolunteerListType | null;
        };
      };
    };
  };
};

export type DashboardSnapshotsQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  isDisablePQSEnabled: Scalars['Boolean'];
  paging: CursorPagingInput;
  attendanceChurnLapsedLastParams: QueryParams;
  attendanceChurnLapsedPrevParams: QueryParams;
  attendanceChurnRegularLastParams: QueryParams;
  attendanceChurnRegularPrevParams: QueryParams;
  attendanceOverviewInput: AttendanceOverviewInput;
  donorCurrentTimeRange: GranularTimeRangeInput;
  donorOrganizationQueryFilter: OrganizationQueryFilterInput;
  donorRecentNewLastParams: QueryParams;
  donorRecentNewPrevParams: QueryParams;
  donorLastQueryParams: QueryParams;
  donorPreviousQueryParams: QueryParams;
  lastQueryParams: QueryParams;
  previousQueryParams: QueryParams;
  lastServingSnapshotsInput: ServingSnapshotsInput;
  previousServingSnapshotsInput: ServingSnapshotsInput;
}>;

export type DashboardSnapshotsQuery = {
  __typename?: 'Query';
  AllInsightEligibleIndividuals?: {
    __typename?: 'Organization';
    LastData?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: { __typename?: 'Aggregations'; All?: number | null };
    } | null;
    PreviousData?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: { __typename?: 'Aggregations'; All?: number | null };
    } | null;
  } | null;
  AttendanceOverview?: {
    __typename?: 'OrganizationAttendanceOverview';
    highlights: { __typename?: 'Attendance'; totalAttendance: number };
  } | null;
  Attenders?: {
    __typename?: 'Organization';
    AttendanceChurnLapsedLastData?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: { __typename?: 'Aggregations'; count?: number | null };
    } | null;
    AttendanceChurnLapsedPrevData?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: { __typename?: 'Aggregations'; count?: number | null };
    } | null;
    AttendanceChurnRegularLastData?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: { __typename?: 'Aggregations'; count?: number | null };
    } | null;
    AttendanceChurnRegularPrevData?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: { __typename?: 'Aggregations'; count?: number | null };
    } | null;
    LastData?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: {
        __typename?: 'Aggregations';
        AtRisk?: number | null;
        Curious?: number | null;
        FirstTime?: number | null;
        RecentNew?: number | null;
        Regular?: number | null;
        Returning?: number | null;
        SecondTime?: number | null;
        PotentialInactive?: number | null;
      };
    } | null;
    PreviousData?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: {
        __typename?: 'Aggregations';
        AtRisk?: number | null;
        Curious?: number | null;
        RecentNew?: number | null;
        Returning?: number | null;
      };
    } | null;
  } | null;
  Donors?: {
    __typename?: 'Organization';
    RecentNewLast?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      paging: { __typename?: 'CursorPagingOutput'; totalItemCount: number };
    } | null;
    RecentNewPrevious?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      paging: { __typename?: 'CursorPagingOutput'; totalItemCount: number };
    } | null;
    CurrentGivingSummaries?: {
      __typename?: 'OrganizationGivingPeriodSummariesNew';
      periods: Array<{
        __typename?: 'OrganizationGivingPeriodSummaryNew';
        fromDate: LocalDate;
        toDate: LocalDate;
        paymentsAggregate: {
          __typename?: 'MoneyAggregate';
          count: number;
          totalValue: { __typename?: 'Money'; amount: string; currency: Currency };
        };
      }>;
    } | null;
    LastData?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: {
        __typename?: 'Aggregations';
        AtRisk?: number | null;
        FirstTime?: number | null;
        Potential?: number | null;
        Recurring?: number | null;
        Regular?: number | null;
        SecondTime?: number | null;
        Digital?: number | null;
        Offline?: number | null;
      };
    } | null;
    PreviousData?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: { __typename?: 'Aggregations'; AtRisk?: number | null };
    } | null;
  } | null;
  Volunteers?: {
    __typename?: 'Organization';
    LastData?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: {
        __typename?: 'Aggregations';
        AtRisk?: number | null;
        Curious?: number | null;
        FirstTime?: number | null;
        Potential?: number | null;
        RecentNew?: number | null;
        Regular?: number | null;
        Returning?: number | null;
        SecondTime?: number | null;
      };
    } | null;
    PreviousData?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: { __typename?: 'Aggregations'; AtRisk?: number | null; RecentNew?: number | null };
    } | null;
  } | null;
  ServingSnapshots: {
    __typename?: 'OrganizationServingSnapshots';
    LastServingSnapshots: {
      __typename?: 'ServingSnapshots';
      availableVolunteers: number;
      servingOpportunities: number;
      servingOpportunitiesUnfilled: number;
    };
    PreviousServingSnapshots: {
      __typename?: 'ServingSnapshots';
      availableVolunteers: number;
      servingOpportunities: number;
      servingOpportunitiesUnfilled: number;
    };
  };
};

export type AttendanceQueryFiltersQueryVariables = Exact<{
  organizationKey: Scalars['String'];
}>;

export type AttendanceQueryFiltersQuery = {
  __typename?: 'Query';
  attendanceQueryFilters: {
    __typename?: 'AttendanceQueryFiltersOutput';
    attendanceGroupingNames: Array<string>;
    campusNames: Array<string>;
    departmentNames: Array<string>;
    eventNames: Array<string>;
    groupNames: Array<string>;
    groupTypeNames: Array<string>;
    membershipTypeIds: Array<number>;
    streamingEventNames: Array<string>;
  };
};

export type OrganizationAttendanceOverviewWithPreviousQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  attendanceOverviewInput: AttendanceOverviewInput;
  comparisonDatesInput: ComparisonDatesInput;
  previousAttendanceOverviewInput: AttendanceOverviewInput;
}>;

export type OrganizationAttendanceOverviewWithPreviousQuery = {
  __typename?: 'Query';
  organizationAttendanceOverview?: {
    __typename?: 'OrganizationAttendanceOverview';
    highlights: {
      __typename?: 'Attendance';
      averageAttendance?: number | null;
      checkinAttendance: number;
      familyAttendance: number;
      firstTimeGuests: number;
      headCountAttendance: number;
      individualAttendance: number;
      servingAttendance: number;
      streamingAttendance: number;
      totalAttendance: number;
      uniqueFamilyAttendance: number;
      uniqueIndividualAttendance: number;
    };
    data: Array<{
      __typename?: 'EventAttendanceSummary';
      attendanceGroupingNames: Array<string>;
      campusNames: Array<string>;
      startDate: any;
      endDate: any;
      total: number;
      eventNames: Array<string>;
      groupNames: Array<string>;
      departmentNames: Array<string>;
      groupTypeNames: Array<string>;
      membershipTypeIds: Array<number>;
      attendanceSummary: {
        __typename?: 'Attendance';
        averageAttendance?: number | null;
        checkinAttendance: number;
        familyAttendance: number;
        firstTimeGuests: number;
        headCountAttendance: number;
        individualAttendance: number;
        servingAttendance: number;
        streamingAttendance: number;
        totalAttendance: number;
        uniqueFamilyAttendance: number;
        uniqueIndividualAttendance: number;
      };
    }>;
    previousData: Array<{
      __typename?: 'EventAttendanceSummary';
      attendanceGroupingNames: Array<string>;
      campusNames: Array<string>;
      startDate: any;
      endDate: any;
      total: number;
      eventNames: Array<string>;
      groupNames: Array<string>;
      departmentNames: Array<string>;
      groupTypeNames: Array<string>;
      membershipTypeIds: Array<number>;
      attendanceSummary: {
        __typename?: 'Attendance';
        averageAttendance?: number | null;
        checkinAttendance: number;
        familyAttendance: number;
        firstTimeGuests: number;
        headCountAttendance: number;
        individualAttendance: number;
        servingAttendance: number;
        streamingAttendance: number;
        totalAttendance: number;
        uniqueFamilyAttendance: number;
        uniqueIndividualAttendance: number;
      };
    }>;
    comparisonHighlight?: {
      __typename?: 'AttendanceComparisonHighlight';
      last: number;
      previous?: number | null;
    } | null;
  } | null;
};

export type OrganizationAttendanceOverviewWithoutPreviousQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  attendanceOverviewInput: AttendanceOverviewInput;
  comparisonDatesInput: ComparisonDatesInput;
}>;

export type OrganizationAttendanceOverviewWithoutPreviousQuery = {
  __typename?: 'Query';
  organizationAttendanceOverview?: {
    __typename?: 'OrganizationAttendanceOverview';
    highlights: {
      __typename?: 'Attendance';
      averageAttendance?: number | null;
      checkinAttendance: number;
      familyAttendance: number;
      firstTimeGuests: number;
      headCountAttendance: number;
      individualAttendance: number;
      servingAttendance: number;
      streamingAttendance: number;
      totalAttendance: number;
      uniqueFamilyAttendance: number;
      uniqueIndividualAttendance: number;
    };
    data: Array<{
      __typename?: 'EventAttendanceSummary';
      attendanceGroupingNames: Array<string>;
      campusNames: Array<string>;
      startDate: any;
      endDate: any;
      total: number;
      eventNames: Array<string>;
      groupNames: Array<string>;
      departmentNames: Array<string>;
      groupTypeNames: Array<string>;
      membershipTypeIds: Array<number>;
      attendanceSummary: {
        __typename?: 'Attendance';
        averageAttendance?: number | null;
        checkinAttendance: number;
        familyAttendance: number;
        firstTimeGuests: number;
        headCountAttendance: number;
        individualAttendance: number;
        servingAttendance: number;
        streamingAttendance: number;
        totalAttendance: number;
        uniqueFamilyAttendance: number;
        uniqueIndividualAttendance: number;
      };
    }>;
    comparisonHighlight?: { __typename?: 'AttendanceComparisonHighlight'; last: number } | null;
  } | null;
};

export type AttendanceSnapshotsQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  paging: CursorPagingInput;
  queryParams?: InputMaybe<QueryParams>;
}>;

export type AttendanceSnapshotsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    communityMembersCursor?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: {
        __typename?: 'Aggregations';
        recentNewAttenders?: number | null;
        atRiskAttenders?: number | null;
        curiousAttenders?: number | null;
        returningAttenders?: number | null;
        potentialInactive?: number | null;
      };
    } | null;
  } | null;
};

export type AttendanceStagesQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  paging: CursorPagingInput;
  queryParams?: InputMaybe<QueryParams>;
}>;

export type AttendanceStagesQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    communityMembersCursor?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: {
        __typename?: 'Aggregations';
        firstTimeAttenders?: number | null;
        lapsedAttenders?: number | null;
        occasionalAttenders?: number | null;
        regularAttenders?: number | null;
        secondTimeAttenders?: number | null;
      };
    } | null;
  } | null;
};

export type CommunityMemberAttendanceListTotalsQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  paging: CursorPagingInput;
  queryParams?: InputMaybe<QueryParams>;
  campusKeys?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type CommunityMemberAttendanceListTotalsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    communityMembersCursor?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: {
        __typename?: 'Aggregations';
        allPeople?: number | null;
        firstTimeAttenders?: number | null;
        lapsedAttenders?: number | null;
        occasionalAttenders?: number | null;
        regularAttenders?: number | null;
        secondTimeAttenders?: number | null;
        recentNewAttenders?: number | null;
        atRiskAttenders?: number | null;
        curiousAttenders?: number | null;
        returningAttenders?: number | null;
        potentialInactive?: number | null;
      };
    } | null;
  } | null;
};

export type CommunityMemberDonorListTotalsQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  paging: CursorPagingInput;
  topDonorsCursor: PqsCursorPagingInput;
  queryParams?: InputMaybe<QueryParams>;
  allIndividualsQueryParams?: InputMaybe<QueryParams>;
  campusKeys?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  topDonorQueryFilter: TopCommunityMemberQueryFilterInput;
  disablePQS: Scalars['Boolean'];
}>;

export type CommunityMemberDonorListTotalsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    AllCount?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: { __typename?: 'Aggregations'; allPeople?: number | null };
    } | null;
    IsDonorCounts?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: {
        __typename?: 'Aggregations';
        atRiskDonors?: number | null;
        firstTimeDonors?: number | null;
        lapsedDonors?: number | null;
        occasionalDonors?: number | null;
        potentialDonors?: number | null;
        recurringDonors?: number | null;
        regularDonors?: number | null;
        secondTimeDonors?: number | null;
      };
    } | null;
    TopDonorsCount?: {
      __typename?: 'CursorPagedOutputOfTopCommunityMember';
      paging: { __typename?: 'CursorPagingOutput'; totalItemCount: number };
    } | null;
  } | null;
};

export type CommunityMemberVolunteerListTotalsQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  paging: CursorPagingInput;
  queryParams?: InputMaybe<QueryParams>;
  campusKeys?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type CommunityMemberVolunteerListTotalsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    communityMembersCursor?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: {
        __typename?: 'Aggregations';
        allPeople?: number | null;
        atRiskVolunteers?: number | null;
        curiousVolunteers?: number | null;
        firstTimeVolunteers?: number | null;
        lapsedVolunteers?: number | null;
        occasionalVolunteers?: number | null;
        recentNewVolunteers?: number | null;
        regularVolunteers?: number | null;
        returningVolunteers?: number | null;
        secondTimeVolunteers?: number | null;
      };
    } | null;
  } | null;
};

export type CommunityServingSnapshotsQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  paging: CursorPagingInput;
  queryParams?: InputMaybe<QueryParams>;
}>;

export type CommunityServingSnapshotsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    communityMembersCursor?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: {
        __typename?: 'Aggregations';
        atRiskVolunteers?: number | null;
        potentialVolunteers?: number | null;
      };
    } | null;
  } | null;
};

export type GivingSnapshotsQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  paging: CursorPagingInput;
  queryParams?: InputMaybe<QueryParams>;
}>;

export type GivingSnapshotsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    communityMembersCursor?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: { __typename?: 'Aggregations'; atRiskDonors?: number | null; potentialDonors?: number | null };
    } | null;
  } | null;
};

export type GivingStagesQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  paging: CursorPagingInput;
  queryParams?: InputMaybe<QueryParams>;
  allIndividualsQueryParams?: InputMaybe<QueryParams>;
}>;

export type GivingStagesQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    AllCount?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: { __typename?: 'Aggregations'; allPeople?: number | null };
    } | null;
    IsDonorCounts?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: {
        __typename?: 'Aggregations';
        firstTimeDonors?: number | null;
        lapsedDonors?: number | null;
        occasionalDonors?: number | null;
        recurringDonors?: number | null;
        regularDonors?: number | null;
        secondTimeDonors?: number | null;
      };
    } | null;
  } | null;
};

export type GivingTypesQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  paging: CursorPagingInput;
  queryParams?: InputMaybe<QueryParams>;
}>;

export type GivingTypesQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    communityMembersCursor?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: { __typename?: 'Aggregations'; digital?: number | null; offline?: number | null };
    } | null;
  } | null;
};

export type HouseholdAttendanceListTotalsQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  paging: CursorPagingInput;
  queryParams?: InputMaybe<QueryParams>;
  campusKeys?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type HouseholdAttendanceListTotalsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    households?: {
      __typename?: 'CursorPagingOutputOfHousehold';
      aggregations: {
        __typename?: 'Aggregations';
        firstTimeAttenders?: number | null;
        lapsedAttenders?: number | null;
        occasionalAttenders?: number | null;
        regularAttenders?: number | null;
        secondTimeAttenders?: number | null;
        recentNewAttenders?: number | null;
        atRiskAttenders?: number | null;
        curiousAttenders?: number | null;
        returningAttenders?: number | null;
        potentialInactive?: number | null;
      };
    } | null;
  } | null;
};

export type HouseholdCountQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  paging: CursorPagingInput;
  queryParams?: InputMaybe<QueryParams>;
  campusKeys?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type HouseholdCountQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    households?: {
      __typename?: 'CursorPagingOutputOfHousehold';
      paging: { __typename?: 'CursorPagingOutput'; totalItemCount: number };
    } | null;
  } | null;
};

export type HouseholdDonorListTotalsQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  paging: CursorPagingInput;
  topDonorsCursor: PqsCursorPagingInput;
  queryParams?: InputMaybe<QueryParams>;
  allHouseholdsQueryParams?: InputMaybe<QueryParams>;
  campusKeys?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  topDonorQueryFilter: TopCommunityMemberQueryFilterInput;
  disablePQS: Scalars['Boolean'];
}>;

export type HouseholdDonorListTotalsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    AllCount?: {
      __typename?: 'CursorPagingOutputOfHousehold';
      paging: { __typename?: 'CursorPagingOutput'; totalItemCount: number };
    } | null;
    IsDonorCounts?: {
      __typename?: 'CursorPagingOutputOfHousehold';
      aggregations: {
        __typename?: 'Aggregations';
        atRiskDonors?: number | null;
        firstTimeDonors?: number | null;
        lapsedDonors?: number | null;
        occasionalDonors?: number | null;
        recurringDonors?: number | null;
        regularDonors?: number | null;
        secondTimeDonors?: number | null;
      };
    } | null;
    TopDonorsCount?: {
      __typename?: 'CursorPagedOutputOfTopCommunityMember';
      paging: { __typename?: 'CursorPagingOutput'; totalItemCount: number };
    } | null;
  } | null;
};

export type HouseholdGivingStagesQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  paging: CursorPagingInput;
  queryParams?: InputMaybe<QueryParams>;
  allHouseholdsQueryParams?: InputMaybe<QueryParams>;
}>;

export type HouseholdGivingStagesQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    AllCount?: {
      __typename?: 'CursorPagingOutputOfHousehold';
      paging: { __typename?: 'CursorPagingOutput'; totalItemCount: number };
    } | null;
    IsDonorCounts?: {
      __typename?: 'CursorPagingOutputOfHousehold';
      aggregations: {
        __typename?: 'Aggregations';
        firstTimeDonors?: number | null;
        lapsedDonors?: number | null;
        occasionalDonors?: number | null;
        recurringDonors?: number | null;
        regularDonors?: number | null;
        secondTimeDonors?: number | null;
      };
    } | null;
  } | null;
};

export type HouseholdVolunteerListTotalsQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  paging: CursorPagingInput;
  queryParams?: InputMaybe<QueryParams>;
  campusKeys?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type HouseholdVolunteerListTotalsQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    households?: {
      __typename?: 'CursorPagingOutputOfHousehold';
      aggregations: {
        __typename?: 'Aggregations';
        atRiskVolunteers?: number | null;
        curiousVolunteers?: number | null;
        firstTimeVolunteers?: number | null;
        lapsedVolunteers?: number | null;
        occasionalVolunteers?: number | null;
        recentNewVolunteers?: number | null;
        regularVolunteers?: number | null;
        returningVolunteers?: number | null;
        secondTimeVolunteers?: number | null;
      };
    } | null;
  } | null;
};

export type LapsedAttenderCountQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  paging: CursorPagingInput;
  queryParams?: InputMaybe<QueryParams>;
}>;

export type LapsedAttenderCountQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    communityMembersCursor?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: { __typename?: 'Aggregations'; lapsedAttenders?: number | null };
    } | null;
  } | null;
};

export type PeopleCountQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  paging: CursorPagingInput;
  queryParams?: InputMaybe<QueryParams>;
  campusKeys?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type PeopleCountQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    communityMembersCursor?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      paging: { __typename?: 'CursorPagingOutput'; totalItemCount: number };
    } | null;
  } | null;
};

export type PeopleListByCommunityMemberQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  campusKeys?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  paging: CursorPagingInput;
  queryParams?: InputMaybe<QueryParams>;
  isPeopleListExtraColumnsEnabled: Scalars['Boolean'];
}>;

export type PeopleListByCommunityMemberQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    communityMembersCursor?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      items: Array<{
        __typename?: 'CommunityMember';
        campusKey?: string | null;
        ccbMembershipTypeId?: number | null;
        communityMemberKey: string;
        firstName?: string | null;
        lastName?: string | null;
        nickname?: string | null;
        createdOn?: Instant | null;
        attendanceStatus?: string | null;
        givingStatus?: string | null;
        servingStatus?: string | null;
        lifeTimeGivingTotal?: string | null;
        lastGiftAmount?: string | null;
        lastGiftDate?: string | null;
        yearToDateGivingTotal?: string | null;
        yearToDateLargestGiftAmount?: string | null;
        yearToDateLargestGiftDate?: string | null;
        estimatedAverageMonthlyTotal?: string | null;
        ccbIndividual?: { __typename?: 'CcbIndividual'; individualId: number } | null;
        email?: { __typename?: 'Email'; address: string } | null;
        phoneNumber?: { __typename?: 'PhoneNumber'; countryCode?: string | null; number?: string | null } | null;
        profileImages?: { __typename?: 'ProfileImages'; thumbnailUrl?: string | null } | null;
      }>;
      paging: {
        __typename?: 'CursorPagingOutput';
        first?: string | null;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        last?: string | null;
        size: number;
        totalItemCount: number;
      };
    } | null;
  } | null;
};

export type PeopleListByHouseholdQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  campusKeys?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  paging: CursorPagingInput;
  queryParams?: InputMaybe<QueryParams>;
  isPeopleListExtraColumnsEnabled: Scalars['Boolean'];
}>;

export type PeopleListByHouseholdQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    households?: {
      __typename?: 'CursorPagingOutputOfHousehold';
      items: Array<{
        __typename?: 'Household';
        campusKey?: string | null;
        householdKey: string;
        name: string;
        numberOfMembers: number;
        createdOn: Instant;
        attendanceStatus?: string | null;
        givingStatus?: string | null;
        servingStatus?: string | null;
        lifeTimeGivingTotal?: string | null;
        lastGiftAmount?: string | null;
        lastGiftDate?: string | null;
        yearToDateGivingTotal?: string | null;
        yearToDateLargestGiftAmount?: string | null;
        yearToDateLargestGiftDate?: string | null;
        estimatedAverageMonthlyTotal?: string | null;
        email?: { __typename?: 'Email'; address: string } | null;
        members: Array<{
          __typename?: 'HouseholdMember';
          primary: boolean;
          communityMember: {
            __typename?: 'CommunityMember';
            ccbIndividual?: { __typename?: 'CcbIndividual'; individualId: number } | null;
          };
        }>;
        phone?: { __typename?: 'PhoneNumber'; countryCode?: string | null; number?: string | null } | null;
      }>;
      paging: {
        __typename?: 'CursorPagingOutput';
        first?: string | null;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        last?: string | null;
        size: number;
        totalItemCount: number;
      };
    } | null;
  } | null;
};

export type RecentNewVolunteersQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  paging: CursorPagingInput;
  queryParams?: InputMaybe<QueryParams>;
}>;

export type RecentNewVolunteersQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    communityMembersCursor?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: { __typename?: 'Aggregations'; recentNewVolunteers?: number | null };
    } | null;
  } | null;
};

export type RegularAttenderCountQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  paging: CursorPagingInput;
  queryParams?: InputMaybe<QueryParams>;
}>;

export type RegularAttenderCountQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    communityMembersCursor?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: { __typename?: 'Aggregations'; regularAttenders?: number | null };
    } | null;
  } | null;
};

export type VolunteerStagesQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  paging: CursorPagingInput;
  queryParams?: InputMaybe<QueryParams>;
}>;

export type VolunteerStagesQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    communityMembersCursor?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: {
        __typename?: 'Aggregations';
        firstTimeVolunteers?: number | null;
        lapsedVolunteers?: number | null;
        occasionalVolunteers?: number | null;
        regularVolunteers?: number | null;
        secondTimeVolunteers?: number | null;
      };
    } | null;
  } | null;
};

export type AttendanceDashboardCardQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  paging: CursorPagingInput;
  lastQueryParams: QueryParams;
  previousQueryParams: QueryParams;
}>;

export type AttendanceDashboardCardQuery = {
  __typename?: 'Query';
  AllInsightEligibleIndividuals?: {
    __typename?: 'Organization';
    communityMembersCursor?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: { __typename?: 'Aggregations'; All?: number | null };
    } | null;
  } | null;
  Attenders?: {
    __typename?: 'Organization';
    LastData?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: {
        __typename?: 'Aggregations';
        AtRisk?: number | null;
        Curious?: number | null;
        FirstTime?: number | null;
        RecentNew?: number | null;
        Regular?: number | null;
        Returning?: number | null;
        SecondTime?: number | null;
      };
    } | null;
    PreviousData?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: {
        __typename?: 'Aggregations';
        AtRisk?: number | null;
        Curious?: number | null;
        RecentNew?: number | null;
        Returning?: number | null;
      };
    } | null;
  } | null;
};

export type AttendanceDashboardTilesQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  paging: CursorPagingInput;
  attendanceChurnLapsedLastParams: QueryParams;
  attendanceChurnLapsedPrevParams: QueryParams;
  attendanceChurnRegularLastParams: QueryParams;
  attendanceChurnRegularPrevParams: QueryParams;
  potentialInactiveQueryParams: QueryParams;
}>;

export type AttendanceDashboardTilesQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    AttendanceChurnLapsedLastData?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: { __typename?: 'Aggregations'; count?: number | null };
    } | null;
    AttendanceChurnLapsedPrevData?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: { __typename?: 'Aggregations'; count?: number | null };
    } | null;
    AttendanceChurnRegularLastData?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: { __typename?: 'Aggregations'; count?: number | null };
    } | null;
    AttendanceChurnRegularPrevData?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: { __typename?: 'Aggregations'; count?: number | null };
    } | null;
    PotentialInactive?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: { __typename?: 'Aggregations'; count?: number | null };
    } | null;
  } | null;
};

export type GivingDashboardCardQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  paging: CursorPagingInput;
  donorRecentNewLastParams: QueryParams;
  donorRecentNewPrevParams: QueryParams;
  donorLastQueryParams: QueryParams;
  donorPreviousQueryParams: QueryParams;
  allIndividualsQueryParams: QueryParams;
}>;

export type GivingDashboardCardQuery = {
  __typename?: 'Query';
  AllInsightEligibleIndividuals?: {
    __typename?: 'Organization';
    communityMembersCursor?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: { __typename?: 'Aggregations'; All?: number | null };
    } | null;
  } | null;
  Donors?: {
    __typename?: 'Organization';
    RecentNewLast?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      paging: { __typename?: 'CursorPagingOutput'; totalItemCount: number };
    } | null;
    RecentNewPrevious?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      paging: { __typename?: 'CursorPagingOutput'; totalItemCount: number };
    } | null;
    LastData?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: {
        __typename?: 'Aggregations';
        AtRisk?: number | null;
        FirstTime?: number | null;
        Potential?: number | null;
        Recurring?: number | null;
        Regular?: number | null;
        SecondTime?: number | null;
      };
    } | null;
    PreviousData?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: { __typename?: 'Aggregations'; AtRisk?: number | null };
    } | null;
  } | null;
};

export type GivingDashboardTilesQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  isDisablePQSEnabled: Scalars['Boolean'];
  paging: CursorPagingInput;
  attendanceOverviewInput: AttendanceOverviewInput;
  donorCurrentTimeRange: GranularTimeRangeInput;
  donorOrganizationQueryFilter: OrganizationQueryFilterInput;
  donorLastQueryParams: QueryParams;
}>;

export type GivingDashboardTilesQuery = {
  __typename?: 'Query';
  organizationAttendanceOverview?: {
    __typename?: 'OrganizationAttendanceOverview';
    highlights: { __typename?: 'Attendance'; totalAttendance: number };
  } | null;
  Donors?: {
    __typename?: 'Organization';
    CurrentGivingSummaries?: {
      __typename?: 'OrganizationGivingPeriodSummariesNew';
      periods: Array<{
        __typename?: 'OrganizationGivingPeriodSummaryNew';
        fromDate: LocalDate;
        toDate: LocalDate;
        paymentsAggregate: {
          __typename?: 'MoneyAggregate';
          count: number;
          totalValue: { __typename?: 'Money'; amount: string; currency: Currency };
        };
      }>;
    } | null;
    communityMembersCursor?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: { __typename?: 'Aggregations'; Digital?: number | null; Offline?: number | null };
    } | null;
  } | null;
};

export type GivingHouseholdDashboardCardQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  paging: CursorPagingInput;
  donorRecentNewLastParams: QueryParams;
  donorRecentNewPrevParams: QueryParams;
  donorLastQueryParams: QueryParams;
  donorPreviousQueryParams: QueryParams;
  allHouseholdsQueryParams: QueryParams;
  donorPotentialQueryParams: QueryParams;
}>;

export type GivingHouseholdDashboardCardQuery = {
  __typename?: 'Query';
  AllInsightEligibleHouseholds?: {
    __typename?: 'Organization';
    households?: {
      __typename?: 'CursorPagingOutputOfHousehold';
      paging: { __typename?: 'CursorPagingOutput'; totalItemCount: number };
    } | null;
  } | null;
  Donors?: {
    __typename?: 'Organization';
    RecentNewLast?: {
      __typename?: 'CursorPagingOutputOfHousehold';
      paging: { __typename?: 'CursorPagingOutput'; totalItemCount: number };
    } | null;
    RecentNewPrevious?: {
      __typename?: 'CursorPagingOutputOfHousehold';
      paging: { __typename?: 'CursorPagingOutput'; totalItemCount: number };
    } | null;
    LastData?: {
      __typename?: 'CursorPagingOutputOfHousehold';
      aggregations: {
        __typename?: 'Aggregations';
        AtRisk?: number | null;
        FirstTime?: number | null;
        Recurring?: number | null;
        Regular?: number | null;
        SecondTime?: number | null;
      };
    } | null;
    Potential?: {
      __typename?: 'CursorPagingOutputOfHousehold';
      paging: { __typename?: 'CursorPagingOutput'; totalItemCount: number };
    } | null;
    PreviousData?: {
      __typename?: 'CursorPagingOutputOfHousehold';
      aggregations: { __typename?: 'Aggregations'; AtRisk?: number | null };
    } | null;
  } | null;
};

export type ServingDashboardCardQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  paging: CursorPagingInput;
  lastQueryParams: QueryParams;
  previousQueryParams: QueryParams;
}>;

export type ServingDashboardCardQuery = {
  __typename?: 'Query';
  AllInsightEligibleIndividuals?: {
    __typename?: 'Organization';
    communityMembersCursor?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: { __typename?: 'Aggregations'; All?: number | null };
    } | null;
  } | null;
  Volunteers?: {
    __typename?: 'Organization';
    LastData?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: {
        __typename?: 'Aggregations';
        AtRisk?: number | null;
        Curious?: number | null;
        FirstTime?: number | null;
        Potential?: number | null;
        RecentNew?: number | null;
        Regular?: number | null;
        Returning?: number | null;
        SecondTime?: number | null;
      };
    } | null;
    PreviousData?: {
      __typename?: 'CursorPagingOutputOfCommunityMember';
      aggregations: { __typename?: 'Aggregations'; AtRisk?: number | null; RecentNew?: number | null };
    } | null;
  } | null;
};

export type ServingDashboardTilesQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  lastServingSnapshotsInput: ServingSnapshotsInput;
  previousServingSnapshotsInput: ServingSnapshotsInput;
}>;

export type ServingDashboardTilesQuery = {
  __typename?: 'Query';
  organizationServingSnapshots: {
    __typename?: 'OrganizationServingSnapshots';
    LastData: {
      __typename?: 'ServingSnapshots';
      availableVolunteers: number;
      servingOpportunities: number;
      servingOpportunitiesUnfilled: number;
    };
    PreviousData: {
      __typename?: 'ServingSnapshots';
      availableVolunteers: number;
      servingOpportunities: number;
      servingOpportunitiesUnfilled: number;
    };
  };
};

export type GroupParticipationFilterValuesQueryVariables = Exact<{
  organizationKey: Scalars['String'];
}>;

export type GroupParticipationFilterValuesQuery = {
  __typename?: 'Query';
  groupParticipationFilterValues: {
    __typename?: 'GroupParticipationFilterValuesOutput';
    campusNames: Array<string>;
    departmentNames: Array<string>;
    groupNames: Array<string>;
    activeGroupNames: Array<string>;
    groupTypeNames: Array<string>;
    membershipTypeIds: Array<number>;
    inactiveGroups: Array<{ __typename?: 'InactiveGroup'; groupName: string; inactivatedDate: string }>;
  };
};

export type OrganizationGroupParticipationOverviewQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  groupParticipationOverviewInput?: InputMaybe<GroupParticipationOverviewInput>;
  comparisonDatesInput?: InputMaybe<ComparisonDatesInput>;
}>;

export type OrganizationGroupParticipationOverviewQuery = {
  __typename?: 'Query';
  organizationGroupParticipationOverview: {
    __typename?: 'OrganizationGroupParticipationOverview';
    comparisonHighlight?: { __typename?: 'GroupParticipationComparisonHighlight'; last: number } | null;
    highlights: {
      __typename?: 'GroupParticipationHighlights';
      groupLeadersTotal: number;
      groupMembersTotal: number;
      groupParticipantHouseholdsTotal: number;
      groupParticipantHouseholdsUnique: number;
      groupParticipantsAvg: number;
      groupParticipantsAvgMedian: number;
      groupParticipantsTotal: number;
      groupsTotal: number;
    };
    data: Array<{
      __typename?: 'GroupParticipationSummary';
      startDate: any;
      endDate: any;
      total: number;
      campusNames: Array<string>;
      departmentNames: Array<string>;
      groupNames: Array<string>;
      groupTypeNames: Array<string>;
      membershipTypeIds: Array<string>;
    }>;
  };
};

export type OrganizationGroupParticipationOverviewWithPreviousQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  groupParticipationOverviewInput?: InputMaybe<GroupParticipationOverviewInput>;
  groupParticipationOverviewInputPrevious?: InputMaybe<GroupParticipationOverviewInput>;
  comparisonDatesInput?: InputMaybe<ComparisonDatesInput>;
}>;

export type OrganizationGroupParticipationOverviewWithPreviousQuery = {
  __typename?: 'Query';
  organizationGroupParticipationOverview: {
    __typename?: 'OrganizationGroupParticipationOverview';
    comparisonHighlight?: {
      __typename?: 'GroupParticipationComparisonHighlight';
      last: number;
      previous?: number | null;
    } | null;
    highlights: {
      __typename?: 'GroupParticipationHighlights';
      groupLeadersTotal: number;
      groupMembersTotal: number;
      groupParticipantHouseholdsTotal: number;
      groupParticipantHouseholdsUnique: number;
      groupParticipantsAvg: number;
      groupParticipantsAvgMedian: number;
      groupParticipantsTotal: number;
      groupsTotal: number;
    };
    data: Array<{
      __typename?: 'GroupParticipationSummary';
      startDate: any;
      endDate: any;
      total: number;
      campusNames: Array<string>;
      departmentNames: Array<string>;
      groupNames: Array<string>;
      groupTypeNames: Array<string>;
      membershipTypeIds: Array<string>;
    }>;
    previousData: Array<{
      __typename?: 'GroupParticipationSummary';
      startDate: any;
      endDate: any;
      total: number;
      campusNames: Array<string>;
      departmentNames: Array<string>;
      groupNames: Array<string>;
      groupTypeNames: Array<string>;
      membershipTypeIds: Array<string>;
    }>;
  };
};

export type ExportJobsQueryVariables = Exact<{
  organizationKey: Scalars['String'];
}>;

export type ExportJobsQuery = {
  __typename?: 'Query';
  exportJobs: Array<{
    __typename?: 'ExportJob';
    createdAt: number;
    filename: string;
    exportLink?: string | null;
    exportSizeBytes?: number | null;
    jobKey: string;
    status: ExportJobStatus;
    exportJobOptions: {
      __typename?: 'ExportJobOptions';
      searchTerm?: string | null;
      viewBy: ExportJobViewBy;
      sort?: { __typename?: 'ExportJobSort'; columnName: string; direction: string } | null;
      type: {
        __typename?: 'ExportTypeOutput';
        attenderListType?: AttenderListType | null;
        donorListType?: DonorListType | null;
        volunteerListType?: VolunteerListType | null;
      };
    };
  }>;
};

export type InsightsExportPingQueryVariables = Exact<{
  organizationKey: Scalars['String'];
}>;

export type InsightsExportPingQuery = { __typename?: 'Query'; insightsExportPing: string };

export type OrganizationGivingSummariesByPeriodQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  currentTimeRange: GranularTimeRangeInput;
  previousTimeRange: GranularTimeRangeInput;
  organizationQueryFilter: OrganizationQueryFilterInput;
  disablePQS: Scalars['Boolean'];
}>;

export type OrganizationGivingSummariesByPeriodQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    current?: {
      __typename?: 'OrganizationGivingPeriodSummariesResult';
      communityMembersCount: number;
      householdCount: number;
      periods: Array<{
        __typename?: 'OrganizationGivingPeriodSummary';
        fromDate: LocalDate;
        toDate: LocalDate;
        paymentsAggregate: {
          __typename?: 'MoneyAggregate';
          count: number;
          totalValue: { __typename?: 'Money'; amount: string; currency: Currency };
        };
        nonCashGiftsAggregate: {
          __typename?: 'MoneyAggregate';
          count: number;
          totalValue: { __typename?: 'Money'; amount: string; currency: Currency };
        };
      }>;
    } | null;
    previous?: {
      __typename?: 'OrganizationGivingPeriodSummariesResult';
      communityMembersCount: number;
      householdCount: number;
      periods: Array<{
        __typename?: 'OrganizationGivingPeriodSummary';
        fromDate: LocalDate;
        toDate: LocalDate;
        paymentsAggregate: {
          __typename?: 'MoneyAggregate';
          count: number;
          totalValue: { __typename?: 'Money'; amount: string; currency: Currency };
        };
        nonCashGiftsAggregate: {
          __typename?: 'MoneyAggregate';
          count: number;
          totalValue: { __typename?: 'Money'; amount: string; currency: Currency };
        };
      }>;
    } | null;
  } | null;
};

export type OrganizationGivingSummariesByPeriodNewQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  currentTimeRange: GranularTimeRangeInput;
  previousTimeRange: GranularTimeRangeInput;
  organizationQueryFilter: OrganizationQueryFilterInput;
  disablePQS: Scalars['Boolean'];
}>;

export type OrganizationGivingSummariesByPeriodNewQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    current?: {
      __typename?: 'OrganizationGivingPeriodSummariesNew';
      communityMembersCount: number;
      householdsCount: number;
      periods: Array<{
        __typename?: 'OrganizationGivingPeriodSummaryNew';
        fromDate: LocalDate;
        toDate: LocalDate;
        paymentsAggregate: {
          __typename?: 'MoneyAggregate';
          count: number;
          totalValue: { __typename?: 'Money'; amount: string; currency: Currency };
        };
        nonCashGiftsAggregate: {
          __typename?: 'MoneyAggregate';
          count: number;
          totalValue: { __typename?: 'Money'; amount: string; currency: Currency };
        };
      }>;
    } | null;
    previous?: {
      __typename?: 'OrganizationGivingPeriodSummariesNew';
      communityMembersCount: number;
      householdsCount: number;
      periods: Array<{
        __typename?: 'OrganizationGivingPeriodSummaryNew';
        fromDate: LocalDate;
        toDate: LocalDate;
        paymentsAggregate: {
          __typename?: 'MoneyAggregate';
          count: number;
          totalValue: { __typename?: 'Money'; amount: string; currency: Currency };
        };
        nonCashGiftsAggregate: {
          __typename?: 'MoneyAggregate';
          count: number;
          totalValue: { __typename?: 'Money'; amount: string; currency: Currency };
        };
      }>;
    } | null;
  } | null;
};

export type TopCommunityMemberDonorSummaryQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  cursor: PqsCursorPagingInput;
  queryFilter: TopCommunityMemberQueryFilterInput;
  startDate: Scalars['Instant'];
  endDate: Scalars['Instant'];
  disablePQS: Scalars['Boolean'];
}>;

export type TopCommunityMemberDonorSummaryQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    organizationCursoredTopCommunityMembers?: {
      __typename?: 'CursorPagedOutputOfTopCommunityMember';
      paging: {
        __typename?: 'CursorPagingOutput';
        totalItemCount: number;
        first?: string | null;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        last?: string | null;
        size: number;
      };
      items: Array<{
        __typename?: 'TopCommunityMember';
        communityMemberKey: string;
        nonCashCount: number;
        paymentCount: number;
        communityMember?: {
          __typename?: 'CommunityMember';
          communityMemberKey: string;
          yearToDateLargestGiftFundKey?: string | null;
          lastGiftFundKey?: string | null;
          yearToDateLargestGiftMerchantIntegerId?: number | null;
          lastGiftMerchantIntegerId?: number | null;
          firstName?: string | null;
          lastName?: string | null;
          nickname?: string | null;
          ccbMembershipTypeId?: number | null;
          createdOn?: Instant | null;
          campusKey?: string | null;
          attendanceStatus?: string | null;
          givingStatus?: string | null;
          servingStatus?: string | null;
          estimatedAverageMonthlyTotal?: string | null;
          lifeTimeGivingTotal?: string | null;
          lastGiftAmount?: string | null;
          lastGiftDate?: string | null;
          yearToDateGivingTotal?: string | null;
          yearToDateLargestGiftAmount?: string | null;
          yearToDateLargestGiftDate?: string | null;
          ytdLargestGiftFund?: { __typename?: 'Fund'; name?: string | null } | null;
          lastGiftFund?: { __typename?: 'Fund'; name?: string | null } | null;
          ytdLargestGiftMerchant?: { __typename?: 'Merchant'; name: string } | null;
          lastGiftMerchant?: { __typename?: 'Merchant'; name: string } | null;
          ccbIndividual?: { __typename?: 'CcbIndividual'; individualId: number } | null;
          email?: { __typename?: 'Email'; address: string } | null;
          phoneNumber?: { __typename?: 'PhoneNumber'; number?: string | null } | null;
          profileImages?: { __typename?: 'ProfileImages'; thumbnailUrl?: string | null } | null;
        } | null;
        giftAmount: { __typename?: 'Money'; amount: string };
      }>;
    } | null;
  } | null;
};

export type TopHouseholdDonorSummaryQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  cursor: PqsCursorPagingInput;
  queryFilter: TopHouseholdQueryFilterInput;
  startDate: Scalars['Instant'];
  endDate: Scalars['Instant'];
  disablePQS: Scalars['Boolean'];
}>;

export type TopHouseholdDonorSummaryQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    organizationCursoredTopHouseholds?: {
      __typename?: 'CursorPagedOutputOfTopHousehold';
      paging: {
        __typename?: 'CursorPagingOutput';
        totalItemCount: number;
        first?: string | null;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        last?: string | null;
        size: number;
      };
      items: Array<{
        __typename?: 'TopHousehold';
        householdKey: string;
        nonCashCount: number;
        paymentCount: number;
        household?: {
          __typename?: 'Household';
          yearToDateLargestGiftFundKey?: string | null;
          lastGiftFundKey?: string | null;
          yearToDateLargestGiftMerchantIntegerId?: number | null;
          lastGiftMerchantIntegerId?: number | null;
          householdKey: string;
          campusKey?: string | null;
          listName: string;
          name: string;
          numberOfMembers: number;
          createdOn: Instant;
          attendanceStatus?: string | null;
          givingStatus?: string | null;
          servingStatus?: string | null;
          estimatedAverageMonthlyTotal?: string | null;
          lifeTimeGivingTotal?: string | null;
          lastGiftAmount?: string | null;
          lastGiftDate?: string | null;
          yearToDateGivingTotal?: string | null;
          yearToDateLargestGiftAmount?: string | null;
          yearToDateLargestGiftDate?: string | null;
          ytdLargestGiftFund?: { __typename?: 'Fund'; name?: string | null } | null;
          lastGiftFund?: { __typename?: 'Fund'; name?: string | null } | null;
          ytdLargestGiftMerchant?: { __typename?: 'Merchant'; name: string } | null;
          lastGiftMerchant?: { __typename?: 'Merchant'; name: string } | null;
          members: Array<{
            __typename?: 'HouseholdMember';
            primary: boolean;
            communityMember: {
              __typename?: 'CommunityMember';
              ccbIndividual?: { __typename?: 'CcbIndividual'; individualId: number } | null;
            };
          }>;
          email?: { __typename?: 'Email'; address: string } | null;
          phoneNumber?: { __typename?: 'PhoneNumber'; number?: string | null } | null;
        } | null;
        giftAmount: { __typename?: 'Money'; amount: string };
      }>;
    } | null;
  } | null;
};

export type ProcessesQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  paging: Chms_CursorPagingInput;
}>;

export type ProcessesQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    processes?: {
      __typename?: 'ProcessesPagedOutput';
      items: Array<{
        __typename?: 'Process';
        id: string;
        name: string;
        campus: { __typename?: 'chms_Campus'; id: string; name: string };
      }>;
      paging: { __typename?: 'CursorPagingOutput'; hasNextPage: boolean; last?: string | null };
    } | null;
  } | null;
};

export type QueuesByProcessIdQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  processId: Scalars['Int'];
  paging: Chms_CursorPagingInput;
}>;

export type QueuesByProcessIdQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    queues?: {
      __typename?: 'ProcessQueuesPagedOutput';
      items: Array<{ __typename?: 'ProcessQueue'; id: string; name: string }>;
      paging: { __typename?: 'CursorPagingOutput'; hasNextPage: boolean; last?: string | null };
    } | null;
  } | null;
};

export type OrganizationServingOverviewQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  servingOverviewInput?: InputMaybe<ServingOverviewInput>;
  comparisonDatesInput?: InputMaybe<ComparisonDatesInput>;
}>;

export type OrganizationServingOverviewQuery = {
  __typename?: 'Query';
  organizationServingOverview: {
    __typename?: 'OrganizationServingOverview';
    comparisonHighlight?: { __typename?: 'ServingComparisonHighlight'; last: number } | null;
    highlights: {
      __typename?: 'ServingHighlights';
      uniqueIndividuals: number;
      uniqueHouseholds: number;
      uniqueTeams: number;
      uniquePositions: number;
      averageServingOpportunities: number;
    };
    data: Array<{
      __typename?: 'ServingOpportunitySummary';
      startDate: any;
      endDate: any;
      total: number;
      campusNames: Array<string>;
      categoryNames: Array<string>;
      teamNames: Array<string>;
      positionNames: Array<string>;
      scheduleStartDateTimes: Array<string>;
      servingSummary: {
        __typename?: 'ServingSummary';
        uniqueIndividuals: number;
        uniqueHouseholds: number;
        uniqueTeams: number;
        uniquePositions: number;
        totalRolesFilled: number;
      };
    }>;
  };
};

export type OrganizationServingOverviewWithPreviousQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  servingOverviewInput?: InputMaybe<ServingOverviewInput>;
  servingOverviewInputPrevious?: InputMaybe<ServingOverviewInput>;
  comparisonDatesInput?: InputMaybe<ComparisonDatesInput>;
}>;

export type OrganizationServingOverviewWithPreviousQuery = {
  __typename?: 'Query';
  organizationServingOverview: {
    __typename?: 'OrganizationServingOverview';
    comparisonHighlight?: { __typename?: 'ServingComparisonHighlight'; previous?: number | null; last: number } | null;
    highlights: {
      __typename?: 'ServingHighlights';
      uniqueIndividuals: number;
      uniqueHouseholds: number;
      uniqueTeams: number;
      uniquePositions: number;
      averageServingOpportunities: number;
    };
    data: Array<{
      __typename?: 'ServingOpportunitySummary';
      startDate: any;
      endDate: any;
      total: number;
      campusNames: Array<string>;
      categoryNames: Array<string>;
      teamNames: Array<string>;
      positionNames: Array<string>;
      scheduleStartDateTimes: Array<string>;
      servingSummary: {
        __typename?: 'ServingSummary';
        uniqueIndividuals: number;
        uniqueHouseholds: number;
        uniqueTeams: number;
        uniquePositions: number;
        totalRolesFilled: number;
      };
    }>;
    previousData: Array<{
      __typename?: 'ServingOpportunitySummary';
      startDate: any;
      endDate: any;
      total: number;
      campusNames: Array<string>;
      categoryNames: Array<string>;
      teamNames: Array<string>;
      positionNames: Array<string>;
      scheduleStartDateTimes: Array<string>;
      servingSummary: {
        __typename?: 'ServingSummary';
        uniqueIndividuals: number;
        uniqueHouseholds: number;
        uniqueTeams: number;
        uniquePositions: number;
        totalRolesFilled: number;
      };
    }>;
  };
};

export type OrganizationServingSnapshotsQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  servingSnapshotsInput: ServingSnapshotsInput;
}>;

export type OrganizationServingSnapshotsQuery = {
  __typename?: 'Query';
  organizationServingSnapshots: {
    __typename?: 'OrganizationServingSnapshots';
    snapshots: {
      __typename?: 'ServingSnapshots';
      availableVolunteers: number;
      servingOpportunities: number;
      servingOpportunitiesUnfilled: number;
    };
  };
};

export type ServingFilterValuesQueryVariables = Exact<{
  organizationKey: Scalars['String'];
}>;

export type ServingFilterValuesQuery = {
  __typename?: 'Query';
  servingFilterValues: {
    __typename?: 'ServingFilterValuesOutput';
    campusNames: Array<string>;
    categoryNames: Array<string>;
    positionNames: Array<string>;
    teamNames: Array<string>;
  };
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'Identity';
    accountType?: AccountType | null;
    identityKey: Key;
    firstName?: string | null;
    lastName?: string | null;
    createdOn?: string | null;
    olsonTimeZone?: OlsonTimeZone | null;
    timeZoneId?: WindowsTimeZone | null;
    primaryEmailAddress?: { __typename?: 'EmailContact'; emailAddress?: string | null } | null;
    allPermissions?: {
      __typename?: 'AllIdentityPermissions';
      organizationPermissions: Array<{
        __typename?: 'OrganizationPermissions';
        organizationKey: Key;
        permissions: Array<{ __typename?: 'Permission'; name: string }>;
        campusPermissions: Array<{
          __typename?: 'CampusPermissions';
          campusKey: Key;
          permissions: Array<{ __typename?: 'Permission'; name: string }>;
        }>;
      }>;
    } | null;
    administeredOrganizations?: Array<{
      __typename?: 'AdministeredOrganization';
      name: string;
      key: string;
      logoUrl: string;
    }> | null;
  } | null;
};

export type MyOrganizationQueryVariables = Exact<{
  organizationKey: Scalars['String'];
}>;

export type MyOrganizationQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    key: string;
    membershipTypes?: Array<{ __typename?: 'MembershipType'; id: string; name: string }> | null;
    details?: {
      __typename?: 'OrganizationDetails';
      name?: string | null;
      ecgId?: string | null;
      catholicEntity: CatholicEntity;
      catholicSoftwareStatus: boolean;
      isGivingPlatformActive?: boolean | null;
      isMasAppPresent?: boolean | null;
      isSalesforceCustomAppPresent?: boolean | null;
      productInformation: {
        __typename?: 'ProductInfo';
        chmsInformation: { __typename?: 'ChmsInfo'; tenantUrl?: string | null; isProvisioned: boolean };
        streamingInformation: { __typename?: 'StreamingInfo'; id?: string | null };
      };
      campuses: Array<{ __typename?: 'Campus'; key: string; name: string }>;
      entitlements: Array<{ __typename?: 'Entitlement'; key: EntitlementKey; displayName: string }>;
    } | null;
    featureFlags: Array<{ __typename?: 'FeatureFlag'; name: string; enabled: boolean }>;
  } | null;
};

export type ValidatePendoGuideQueryVariables = Exact<{
  hash: Scalars['String'];
}>;

export type ValidatePendoGuideQuery = { __typename?: 'Query'; isGuideValid: boolean };

export type AddressKeySpecifier = (
  | 'city'
  | 'country'
  | 'createdOn'
  | 'line1'
  | 'line2'
  | 'postcode'
  | 'primary'
  | 'state'
  | 'type'
  | AddressKeySpecifier
)[];
export type AddressFieldPolicy = {
  city?: FieldPolicy<any> | FieldReadFunction<any>;
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  line1?: FieldPolicy<any> | FieldReadFunction<any>;
  line2?: FieldPolicy<any> | FieldReadFunction<any>;
  postcode?: FieldPolicy<any> | FieldReadFunction<any>;
  primary?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AdministeredOrganizationKeySpecifier = (
  | 'isMerchantAdmin'
  | 'key'
  | 'logoUrl'
  | 'name'
  | AdministeredOrganizationKeySpecifier
)[];
export type AdministeredOrganizationFieldPolicy = {
  isMerchantAdmin?: FieldPolicy<any> | FieldReadFunction<any>;
  key?: FieldPolicy<any> | FieldReadFunction<any>;
  logoUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AggregationsKeySpecifier = ('avg' | 'count' | 'max' | 'min' | 'sum' | AggregationsKeySpecifier)[];
export type AggregationsFieldPolicy = {
  avg?: FieldPolicy<any> | FieldReadFunction<any>;
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  max?: FieldPolicy<any> | FieldReadFunction<any>;
  min?: FieldPolicy<any> | FieldReadFunction<any>;
  sum?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AllIdentityPermissionsKeySpecifier = (
  | 'organizationPermissions'
  | 'permissions'
  | AllIdentityPermissionsKeySpecifier
)[];
export type AllIdentityPermissionsFieldPolicy = {
  organizationPermissions?: FieldPolicy<any> | FieldReadFunction<any>;
  permissions?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AttendanceKeySpecifier = (
  | 'averageAttendance'
  | 'checkinAttendance'
  | 'familyAttendance'
  | 'firstTimeGuests'
  | 'headCountAttendance'
  | 'individualAttendance'
  | 'servingAttendance'
  | 'streamingAttendance'
  | 'totalAttendance'
  | 'uniqueFamilyAttendance'
  | 'uniqueIndividualAttendance'
  | AttendanceKeySpecifier
)[];
export type AttendanceFieldPolicy = {
  averageAttendance?: FieldPolicy<any> | FieldReadFunction<any>;
  checkinAttendance?: FieldPolicy<any> | FieldReadFunction<any>;
  familyAttendance?: FieldPolicy<any> | FieldReadFunction<any>;
  firstTimeGuests?: FieldPolicy<any> | FieldReadFunction<any>;
  headCountAttendance?: FieldPolicy<any> | FieldReadFunction<any>;
  individualAttendance?: FieldPolicy<any> | FieldReadFunction<any>;
  servingAttendance?: FieldPolicy<any> | FieldReadFunction<any>;
  streamingAttendance?: FieldPolicy<any> | FieldReadFunction<any>;
  totalAttendance?: FieldPolicy<any> | FieldReadFunction<any>;
  uniqueFamilyAttendance?: FieldPolicy<any> | FieldReadFunction<any>;
  uniqueIndividualAttendance?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AttendanceComparisonHighlightKeySpecifier = (
  | 'last'
  | 'previous'
  | AttendanceComparisonHighlightKeySpecifier
)[];
export type AttendanceComparisonHighlightFieldPolicy = {
  last?: FieldPolicy<any> | FieldReadFunction<any>;
  previous?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AttendanceQueryFiltersOutputKeySpecifier = (
  | 'attendanceGroupingNames'
  | 'campusNames'
  | 'departmentNames'
  | 'eventNames'
  | 'groupNames'
  | 'groupTypeNames'
  | 'membershipTypeIds'
  | 'streamingEventNames'
  | AttendanceQueryFiltersOutputKeySpecifier
)[];
export type AttendanceQueryFiltersOutputFieldPolicy = {
  attendanceGroupingNames?: FieldPolicy<any> | FieldReadFunction<any>;
  campusNames?: FieldPolicy<any> | FieldReadFunction<any>;
  departmentNames?: FieldPolicy<any> | FieldReadFunction<any>;
  eventNames?: FieldPolicy<any> | FieldReadFunction<any>;
  groupNames?: FieldPolicy<any> | FieldReadFunction<any>;
  groupTypeNames?: FieldPolicy<any> | FieldReadFunction<any>;
  membershipTypeIds?: FieldPolicy<any> | FieldReadFunction<any>;
  streamingEventNames?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CampusKeySpecifier = ('key' | 'name' | CampusKeySpecifier)[];
export type CampusFieldPolicy = {
  key?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CampusPermissionsKeySpecifier = ('campusKey' | 'permissions' | CampusPermissionsKeySpecifier)[];
export type CampusPermissionsFieldPolicy = {
  campusKey?: FieldPolicy<any> | FieldReadFunction<any>;
  permissions?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CampusesPagedOutputKeySpecifier = ('items' | 'paging' | CampusesPagedOutputKeySpecifier)[];
export type CampusesPagedOutputFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  paging?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CcbIndividualKeySpecifier = ('deepLinkUrl' | 'individualId' | CcbIndividualKeySpecifier)[];
export type CcbIndividualFieldPolicy = {
  deepLinkUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  individualId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ChMSOrganizationKeySpecifier = (
  | 'campuses'
  | 'chmsOrgId'
  | 'membershipTypes'
  | 'processes'
  | 'queues'
  | ChMSOrganizationKeySpecifier
)[];
export type ChMSOrganizationFieldPolicy = {
  campuses?: FieldPolicy<any> | FieldReadFunction<any>;
  chmsOrgId?: FieldPolicy<any> | FieldReadFunction<any>;
  membershipTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  processes?: FieldPolicy<any> | FieldReadFunction<any>;
  queues?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ChmsInfoKeySpecifier = ('contractStatus' | 'id' | 'isProvisioned' | 'tenantUrl' | ChmsInfoKeySpecifier)[];
export type ChmsInfoFieldPolicy = {
  contractStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  isProvisioned?: FieldPolicy<any> | FieldReadFunction<any>;
  tenantUrl?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ClientFieldKeySpecifier = ('field' | ClientFieldKeySpecifier)[];
export type ClientFieldFieldPolicy = {
  field?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CommunityKeySpecifier = (
  | 'ageOfIndependence'
  | 'isInSync'
  | 'isSyncEnabled'
  | 'isSyncRunning'
  | CommunityKeySpecifier
)[];
export type CommunityFieldPolicy = {
  ageOfIndependence?: FieldPolicy<any> | FieldReadFunction<any>;
  isInSync?: FieldPolicy<any> | FieldReadFunction<any>;
  isSyncEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  isSyncRunning?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CommunityMemberKeySpecifier = (
  | 'address'
  | 'addresses'
  | 'age'
  | 'ageCategory'
  | 'archived'
  | 'archivedOn'
  | 'campusKey'
  | 'ccbIndividual'
  | 'ccbMembershipTypeId'
  | 'community'
  | 'communityMemberKey'
  | 'communityMemberType'
  | 'createdOn'
  | 'dateOfBirth'
  | 'dateOfDeath'
  | 'dateStampOfBirth'
  | 'dateStampOfDeath'
  | 'deceased'
  | 'deceasedSetOn'
  | 'dependent'
  | 'detailsUpdatedOn'
  | 'donor'
  | 'email'
  | 'emailAddress'
  | 'emails'
  | 'engagement'
  | 'externalIds'
  | 'externalReference'
  | 'firstName'
  | 'formerLastNames'
  | 'gender'
  | 'householdMoveOutStatus'
  | 'households'
  | 'lastActiveOn'
  | 'lastGiftFund'
  | 'lastGiftFundKey'
  | 'lastGiftMerchant'
  | 'lastGiftMerchantId'
  | 'lastGiftMerchantIntegerId'
  | 'lastName'
  | 'lastPaymentOn'
  | 'legalFirstName'
  | 'legalFullName'
  | 'lifetimeLargestGiftFundKey'
  | 'lifetimeLargestGiftMerchantId'
  | 'middleNames'
  | 'mobileNumber'
  | 'nickname'
  | 'phoneNumber'
  | 'phoneNumbers'
  | 'preferredFirstName'
  | 'preferredFullName'
  | 'preferredFullNameWithNickname'
  | 'preferredName'
  | 'prefix'
  | 'primaryCustomerInputExternalId'
  | 'primaryHousehold'
  | 'profileCreatedOn'
  | 'profileImages'
  | 'suffix'
  | 'tag'
  | 'timeZoneId'
  | 'updatedOn'
  | 'yearToDateLargestGiftFundKey'
  | 'yearToDateLargestGiftMerchantId'
  | 'yearToDateLargestGiftMerchantIntegerId'
  | 'ytdLargestGiftFund'
  | 'ytdLargestGiftMerchant'
  | CommunityMemberKeySpecifier
)[];
export type CommunityMemberFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  addresses?: FieldPolicy<any> | FieldReadFunction<any>;
  age?: FieldPolicy<any> | FieldReadFunction<any>;
  ageCategory?: FieldPolicy<any> | FieldReadFunction<any>;
  archived?: FieldPolicy<any> | FieldReadFunction<any>;
  archivedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  campusKey?: FieldPolicy<any> | FieldReadFunction<any>;
  ccbIndividual?: FieldPolicy<any> | FieldReadFunction<any>;
  ccbMembershipTypeId?: FieldPolicy<any> | FieldReadFunction<any>;
  community?: FieldPolicy<any> | FieldReadFunction<any>;
  communityMemberKey?: FieldPolicy<any> | FieldReadFunction<any>;
  communityMemberType?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  dateOfBirth?: FieldPolicy<any> | FieldReadFunction<any>;
  dateOfDeath?: FieldPolicy<any> | FieldReadFunction<any>;
  dateStampOfBirth?: FieldPolicy<any> | FieldReadFunction<any>;
  dateStampOfDeath?: FieldPolicy<any> | FieldReadFunction<any>;
  deceased?: FieldPolicy<any> | FieldReadFunction<any>;
  deceasedSetOn?: FieldPolicy<any> | FieldReadFunction<any>;
  dependent?: FieldPolicy<any> | FieldReadFunction<any>;
  detailsUpdatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  donor?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  emailAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  emails?: FieldPolicy<any> | FieldReadFunction<any>;
  engagement?: FieldPolicy<any> | FieldReadFunction<any>;
  externalIds?: FieldPolicy<any> | FieldReadFunction<any>;
  externalReference?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  formerLastNames?: FieldPolicy<any> | FieldReadFunction<any>;
  gender?: FieldPolicy<any> | FieldReadFunction<any>;
  householdMoveOutStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  households?: FieldPolicy<any> | FieldReadFunction<any>;
  lastActiveOn?: FieldPolicy<any> | FieldReadFunction<any>;
  lastGiftFund?: FieldPolicy<any> | FieldReadFunction<any>;
  lastGiftFundKey?: FieldPolicy<any> | FieldReadFunction<any>;
  lastGiftMerchant?: FieldPolicy<any> | FieldReadFunction<any>;
  lastGiftMerchantId?: FieldPolicy<any> | FieldReadFunction<any>;
  lastGiftMerchantIntegerId?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  lastPaymentOn?: FieldPolicy<any> | FieldReadFunction<any>;
  legalFirstName?: FieldPolicy<any> | FieldReadFunction<any>;
  legalFullName?: FieldPolicy<any> | FieldReadFunction<any>;
  lifetimeLargestGiftFundKey?: FieldPolicy<any> | FieldReadFunction<any>;
  lifetimeLargestGiftMerchantId?: FieldPolicy<any> | FieldReadFunction<any>;
  middleNames?: FieldPolicy<any> | FieldReadFunction<any>;
  mobileNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  nickname?: FieldPolicy<any> | FieldReadFunction<any>;
  phoneNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  phoneNumbers?: FieldPolicy<any> | FieldReadFunction<any>;
  preferredFirstName?: FieldPolicy<any> | FieldReadFunction<any>;
  preferredFullName?: FieldPolicy<any> | FieldReadFunction<any>;
  preferredFullNameWithNickname?: FieldPolicy<any> | FieldReadFunction<any>;
  preferredName?: FieldPolicy<any> | FieldReadFunction<any>;
  prefix?: FieldPolicy<any> | FieldReadFunction<any>;
  primaryCustomerInputExternalId?: FieldPolicy<any> | FieldReadFunction<any>;
  primaryHousehold?: FieldPolicy<any> | FieldReadFunction<any>;
  profileCreatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  profileImages?: FieldPolicy<any> | FieldReadFunction<any>;
  suffix?: FieldPolicy<any> | FieldReadFunction<any>;
  tag?: FieldPolicy<any> | FieldReadFunction<any>;
  timeZoneId?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  yearToDateLargestGiftFundKey?: FieldPolicy<any> | FieldReadFunction<any>;
  yearToDateLargestGiftMerchantId?: FieldPolicy<any> | FieldReadFunction<any>;
  yearToDateLargestGiftMerchantIntegerId?: FieldPolicy<any> | FieldReadFunction<any>;
  ytdLargestGiftFund?: FieldPolicy<any> | FieldReadFunction<any>;
  ytdLargestGiftMerchant?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CommunityMemberCollectionResultKeySpecifier = (
  | 'communityMembers'
  | 'success'
  | CommunityMemberCollectionResultKeySpecifier
)[];
export type CommunityMemberCollectionResultFieldPolicy = {
  communityMembers?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CommunityMemberEngagementKeySpecifier = (
  | 'isEngaged'
  | 'lastActiveOn'
  | 'lastAppActivity'
  | 'lastPayment'
  | CommunityMemberEngagementKeySpecifier
)[];
export type CommunityMemberEngagementFieldPolicy = {
  isEngaged?: FieldPolicy<any> | FieldReadFunction<any>;
  lastActiveOn?: FieldPolicy<any> | FieldReadFunction<any>;
  lastAppActivity?: FieldPolicy<any> | FieldReadFunction<any>;
  lastPayment?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CommunityMemberResultKeySpecifier = ('communityMember' | CommunityMemberResultKeySpecifier)[];
export type CommunityMemberResultFieldPolicy = {
  communityMember?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CursorPagedOutputKeySpecifier = ('paging' | CursorPagedOutputKeySpecifier)[];
export type CursorPagedOutputFieldPolicy = {
  paging?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CursorPagedOutputOfTopCommunityMemberKeySpecifier = (
  | 'items'
  | 'paging'
  | CursorPagedOutputOfTopCommunityMemberKeySpecifier
)[];
export type CursorPagedOutputOfTopCommunityMemberFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  paging?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CursorPagedOutputOfTopHouseholdKeySpecifier = (
  | 'items'
  | 'paging'
  | CursorPagedOutputOfTopHouseholdKeySpecifier
)[];
export type CursorPagedOutputOfTopHouseholdFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  paging?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CursorPagingOutputKeySpecifier = (
  | 'first'
  | 'hasNextPage'
  | 'hasPreviousPage'
  | 'last'
  | 'size'
  | 'totalItemCount'
  | CursorPagingOutputKeySpecifier
)[];
export type CursorPagingOutputFieldPolicy = {
  first?: FieldPolicy<any> | FieldReadFunction<any>;
  hasNextPage?: FieldPolicy<any> | FieldReadFunction<any>;
  hasPreviousPage?: FieldPolicy<any> | FieldReadFunction<any>;
  last?: FieldPolicy<any> | FieldReadFunction<any>;
  size?: FieldPolicy<any> | FieldReadFunction<any>;
  totalItemCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CursorPagingOutputOfCommunityMemberKeySpecifier = (
  | 'aggregations'
  | 'items'
  | 'paging'
  | CursorPagingOutputOfCommunityMemberKeySpecifier
)[];
export type CursorPagingOutputOfCommunityMemberFieldPolicy = {
  aggregations?: FieldPolicy<any> | FieldReadFunction<any>;
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  paging?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CursorPagingOutputOfHouseholdKeySpecifier = (
  | 'aggregations'
  | 'items'
  | 'paging'
  | CursorPagingOutputOfHouseholdKeySpecifier
)[];
export type CursorPagingOutputOfHouseholdFieldPolicy = {
  aggregations?: FieldPolicy<any> | FieldReadFunction<any>;
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  paging?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DateStampKeySpecifier = ('day' | 'month' | 'year' | DateStampKeySpecifier)[];
export type DateStampFieldPolicy = {
  day?: FieldPolicy<any> | FieldReadFunction<any>;
  month?: FieldPolicy<any> | FieldReadFunction<any>;
  year?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DateStampOfBirthKeySpecifier = ('day' | 'month' | 'year' | DateStampOfBirthKeySpecifier)[];
export type DateStampOfBirthFieldPolicy = {
  day?: FieldPolicy<any> | FieldReadFunction<any>;
  month?: FieldPolicy<any> | FieldReadFunction<any>;
  year?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DateStampOfDeathKeySpecifier = ('day' | 'month' | 'year' | DateStampOfDeathKeySpecifier)[];
export type DateStampOfDeathFieldPolicy = {
  day?: FieldPolicy<any> | FieldReadFunction<any>;
  month?: FieldPolicy<any> | FieldReadFunction<any>;
  year?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EmailKeySpecifier = ('address' | 'createdOn' | 'primary' | 'type' | 'verified' | EmailKeySpecifier)[];
export type EmailFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  primary?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  verified?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EmailAddressValidationErrorKeySpecifier = ('fieldPath' | EmailAddressValidationErrorKeySpecifier)[];
export type EmailAddressValidationErrorFieldPolicy = {
  fieldPath?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EmailContactKeySpecifier = ('emailAddress' | 'verified' | EmailContactKeySpecifier)[];
export type EmailContactFieldPolicy = {
  emailAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  verified?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EntitlementKeySpecifier = ('displayName' | 'key' | EntitlementKeySpecifier)[];
export type EntitlementFieldPolicy = {
  displayName?: FieldPolicy<any> | FieldReadFunction<any>;
  key?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EventAttendanceKeySpecifier = (
  | 'attendance'
  | 'attendanceGroupingName'
  | 'campusName'
  | 'departmentId'
  | 'departmentName'
  | 'eventId'
  | 'eventName'
  | 'groupId'
  | 'groupName'
  | 'groupTypeName'
  | 'membershipTypeIds'
  | 'occurrence'
  | EventAttendanceKeySpecifier
)[];
export type EventAttendanceFieldPolicy = {
  attendance?: FieldPolicy<any> | FieldReadFunction<any>;
  attendanceGroupingName?: FieldPolicy<any> | FieldReadFunction<any>;
  campusName?: FieldPolicy<any> | FieldReadFunction<any>;
  departmentId?: FieldPolicy<any> | FieldReadFunction<any>;
  departmentName?: FieldPolicy<any> | FieldReadFunction<any>;
  eventId?: FieldPolicy<any> | FieldReadFunction<any>;
  eventName?: FieldPolicy<any> | FieldReadFunction<any>;
  groupId?: FieldPolicy<any> | FieldReadFunction<any>;
  groupName?: FieldPolicy<any> | FieldReadFunction<any>;
  groupTypeName?: FieldPolicy<any> | FieldReadFunction<any>;
  membershipTypeIds?: FieldPolicy<any> | FieldReadFunction<any>;
  occurrence?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EventAttendanceSummaryKeySpecifier = (
  | 'attendanceGroupingNames'
  | 'attendanceSummary'
  | 'campusNames'
  | 'departmentNames'
  | 'endDate'
  | 'eventNames'
  | 'groupNames'
  | 'groupTypeNames'
  | 'membershipTypeIds'
  | 'startDate'
  | 'total'
  | EventAttendanceSummaryKeySpecifier
)[];
export type EventAttendanceSummaryFieldPolicy = {
  attendanceGroupingNames?: FieldPolicy<any> | FieldReadFunction<any>;
  attendanceSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  campusNames?: FieldPolicy<any> | FieldReadFunction<any>;
  departmentNames?: FieldPolicy<any> | FieldReadFunction<any>;
  endDate?: FieldPolicy<any> | FieldReadFunction<any>;
  eventNames?: FieldPolicy<any> | FieldReadFunction<any>;
  groupNames?: FieldPolicy<any> | FieldReadFunction<any>;
  groupTypeNames?: FieldPolicy<any> | FieldReadFunction<any>;
  membershipTypeIds?: FieldPolicy<any> | FieldReadFunction<any>;
  startDate?: FieldPolicy<any> | FieldReadFunction<any>;
  total?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ExportJobKeySpecifier = (
  | 'createdAt'
  | 'exportJobOptions'
  | 'exportLink'
  | 'exportSizeBytes'
  | 'filename'
  | 'jobKey'
  | 'status'
  | ExportJobKeySpecifier
)[];
export type ExportJobFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  exportJobOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  exportLink?: FieldPolicy<any> | FieldReadFunction<any>;
  exportSizeBytes?: FieldPolicy<any> | FieldReadFunction<any>;
  filename?: FieldPolicy<any> | FieldReadFunction<any>;
  jobKey?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ExportJobCanceledOutputKeySpecifier = ('export' | ExportJobCanceledOutputKeySpecifier)[];
export type ExportJobCanceledOutputFieldPolicy = {
  export?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ExportJobDeletedOutputKeySpecifier = ('export' | ExportJobDeletedOutputKeySpecifier)[];
export type ExportJobDeletedOutputFieldPolicy = {
  export?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ExportJobOptionsKeySpecifier = (
  | 'attendanceStatus'
  | 'campusKeys'
  | 'donorStatus'
  | 'membershipTypeIds'
  | 'searchTerm'
  | 'sort'
  | 'type'
  | 'viewBy'
  | 'volunteerStatus'
  | ExportJobOptionsKeySpecifier
)[];
export type ExportJobOptionsFieldPolicy = {
  attendanceStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  campusKeys?: FieldPolicy<any> | FieldReadFunction<any>;
  donorStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  membershipTypeIds?: FieldPolicy<any> | FieldReadFunction<any>;
  searchTerm?: FieldPolicy<any> | FieldReadFunction<any>;
  sort?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  viewBy?: FieldPolicy<any> | FieldReadFunction<any>;
  volunteerStatus?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ExportJobSortKeySpecifier = ('columnName' | 'direction' | ExportJobSortKeySpecifier)[];
export type ExportJobSortFieldPolicy = {
  columnName?: FieldPolicy<any> | FieldReadFunction<any>;
  direction?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ExportQueuedOutputKeySpecifier = ('export' | ExportQueuedOutputKeySpecifier)[];
export type ExportQueuedOutputFieldPolicy = {
  export?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ExportTypeOutputKeySpecifier = (
  | 'attenderListType'
  | 'donorListType'
  | 'volunteerListType'
  | ExportTypeOutputKeySpecifier
)[];
export type ExportTypeOutputFieldPolicy = {
  attenderListType?: FieldPolicy<any> | FieldReadFunction<any>;
  donorListType?: FieldPolicy<any> | FieldReadFunction<any>;
  volunteerListType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ExternalIdKeySpecifier = ('createdOn' | 'primary' | 'type' | 'value' | ExternalIdKeySpecifier)[];
export type ExternalIdFieldPolicy = {
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  primary?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FeatureFlagKeySpecifier = ('enabled' | 'name' | FeatureFlagKeySpecifier)[];
export type FeatureFlagFieldPolicy = {
  enabled?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FormerHouseholdMemberKeySpecifier = (
  | 'communityMember'
  | 'createdOn'
  | 'formerHouseholdMemberPosition'
  | FormerHouseholdMemberKeySpecifier
)[];
export type FormerHouseholdMemberFieldPolicy = {
  communityMember?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  formerHouseholdMemberPosition?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FormerLastNameKeySpecifier = ('changedAwayOn' | 'createdOn' | 'lastName' | FormerLastNameKeySpecifier)[];
export type FormerLastNameFieldPolicy = {
  changedAwayOn?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FundKeySpecifier = ('name' | FundKeySpecifier)[];
export type FundFieldPolicy = {
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GivingOrganizationKeySpecifier = ('fund' | 'merchant' | GivingOrganizationKeySpecifier)[];
export type GivingOrganizationFieldPolicy = {
  fund?: FieldPolicy<any> | FieldReadFunction<any>;
  merchant?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GroupParticipationComparisonHighlightKeySpecifier = (
  | 'last'
  | 'previous'
  | GroupParticipationComparisonHighlightKeySpecifier
)[];
export type GroupParticipationComparisonHighlightFieldPolicy = {
  last?: FieldPolicy<any> | FieldReadFunction<any>;
  previous?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GroupParticipationFilterValuesOutputKeySpecifier = (
  | 'activeGroupNames'
  | 'campusNames'
  | 'departmentNames'
  | 'groupNames'
  | 'groupTypeNames'
  | 'inactiveGroups'
  | 'membershipTypeIds'
  | GroupParticipationFilterValuesOutputKeySpecifier
)[];
export type GroupParticipationFilterValuesOutputFieldPolicy = {
  activeGroupNames?: FieldPolicy<any> | FieldReadFunction<any>;
  campusNames?: FieldPolicy<any> | FieldReadFunction<any>;
  departmentNames?: FieldPolicy<any> | FieldReadFunction<any>;
  groupNames?: FieldPolicy<any> | FieldReadFunction<any>;
  groupTypeNames?: FieldPolicy<any> | FieldReadFunction<any>;
  inactiveGroups?: FieldPolicy<any> | FieldReadFunction<any>;
  membershipTypeIds?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GroupParticipationHighlightsKeySpecifier = (
  | 'groupLeadersTotal'
  | 'groupMembersTotal'
  | 'groupParticipantHouseholdsTotal'
  | 'groupParticipantHouseholdsUnique'
  | 'groupParticipantsAvg'
  | 'groupParticipantsAvgMedian'
  | 'groupParticipantsTotal'
  | 'groupsTotal'
  | GroupParticipationHighlightsKeySpecifier
)[];
export type GroupParticipationHighlightsFieldPolicy = {
  groupLeadersTotal?: FieldPolicy<any> | FieldReadFunction<any>;
  groupMembersTotal?: FieldPolicy<any> | FieldReadFunction<any>;
  groupParticipantHouseholdsTotal?: FieldPolicy<any> | FieldReadFunction<any>;
  groupParticipantHouseholdsUnique?: FieldPolicy<any> | FieldReadFunction<any>;
  groupParticipantsAvg?: FieldPolicy<any> | FieldReadFunction<any>;
  groupParticipantsAvgMedian?: FieldPolicy<any> | FieldReadFunction<any>;
  groupParticipantsTotal?: FieldPolicy<any> | FieldReadFunction<any>;
  groupsTotal?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GroupParticipationSuccessResultKeySpecifier = ('success' | GroupParticipationSuccessResultKeySpecifier)[];
export type GroupParticipationSuccessResultFieldPolicy = {
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GroupParticipationSummaryKeySpecifier = (
  | 'campusNames'
  | 'departmentNames'
  | 'endDate'
  | 'groupNames'
  | 'groupTypeNames'
  | 'membershipTypeIds'
  | 'startDate'
  | 'total'
  | GroupParticipationSummaryKeySpecifier
)[];
export type GroupParticipationSummaryFieldPolicy = {
  campusNames?: FieldPolicy<any> | FieldReadFunction<any>;
  departmentNames?: FieldPolicy<any> | FieldReadFunction<any>;
  endDate?: FieldPolicy<any> | FieldReadFunction<any>;
  groupNames?: FieldPolicy<any> | FieldReadFunction<any>;
  groupTypeNames?: FieldPolicy<any> | FieldReadFunction<any>;
  membershipTypeIds?: FieldPolicy<any> | FieldReadFunction<any>;
  startDate?: FieldPolicy<any> | FieldReadFunction<any>;
  total?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type HouseholdKeySpecifier = (
  | 'address'
  | 'archived'
  | 'archivedOn'
  | 'campusKey'
  | 'community'
  | 'createdOn'
  | 'donor'
  | 'email'
  | 'externalIds'
  | 'formerMembers'
  | 'householdKey'
  | 'lastGiftFund'
  | 'lastGiftFundKey'
  | 'lastGiftMerchant'
  | 'lastGiftMerchantId'
  | 'lastGiftMerchantIntegerId'
  | 'lifetimeLargestGiftFundKey'
  | 'lifetimeLargestGiftMerchantId'
  | 'listName'
  | 'members'
  | 'name'
  | 'numberOfMembers'
  | 'phone'
  | 'primaryContact'
  | 'secondaryContact'
  | 'tag'
  | 'updatedOn'
  | 'yearToDateLargestGiftFundKey'
  | 'yearToDateLargestGiftMerchantId'
  | 'yearToDateLargestGiftMerchantIntegerId'
  | 'yourHouseholdId'
  | 'ytdLargestGiftFund'
  | 'ytdLargestGiftMerchant'
  | HouseholdKeySpecifier
)[];
export type HouseholdFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  archived?: FieldPolicy<any> | FieldReadFunction<any>;
  archivedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  campusKey?: FieldPolicy<any> | FieldReadFunction<any>;
  community?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  donor?: FieldPolicy<any> | FieldReadFunction<any>;
  email?: FieldPolicy<any> | FieldReadFunction<any>;
  externalIds?: FieldPolicy<any> | FieldReadFunction<any>;
  formerMembers?: FieldPolicy<any> | FieldReadFunction<any>;
  householdKey?: FieldPolicy<any> | FieldReadFunction<any>;
  lastGiftFund?: FieldPolicy<any> | FieldReadFunction<any>;
  lastGiftFundKey?: FieldPolicy<any> | FieldReadFunction<any>;
  lastGiftMerchant?: FieldPolicy<any> | FieldReadFunction<any>;
  lastGiftMerchantId?: FieldPolicy<any> | FieldReadFunction<any>;
  lastGiftMerchantIntegerId?: FieldPolicy<any> | FieldReadFunction<any>;
  lifetimeLargestGiftFundKey?: FieldPolicy<any> | FieldReadFunction<any>;
  lifetimeLargestGiftMerchantId?: FieldPolicy<any> | FieldReadFunction<any>;
  listName?: FieldPolicy<any> | FieldReadFunction<any>;
  members?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  numberOfMembers?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  primaryContact?: FieldPolicy<any> | FieldReadFunction<any>;
  secondaryContact?: FieldPolicy<any> | FieldReadFunction<any>;
  tag?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
  yearToDateLargestGiftFundKey?: FieldPolicy<any> | FieldReadFunction<any>;
  yearToDateLargestGiftMerchantId?: FieldPolicy<any> | FieldReadFunction<any>;
  yearToDateLargestGiftMerchantIntegerId?: FieldPolicy<any> | FieldReadFunction<any>;
  yourHouseholdId?: FieldPolicy<any> | FieldReadFunction<any>;
  ytdLargestGiftFund?: FieldPolicy<any> | FieldReadFunction<any>;
  ytdLargestGiftMerchant?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type HouseholdContactKeySpecifier = ('firstName' | 'lastName' | HouseholdContactKeySpecifier)[];
export type HouseholdContactFieldPolicy = {
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type HouseholdMemberKeySpecifier = (
  | 'communityMember'
  | 'createdOn'
  | 'household'
  | 'householdKey'
  | 'position'
  | 'primary'
  | 'updatedOn'
  | HouseholdMemberKeySpecifier
)[];
export type HouseholdMemberFieldPolicy = {
  communityMember?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  household?: FieldPolicy<any> | FieldReadFunction<any>;
  householdKey?: FieldPolicy<any> | FieldReadFunction<any>;
  position?: FieldPolicy<any> | FieldReadFunction<any>;
  primary?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type HouseholdMoveOutStatusKeySpecifier = (
  | 'moveOutAttempted'
  | 'moveOutDate'
  | HouseholdMoveOutStatusKeySpecifier
)[];
export type HouseholdMoveOutStatusFieldPolicy = {
  moveOutAttempted?: FieldPolicy<any> | FieldReadFunction<any>;
  moveOutDate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type HouseholdResultKeySpecifier = ('household' | HouseholdResultKeySpecifier)[];
export type HouseholdResultFieldPolicy = {
  household?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IValidationErrorKeySpecifier = ('fieldPath' | IValidationErrorKeySpecifier)[];
export type IValidationErrorFieldPolicy = {
  fieldPath?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IdentityKeySpecifier = (
  | 'accountType'
  | 'administeredOrganizations'
  | 'allPermissions'
  | 'createdOn'
  | 'emailAddresses'
  | 'firstName'
  | 'identityKey'
  | 'lastName'
  | 'olsonTimeZone'
  | 'primaryEmailAddress'
  | 'timeZoneId'
  | IdentityKeySpecifier
)[];
export type IdentityFieldPolicy = {
  accountType?: FieldPolicy<any> | FieldReadFunction<any>;
  administeredOrganizations?: FieldPolicy<any> | FieldReadFunction<any>;
  allPermissions?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  emailAddresses?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  identityKey?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  olsonTimeZone?: FieldPolicy<any> | FieldReadFunction<any>;
  primaryEmailAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  timeZoneId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InactiveGroupKeySpecifier = ('groupName' | 'inactivatedDate' | InactiveGroupKeySpecifier)[];
export type InactiveGroupFieldPolicy = {
  groupName?: FieldPolicy<any> | FieldReadFunction<any>;
  inactivatedDate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MassAddIndividualsToProcessQueueResultKeySpecifier = (
  | 'message'
  | 'status'
  | 'success'
  | MassAddIndividualsToProcessQueueResultKeySpecifier
)[];
export type MassAddIndividualsToProcessQueueResultFieldPolicy = {
  message?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MaxLengthValidationErrorKeySpecifier = ('fieldPath' | MaxLengthValidationErrorKeySpecifier)[];
export type MaxLengthValidationErrorFieldPolicy = {
  fieldPath?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MembershipTypeKeySpecifier = ('id' | 'name' | MembershipTypeKeySpecifier)[];
export type MembershipTypeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MembershipTypeIdsOutputKeySpecifier = ('isEmpty' | 'values' | MembershipTypeIdsOutputKeySpecifier)[];
export type MembershipTypeIdsOutputFieldPolicy = {
  isEmpty?: FieldPolicy<any> | FieldReadFunction<any>;
  values?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MerchantKeySpecifier = ('name' | MerchantKeySpecifier)[];
export type MerchantFieldPolicy = {
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MinLengthValidationErrorKeySpecifier = ('fieldPath' | MinLengthValidationErrorKeySpecifier)[];
export type MinLengthValidationErrorFieldPolicy = {
  fieldPath?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MoneyKeySpecifier = ('amount' | 'currency' | MoneyKeySpecifier)[];
export type MoneyFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MoneyAggregateKeySpecifier = ('count' | 'totalValue' | MoneyAggregateKeySpecifier)[];
export type MoneyAggregateFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  totalValue?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MutationKeySpecifier = (
  | 'cancelExportJobMutation'
  | 'deleteExportJobMutation'
  | 'massAddIndividualsToProcessQueue'
  | 'queueExportJob'
  | MutationKeySpecifier
)[];
export type MutationFieldPolicy = {
  cancelExportJobMutation?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteExportJobMutation?: FieldPolicy<any> | FieldReadFunction<any>;
  massAddIndividualsToProcessQueue?: FieldPolicy<any> | FieldReadFunction<any>;
  queueExportJob?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganizationKeySpecifier = (
  | 'communityMembersCursor'
  | 'details'
  | 'featureFlags'
  | 'fund'
  | 'households'
  | 'key'
  | 'membershipTypes'
  | 'organizationCursoredTopCommunityMembers'
  | 'organizationCursoredTopHouseholds'
  | 'organizationGivingSummariesByPeriod'
  | 'organizationGivingSummariesByPeriodNew'
  | 'processes'
  | 'queues'
  | 'topCommunityMemberDonorsCursor'
  | 'topHouseholdDonors'
  | OrganizationKeySpecifier
)[];
export type OrganizationFieldPolicy = {
  communityMembersCursor?: FieldPolicy<any> | FieldReadFunction<any>;
  details?: FieldPolicy<any> | FieldReadFunction<any>;
  featureFlags?: FieldPolicy<any> | FieldReadFunction<any>;
  fund?: FieldPolicy<any> | FieldReadFunction<any>;
  households?: FieldPolicy<any> | FieldReadFunction<any>;
  key?: FieldPolicy<any> | FieldReadFunction<any>;
  membershipTypes?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationCursoredTopCommunityMembers?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationCursoredTopHouseholds?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationGivingSummariesByPeriod?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationGivingSummariesByPeriodNew?: FieldPolicy<any> | FieldReadFunction<any>;
  processes?: FieldPolicy<any> | FieldReadFunction<any>;
  queues?: FieldPolicy<any> | FieldReadFunction<any>;
  topCommunityMemberDonorsCursor?: FieldPolicy<any> | FieldReadFunction<any>;
  topHouseholdDonors?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganizationAttendanceOverviewKeySpecifier = (
  | 'comparisonHighlight'
  | 'data'
  | 'highlights'
  | OrganizationAttendanceOverviewKeySpecifier
)[];
export type OrganizationAttendanceOverviewFieldPolicy = {
  comparisonHighlight?: FieldPolicy<any> | FieldReadFunction<any>;
  data?: FieldPolicy<any> | FieldReadFunction<any>;
  highlights?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganizationDetailsKeySpecifier = (
  | 'campuses'
  | 'catholicEntity'
  | 'catholicSoftwareStatus'
  | 'ecgId'
  | 'entitlements'
  | 'isGivingPlatformActive'
  | 'isMasAppPresent'
  | 'isSalesforceCustomAppPresent'
  | 'name'
  | 'productInformation'
  | OrganizationDetailsKeySpecifier
)[];
export type OrganizationDetailsFieldPolicy = {
  campuses?: FieldPolicy<any> | FieldReadFunction<any>;
  catholicEntity?: FieldPolicy<any> | FieldReadFunction<any>;
  catholicSoftwareStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  ecgId?: FieldPolicy<any> | FieldReadFunction<any>;
  entitlements?: FieldPolicy<any> | FieldReadFunction<any>;
  isGivingPlatformActive?: FieldPolicy<any> | FieldReadFunction<any>;
  isMasAppPresent?: FieldPolicy<any> | FieldReadFunction<any>;
  isSalesforceCustomAppPresent?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  productInformation?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganizationGivingPeriodSummariesNewKeySpecifier = (
  | 'communityMembersCount'
  | 'householdsCount'
  | 'periods'
  | OrganizationGivingPeriodSummariesNewKeySpecifier
)[];
export type OrganizationGivingPeriodSummariesNewFieldPolicy = {
  communityMembersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  householdsCount?: FieldPolicy<any> | FieldReadFunction<any>;
  periods?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganizationGivingPeriodSummariesResultKeySpecifier = (
  | 'communityMembersCount'
  | 'householdCount'
  | 'periods'
  | OrganizationGivingPeriodSummariesResultKeySpecifier
)[];
export type OrganizationGivingPeriodSummariesResultFieldPolicy = {
  communityMembersCount?: FieldPolicy<any> | FieldReadFunction<any>;
  householdCount?: FieldPolicy<any> | FieldReadFunction<any>;
  periods?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganizationGivingPeriodSummaryKeySpecifier = (
  | 'fromDate'
  | 'nonCashGiftsAggregate'
  | 'paymentsAggregate'
  | 'toDate'
  | OrganizationGivingPeriodSummaryKeySpecifier
)[];
export type OrganizationGivingPeriodSummaryFieldPolicy = {
  fromDate?: FieldPolicy<any> | FieldReadFunction<any>;
  nonCashGiftsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  toDate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganizationGivingPeriodSummaryNewKeySpecifier = (
  | 'fromDate'
  | 'nonCashGiftsAggregate'
  | 'paymentsAggregate'
  | 'toDate'
  | OrganizationGivingPeriodSummaryNewKeySpecifier
)[];
export type OrganizationGivingPeriodSummaryNewFieldPolicy = {
  fromDate?: FieldPolicy<any> | FieldReadFunction<any>;
  nonCashGiftsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentsAggregate?: FieldPolicy<any> | FieldReadFunction<any>;
  toDate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganizationGroupParticipationOverviewKeySpecifier = (
  | 'comparisonHighlight'
  | 'data'
  | 'highlights'
  | OrganizationGroupParticipationOverviewKeySpecifier
)[];
export type OrganizationGroupParticipationOverviewFieldPolicy = {
  comparisonHighlight?: FieldPolicy<any> | FieldReadFunction<any>;
  data?: FieldPolicy<any> | FieldReadFunction<any>;
  highlights?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganizationPermissionsKeySpecifier = (
  | 'campusPermissions'
  | 'organization'
  | 'organizationKey'
  | 'permissions'
  | OrganizationPermissionsKeySpecifier
)[];
export type OrganizationPermissionsFieldPolicy = {
  campusPermissions?: FieldPolicy<any> | FieldReadFunction<any>;
  organization?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationKey?: FieldPolicy<any> | FieldReadFunction<any>;
  permissions?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganizationServingOverviewKeySpecifier = (
  | 'comparisonHighlight'
  | 'data'
  | 'highlights'
  | OrganizationServingOverviewKeySpecifier
)[];
export type OrganizationServingOverviewFieldPolicy = {
  comparisonHighlight?: FieldPolicy<any> | FieldReadFunction<any>;
  data?: FieldPolicy<any> | FieldReadFunction<any>;
  highlights?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganizationServingSnapshotsKeySpecifier = ('snapshots' | OrganizationServingSnapshotsKeySpecifier)[];
export type OrganizationServingSnapshotsFieldPolicy = {
  snapshots?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PagingOutputKeySpecifier = ('page' | 'pageSize' | 'totalCount' | PagingOutputKeySpecifier)[];
export type PagingOutputFieldPolicy = {
  page?: FieldPolicy<any> | FieldReadFunction<any>;
  pageSize?: FieldPolicy<any> | FieldReadFunction<any>;
  totalCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PermissionKeySpecifier = ('name' | 'permissionKey' | PermissionKeySpecifier)[];
export type PermissionFieldPolicy = {
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  permissionKey?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PhoneNumberKeySpecifier = (
  | 'countryCode'
  | 'createdOn'
  | 'nationalNumber'
  | 'number'
  | 'plainNationalNumber'
  | 'primary'
  | 'type'
  | 'verified'
  | 'verifiedOn'
  | PhoneNumberKeySpecifier
)[];
export type PhoneNumberFieldPolicy = {
  countryCode?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  nationalNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  number?: FieldPolicy<any> | FieldReadFunction<any>;
  plainNationalNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  primary?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  verified?: FieldPolicy<any> | FieldReadFunction<any>;
  verifiedOn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProcessKeySpecifier = ('campus' | 'id' | 'name' | ProcessKeySpecifier)[];
export type ProcessFieldPolicy = {
  campus?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProcessQueueKeySpecifier = ('id' | 'name' | ProcessQueueKeySpecifier)[];
export type ProcessQueueFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProcessQueuesPagedOutputKeySpecifier = ('items' | 'paging' | ProcessQueuesPagedOutputKeySpecifier)[];
export type ProcessQueuesPagedOutputFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  paging?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProcessesPagedOutputKeySpecifier = ('items' | 'paging' | ProcessesPagedOutputKeySpecifier)[];
export type ProcessesPagedOutputFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  paging?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProductInfoKeySpecifier = ('chmsInformation' | 'streamingInformation' | ProductInfoKeySpecifier)[];
export type ProductInfoFieldPolicy = {
  chmsInformation?: FieldPolicy<any> | FieldReadFunction<any>;
  streamingInformation?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProfileImagesKeySpecifier = ('fullUrl' | 'thumbnailUrl' | ProfileImagesKeySpecifier)[];
export type ProfileImagesFieldPolicy = {
  fullUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  thumbnailUrl?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type QueryKeySpecifier = (
  | 'attendanceQueryFilters'
  | 'exportJobs'
  | 'groupParticipationFilterValues'
  | 'group_participation_query_ping'
  | 'insightsExportPing'
  | 'isGuideValid'
  | 'me'
  | 'organization'
  | 'organizationAttendanceOverview'
  | 'organizationGroupParticipationOverview'
  | 'organizationServingOverview'
  | 'organizationServingSnapshots'
  | 'ping'
  | 'servingFilterValues'
  | 'serving_query_ping'
  | QueryKeySpecifier
)[];
export type QueryFieldPolicy = {
  attendanceQueryFilters?: FieldPolicy<any> | FieldReadFunction<any>;
  exportJobs?: FieldPolicy<any> | FieldReadFunction<any>;
  groupParticipationFilterValues?: FieldPolicy<any> | FieldReadFunction<any>;
  group_participation_query_ping?: FieldPolicy<any> | FieldReadFunction<any>;
  insightsExportPing?: FieldPolicy<any> | FieldReadFunction<any>;
  isGuideValid?: FieldPolicy<any> | FieldReadFunction<any>;
  me?: FieldPolicy<any> | FieldReadFunction<any>;
  organization?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationAttendanceOverview?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationGroupParticipationOverview?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationServingOverview?: FieldPolicy<any> | FieldReadFunction<any>;
  organizationServingSnapshots?: FieldPolicy<any> | FieldReadFunction<any>;
  ping?: FieldPolicy<any> | FieldReadFunction<any>;
  servingFilterValues?: FieldPolicy<any> | FieldReadFunction<any>;
  serving_query_ping?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RangeValidationErrorKeySpecifier = ('fieldPath' | RangeValidationErrorKeySpecifier)[];
export type RangeValidationErrorFieldPolicy = {
  fieldPath?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RegularExpressionValidationErrorKeySpecifier = (
  | 'fieldPath'
  | RegularExpressionValidationErrorKeySpecifier
)[];
export type RegularExpressionValidationErrorFieldPolicy = {
  fieldPath?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RequiredValidationErrorKeySpecifier = ('fieldPath' | RequiredValidationErrorKeySpecifier)[];
export type RequiredValidationErrorFieldPolicy = {
  fieldPath?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ServingComparisonHighlightKeySpecifier = ('last' | 'previous' | ServingComparisonHighlightKeySpecifier)[];
export type ServingComparisonHighlightFieldPolicy = {
  last?: FieldPolicy<any> | FieldReadFunction<any>;
  previous?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ServingFilterValuesOutputKeySpecifier = (
  | 'campusNames'
  | 'categoryNames'
  | 'membershipTypeIds'
  | 'positionNames'
  | 'teamNames'
  | ServingFilterValuesOutputKeySpecifier
)[];
export type ServingFilterValuesOutputFieldPolicy = {
  campusNames?: FieldPolicy<any> | FieldReadFunction<any>;
  categoryNames?: FieldPolicy<any> | FieldReadFunction<any>;
  membershipTypeIds?: FieldPolicy<any> | FieldReadFunction<any>;
  positionNames?: FieldPolicy<any> | FieldReadFunction<any>;
  teamNames?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ServingHighlightsKeySpecifier = (
  | 'averageServingOpportunities'
  | 'uniqueHouseholds'
  | 'uniqueIndividuals'
  | 'uniquePositions'
  | 'uniqueTeams'
  | ServingHighlightsKeySpecifier
)[];
export type ServingHighlightsFieldPolicy = {
  averageServingOpportunities?: FieldPolicy<any> | FieldReadFunction<any>;
  uniqueHouseholds?: FieldPolicy<any> | FieldReadFunction<any>;
  uniqueIndividuals?: FieldPolicy<any> | FieldReadFunction<any>;
  uniquePositions?: FieldPolicy<any> | FieldReadFunction<any>;
  uniqueTeams?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ServingOpportunitySummaryKeySpecifier = (
  | 'campusNames'
  | 'categoryNames'
  | 'endDate'
  | 'positionNames'
  | 'scheduleStartDateTimes'
  | 'servingSummary'
  | 'startDate'
  | 'teamNames'
  | 'total'
  | ServingOpportunitySummaryKeySpecifier
)[];
export type ServingOpportunitySummaryFieldPolicy = {
  campusNames?: FieldPolicy<any> | FieldReadFunction<any>;
  categoryNames?: FieldPolicy<any> | FieldReadFunction<any>;
  endDate?: FieldPolicy<any> | FieldReadFunction<any>;
  positionNames?: FieldPolicy<any> | FieldReadFunction<any>;
  scheduleStartDateTimes?: FieldPolicy<any> | FieldReadFunction<any>;
  servingSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  startDate?: FieldPolicy<any> | FieldReadFunction<any>;
  teamNames?: FieldPolicy<any> | FieldReadFunction<any>;
  total?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ServingSnapshotsKeySpecifier = (
  | 'availableVolunteers'
  | 'servingOpportunities'
  | 'servingOpportunitiesUnfilled'
  | ServingSnapshotsKeySpecifier
)[];
export type ServingSnapshotsFieldPolicy = {
  availableVolunteers?: FieldPolicy<any> | FieldReadFunction<any>;
  servingOpportunities?: FieldPolicy<any> | FieldReadFunction<any>;
  servingOpportunitiesUnfilled?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ServingSummaryKeySpecifier = (
  | 'totalRolesFilled'
  | 'uniqueHouseholds'
  | 'uniqueIndividuals'
  | 'uniquePositions'
  | 'uniqueTeams'
  | ServingSummaryKeySpecifier
)[];
export type ServingSummaryFieldPolicy = {
  totalRolesFilled?: FieldPolicy<any> | FieldReadFunction<any>;
  uniqueHouseholds?: FieldPolicy<any> | FieldReadFunction<any>;
  uniqueIndividuals?: FieldPolicy<any> | FieldReadFunction<any>;
  uniquePositions?: FieldPolicy<any> | FieldReadFunction<any>;
  uniqueTeams?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StreamingInfoKeySpecifier = ('id' | StreamingInfoKeySpecifier)[];
export type StreamingInfoFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StringLengthValidationErrorKeySpecifier = ('fieldPath' | StringLengthValidationErrorKeySpecifier)[];
export type StringLengthValidationErrorFieldPolicy = {
  fieldPath?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SuccessResultKeySpecifier = ('success' | SuccessResultKeySpecifier)[];
export type SuccessResultFieldPolicy = {
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TimeZoneKeySpecifier = ('olsonName' | 'windowsName' | TimeZoneKeySpecifier)[];
export type TimeZoneFieldPolicy = {
  olsonName?: FieldPolicy<any> | FieldReadFunction<any>;
  windowsName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TopCommunityMemberKeySpecifier = (
  | 'communityMember'
  | 'communityMemberKey'
  | 'giftAmount'
  | 'nonCashCount'
  | 'paymentCount'
  | TopCommunityMemberKeySpecifier
)[];
export type TopCommunityMemberFieldPolicy = {
  communityMember?: FieldPolicy<any> | FieldReadFunction<any>;
  communityMemberKey?: FieldPolicy<any> | FieldReadFunction<any>;
  giftAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  nonCashCount?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TopHouseholdKeySpecifier = (
  | 'giftAmount'
  | 'household'
  | 'householdKey'
  | 'nonCashCount'
  | 'paymentCount'
  | TopHouseholdKeySpecifier
)[];
export type TopHouseholdFieldPolicy = {
  giftAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  household?: FieldPolicy<any> | FieldReadFunction<any>;
  householdKey?: FieldPolicy<any> | FieldReadFunction<any>;
  nonCashCount?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UrlValidationErrorKeySpecifier = ('fieldPath' | UrlValidationErrorKeySpecifier)[];
export type UrlValidationErrorFieldPolicy = {
  fieldPath?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ValidationErrorKeySpecifier = ('fieldPath' | ValidationErrorKeySpecifier)[];
export type ValidationErrorFieldPolicy = {
  fieldPath?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ValidationErrorsKeySpecifier = ('errors' | ValidationErrorsKeySpecifier)[];
export type ValidationErrorsFieldPolicy = {
  errors?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type chms_CampusKeySpecifier = ('id' | 'name' | chms_CampusKeySpecifier)[];
export type chms_CampusFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type group_participation_query_MutationKeySpecifier = (
  | 'groupParticipationDeleteLastProcessCursors'
  | group_participation_query_MutationKeySpecifier
)[];
export type group_participation_query_MutationFieldPolicy = {
  groupParticipationDeleteLastProcessCursors?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StrictTypedTypePolicies = {
  Address?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AddressKeySpecifier | (() => undefined | AddressKeySpecifier);
    fields?: AddressFieldPolicy;
  };
  AdministeredOrganization?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AdministeredOrganizationKeySpecifier | (() => undefined | AdministeredOrganizationKeySpecifier);
    fields?: AdministeredOrganizationFieldPolicy;
  };
  Aggregations?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AggregationsKeySpecifier | (() => undefined | AggregationsKeySpecifier);
    fields?: AggregationsFieldPolicy;
  };
  AllIdentityPermissions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AllIdentityPermissionsKeySpecifier | (() => undefined | AllIdentityPermissionsKeySpecifier);
    fields?: AllIdentityPermissionsFieldPolicy;
  };
  Attendance?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AttendanceKeySpecifier | (() => undefined | AttendanceKeySpecifier);
    fields?: AttendanceFieldPolicy;
  };
  AttendanceComparisonHighlight?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AttendanceComparisonHighlightKeySpecifier
      | (() => undefined | AttendanceComparisonHighlightKeySpecifier);
    fields?: AttendanceComparisonHighlightFieldPolicy;
  };
  AttendanceQueryFiltersOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AttendanceQueryFiltersOutputKeySpecifier
      | (() => undefined | AttendanceQueryFiltersOutputKeySpecifier);
    fields?: AttendanceQueryFiltersOutputFieldPolicy;
  };
  Campus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CampusKeySpecifier | (() => undefined | CampusKeySpecifier);
    fields?: CampusFieldPolicy;
  };
  CampusPermissions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CampusPermissionsKeySpecifier | (() => undefined | CampusPermissionsKeySpecifier);
    fields?: CampusPermissionsFieldPolicy;
  };
  CampusesPagedOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CampusesPagedOutputKeySpecifier | (() => undefined | CampusesPagedOutputKeySpecifier);
    fields?: CampusesPagedOutputFieldPolicy;
  };
  CcbIndividual?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CcbIndividualKeySpecifier | (() => undefined | CcbIndividualKeySpecifier);
    fields?: CcbIndividualFieldPolicy;
  };
  ChMSOrganization?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ChMSOrganizationKeySpecifier | (() => undefined | ChMSOrganizationKeySpecifier);
    fields?: ChMSOrganizationFieldPolicy;
  };
  ChmsInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ChmsInfoKeySpecifier | (() => undefined | ChmsInfoKeySpecifier);
    fields?: ChmsInfoFieldPolicy;
  };
  ClientField?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ClientFieldKeySpecifier | (() => undefined | ClientFieldKeySpecifier);
    fields?: ClientFieldFieldPolicy;
  };
  Community?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CommunityKeySpecifier | (() => undefined | CommunityKeySpecifier);
    fields?: CommunityFieldPolicy;
  };
  CommunityMember?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CommunityMemberKeySpecifier | (() => undefined | CommunityMemberKeySpecifier);
    fields?: CommunityMemberFieldPolicy;
  };
  CommunityMemberCollectionResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CommunityMemberCollectionResultKeySpecifier
      | (() => undefined | CommunityMemberCollectionResultKeySpecifier);
    fields?: CommunityMemberCollectionResultFieldPolicy;
  };
  CommunityMemberEngagement?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CommunityMemberEngagementKeySpecifier
      | (() => undefined | CommunityMemberEngagementKeySpecifier);
    fields?: CommunityMemberEngagementFieldPolicy;
  };
  CommunityMemberResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CommunityMemberResultKeySpecifier | (() => undefined | CommunityMemberResultKeySpecifier);
    fields?: CommunityMemberResultFieldPolicy;
  };
  CursorPagedOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CursorPagedOutputKeySpecifier | (() => undefined | CursorPagedOutputKeySpecifier);
    fields?: CursorPagedOutputFieldPolicy;
  };
  CursorPagedOutputOfTopCommunityMember?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CursorPagedOutputOfTopCommunityMemberKeySpecifier
      | (() => undefined | CursorPagedOutputOfTopCommunityMemberKeySpecifier);
    fields?: CursorPagedOutputOfTopCommunityMemberFieldPolicy;
  };
  CursorPagedOutputOfTopHousehold?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CursorPagedOutputOfTopHouseholdKeySpecifier
      | (() => undefined | CursorPagedOutputOfTopHouseholdKeySpecifier);
    fields?: CursorPagedOutputOfTopHouseholdFieldPolicy;
  };
  CursorPagingOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CursorPagingOutputKeySpecifier | (() => undefined | CursorPagingOutputKeySpecifier);
    fields?: CursorPagingOutputFieldPolicy;
  };
  CursorPagingOutputOfCommunityMember?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CursorPagingOutputOfCommunityMemberKeySpecifier
      | (() => undefined | CursorPagingOutputOfCommunityMemberKeySpecifier);
    fields?: CursorPagingOutputOfCommunityMemberFieldPolicy;
  };
  CursorPagingOutputOfHousehold?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CursorPagingOutputOfHouseholdKeySpecifier
      | (() => undefined | CursorPagingOutputOfHouseholdKeySpecifier);
    fields?: CursorPagingOutputOfHouseholdFieldPolicy;
  };
  DateStamp?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | DateStampKeySpecifier | (() => undefined | DateStampKeySpecifier);
    fields?: DateStampFieldPolicy;
  };
  DateStampOfBirth?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | DateStampOfBirthKeySpecifier | (() => undefined | DateStampOfBirthKeySpecifier);
    fields?: DateStampOfBirthFieldPolicy;
  };
  DateStampOfDeath?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | DateStampOfDeathKeySpecifier | (() => undefined | DateStampOfDeathKeySpecifier);
    fields?: DateStampOfDeathFieldPolicy;
  };
  Email?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | EmailKeySpecifier | (() => undefined | EmailKeySpecifier);
    fields?: EmailFieldPolicy;
  };
  EmailAddressValidationError?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EmailAddressValidationErrorKeySpecifier
      | (() => undefined | EmailAddressValidationErrorKeySpecifier);
    fields?: EmailAddressValidationErrorFieldPolicy;
  };
  EmailContact?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | EmailContactKeySpecifier | (() => undefined | EmailContactKeySpecifier);
    fields?: EmailContactFieldPolicy;
  };
  Entitlement?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | EntitlementKeySpecifier | (() => undefined | EntitlementKeySpecifier);
    fields?: EntitlementFieldPolicy;
  };
  EventAttendance?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | EventAttendanceKeySpecifier | (() => undefined | EventAttendanceKeySpecifier);
    fields?: EventAttendanceFieldPolicy;
  };
  EventAttendanceSummary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | EventAttendanceSummaryKeySpecifier | (() => undefined | EventAttendanceSummaryKeySpecifier);
    fields?: EventAttendanceSummaryFieldPolicy;
  };
  ExportJob?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ExportJobKeySpecifier | (() => undefined | ExportJobKeySpecifier);
    fields?: ExportJobFieldPolicy;
  };
  ExportJobCanceledOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ExportJobCanceledOutputKeySpecifier | (() => undefined | ExportJobCanceledOutputKeySpecifier);
    fields?: ExportJobCanceledOutputFieldPolicy;
  };
  ExportJobDeletedOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ExportJobDeletedOutputKeySpecifier | (() => undefined | ExportJobDeletedOutputKeySpecifier);
    fields?: ExportJobDeletedOutputFieldPolicy;
  };
  ExportJobOptions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ExportJobOptionsKeySpecifier | (() => undefined | ExportJobOptionsKeySpecifier);
    fields?: ExportJobOptionsFieldPolicy;
  };
  ExportJobSort?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ExportJobSortKeySpecifier | (() => undefined | ExportJobSortKeySpecifier);
    fields?: ExportJobSortFieldPolicy;
  };
  ExportQueuedOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ExportQueuedOutputKeySpecifier | (() => undefined | ExportQueuedOutputKeySpecifier);
    fields?: ExportQueuedOutputFieldPolicy;
  };
  ExportTypeOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ExportTypeOutputKeySpecifier | (() => undefined | ExportTypeOutputKeySpecifier);
    fields?: ExportTypeOutputFieldPolicy;
  };
  ExternalId?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ExternalIdKeySpecifier | (() => undefined | ExternalIdKeySpecifier);
    fields?: ExternalIdFieldPolicy;
  };
  FeatureFlag?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | FeatureFlagKeySpecifier | (() => undefined | FeatureFlagKeySpecifier);
    fields?: FeatureFlagFieldPolicy;
  };
  FormerHouseholdMember?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | FormerHouseholdMemberKeySpecifier | (() => undefined | FormerHouseholdMemberKeySpecifier);
    fields?: FormerHouseholdMemberFieldPolicy;
  };
  FormerLastName?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | FormerLastNameKeySpecifier | (() => undefined | FormerLastNameKeySpecifier);
    fields?: FormerLastNameFieldPolicy;
  };
  Fund?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | FundKeySpecifier | (() => undefined | FundKeySpecifier);
    fields?: FundFieldPolicy;
  };
  GivingOrganization?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | GivingOrganizationKeySpecifier | (() => undefined | GivingOrganizationKeySpecifier);
    fields?: GivingOrganizationFieldPolicy;
  };
  GroupParticipationComparisonHighlight?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GroupParticipationComparisonHighlightKeySpecifier
      | (() => undefined | GroupParticipationComparisonHighlightKeySpecifier);
    fields?: GroupParticipationComparisonHighlightFieldPolicy;
  };
  GroupParticipationFilterValuesOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GroupParticipationFilterValuesOutputKeySpecifier
      | (() => undefined | GroupParticipationFilterValuesOutputKeySpecifier);
    fields?: GroupParticipationFilterValuesOutputFieldPolicy;
  };
  GroupParticipationHighlights?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GroupParticipationHighlightsKeySpecifier
      | (() => undefined | GroupParticipationHighlightsKeySpecifier);
    fields?: GroupParticipationHighlightsFieldPolicy;
  };
  GroupParticipationSuccessResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GroupParticipationSuccessResultKeySpecifier
      | (() => undefined | GroupParticipationSuccessResultKeySpecifier);
    fields?: GroupParticipationSuccessResultFieldPolicy;
  };
  GroupParticipationSummary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GroupParticipationSummaryKeySpecifier
      | (() => undefined | GroupParticipationSummaryKeySpecifier);
    fields?: GroupParticipationSummaryFieldPolicy;
  };
  Household?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | HouseholdKeySpecifier | (() => undefined | HouseholdKeySpecifier);
    fields?: HouseholdFieldPolicy;
  };
  HouseholdContact?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | HouseholdContactKeySpecifier | (() => undefined | HouseholdContactKeySpecifier);
    fields?: HouseholdContactFieldPolicy;
  };
  HouseholdMember?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | HouseholdMemberKeySpecifier | (() => undefined | HouseholdMemberKeySpecifier);
    fields?: HouseholdMemberFieldPolicy;
  };
  HouseholdMoveOutStatus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | HouseholdMoveOutStatusKeySpecifier | (() => undefined | HouseholdMoveOutStatusKeySpecifier);
    fields?: HouseholdMoveOutStatusFieldPolicy;
  };
  HouseholdResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | HouseholdResultKeySpecifier | (() => undefined | HouseholdResultKeySpecifier);
    fields?: HouseholdResultFieldPolicy;
  };
  IValidationError?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | IValidationErrorKeySpecifier | (() => undefined | IValidationErrorKeySpecifier);
    fields?: IValidationErrorFieldPolicy;
  };
  Identity?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | IdentityKeySpecifier | (() => undefined | IdentityKeySpecifier);
    fields?: IdentityFieldPolicy;
  };
  InactiveGroup?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | InactiveGroupKeySpecifier | (() => undefined | InactiveGroupKeySpecifier);
    fields?: InactiveGroupFieldPolicy;
  };
  MassAddIndividualsToProcessQueueResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MassAddIndividualsToProcessQueueResultKeySpecifier
      | (() => undefined | MassAddIndividualsToProcessQueueResultKeySpecifier);
    fields?: MassAddIndividualsToProcessQueueResultFieldPolicy;
  };
  MaxLengthValidationError?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MaxLengthValidationErrorKeySpecifier | (() => undefined | MaxLengthValidationErrorKeySpecifier);
    fields?: MaxLengthValidationErrorFieldPolicy;
  };
  MembershipType?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MembershipTypeKeySpecifier | (() => undefined | MembershipTypeKeySpecifier);
    fields?: MembershipTypeFieldPolicy;
  };
  MembershipTypeIdsOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MembershipTypeIdsOutputKeySpecifier | (() => undefined | MembershipTypeIdsOutputKeySpecifier);
    fields?: MembershipTypeIdsOutputFieldPolicy;
  };
  Merchant?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MerchantKeySpecifier | (() => undefined | MerchantKeySpecifier);
    fields?: MerchantFieldPolicy;
  };
  MinLengthValidationError?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MinLengthValidationErrorKeySpecifier | (() => undefined | MinLengthValidationErrorKeySpecifier);
    fields?: MinLengthValidationErrorFieldPolicy;
  };
  Money?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MoneyKeySpecifier | (() => undefined | MoneyKeySpecifier);
    fields?: MoneyFieldPolicy;
  };
  MoneyAggregate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MoneyAggregateKeySpecifier | (() => undefined | MoneyAggregateKeySpecifier);
    fields?: MoneyAggregateFieldPolicy;
  };
  Mutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier);
    fields?: MutationFieldPolicy;
  };
  Organization?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | OrganizationKeySpecifier | (() => undefined | OrganizationKeySpecifier);
    fields?: OrganizationFieldPolicy;
  };
  OrganizationAttendanceOverview?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganizationAttendanceOverviewKeySpecifier
      | (() => undefined | OrganizationAttendanceOverviewKeySpecifier);
    fields?: OrganizationAttendanceOverviewFieldPolicy;
  };
  OrganizationDetails?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | OrganizationDetailsKeySpecifier | (() => undefined | OrganizationDetailsKeySpecifier);
    fields?: OrganizationDetailsFieldPolicy;
  };
  OrganizationGivingPeriodSummariesNew?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganizationGivingPeriodSummariesNewKeySpecifier
      | (() => undefined | OrganizationGivingPeriodSummariesNewKeySpecifier);
    fields?: OrganizationGivingPeriodSummariesNewFieldPolicy;
  };
  OrganizationGivingPeriodSummariesResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganizationGivingPeriodSummariesResultKeySpecifier
      | (() => undefined | OrganizationGivingPeriodSummariesResultKeySpecifier);
    fields?: OrganizationGivingPeriodSummariesResultFieldPolicy;
  };
  OrganizationGivingPeriodSummary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganizationGivingPeriodSummaryKeySpecifier
      | (() => undefined | OrganizationGivingPeriodSummaryKeySpecifier);
    fields?: OrganizationGivingPeriodSummaryFieldPolicy;
  };
  OrganizationGivingPeriodSummaryNew?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganizationGivingPeriodSummaryNewKeySpecifier
      | (() => undefined | OrganizationGivingPeriodSummaryNewKeySpecifier);
    fields?: OrganizationGivingPeriodSummaryNewFieldPolicy;
  };
  OrganizationGroupParticipationOverview?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganizationGroupParticipationOverviewKeySpecifier
      | (() => undefined | OrganizationGroupParticipationOverviewKeySpecifier);
    fields?: OrganizationGroupParticipationOverviewFieldPolicy;
  };
  OrganizationPermissions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | OrganizationPermissionsKeySpecifier | (() => undefined | OrganizationPermissionsKeySpecifier);
    fields?: OrganizationPermissionsFieldPolicy;
  };
  OrganizationServingOverview?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganizationServingOverviewKeySpecifier
      | (() => undefined | OrganizationServingOverviewKeySpecifier);
    fields?: OrganizationServingOverviewFieldPolicy;
  };
  OrganizationServingSnapshots?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrganizationServingSnapshotsKeySpecifier
      | (() => undefined | OrganizationServingSnapshotsKeySpecifier);
    fields?: OrganizationServingSnapshotsFieldPolicy;
  };
  PagingOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PagingOutputKeySpecifier | (() => undefined | PagingOutputKeySpecifier);
    fields?: PagingOutputFieldPolicy;
  };
  Permission?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PermissionKeySpecifier | (() => undefined | PermissionKeySpecifier);
    fields?: PermissionFieldPolicy;
  };
  PhoneNumber?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PhoneNumberKeySpecifier | (() => undefined | PhoneNumberKeySpecifier);
    fields?: PhoneNumberFieldPolicy;
  };
  Process?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ProcessKeySpecifier | (() => undefined | ProcessKeySpecifier);
    fields?: ProcessFieldPolicy;
  };
  ProcessQueue?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ProcessQueueKeySpecifier | (() => undefined | ProcessQueueKeySpecifier);
    fields?: ProcessQueueFieldPolicy;
  };
  ProcessQueuesPagedOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ProcessQueuesPagedOutputKeySpecifier | (() => undefined | ProcessQueuesPagedOutputKeySpecifier);
    fields?: ProcessQueuesPagedOutputFieldPolicy;
  };
  ProcessesPagedOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ProcessesPagedOutputKeySpecifier | (() => undefined | ProcessesPagedOutputKeySpecifier);
    fields?: ProcessesPagedOutputFieldPolicy;
  };
  ProductInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ProductInfoKeySpecifier | (() => undefined | ProductInfoKeySpecifier);
    fields?: ProductInfoFieldPolicy;
  };
  ProfileImages?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ProfileImagesKeySpecifier | (() => undefined | ProfileImagesKeySpecifier);
    fields?: ProfileImagesFieldPolicy;
  };
  Query?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier);
    fields?: QueryFieldPolicy;
  };
  RangeValidationError?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RangeValidationErrorKeySpecifier | (() => undefined | RangeValidationErrorKeySpecifier);
    fields?: RangeValidationErrorFieldPolicy;
  };
  RegularExpressionValidationError?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RegularExpressionValidationErrorKeySpecifier
      | (() => undefined | RegularExpressionValidationErrorKeySpecifier);
    fields?: RegularExpressionValidationErrorFieldPolicy;
  };
  RequiredValidationError?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | RequiredValidationErrorKeySpecifier | (() => undefined | RequiredValidationErrorKeySpecifier);
    fields?: RequiredValidationErrorFieldPolicy;
  };
  ServingComparisonHighlight?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ServingComparisonHighlightKeySpecifier
      | (() => undefined | ServingComparisonHighlightKeySpecifier);
    fields?: ServingComparisonHighlightFieldPolicy;
  };
  ServingFilterValuesOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ServingFilterValuesOutputKeySpecifier
      | (() => undefined | ServingFilterValuesOutputKeySpecifier);
    fields?: ServingFilterValuesOutputFieldPolicy;
  };
  ServingHighlights?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ServingHighlightsKeySpecifier | (() => undefined | ServingHighlightsKeySpecifier);
    fields?: ServingHighlightsFieldPolicy;
  };
  ServingOpportunitySummary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ServingOpportunitySummaryKeySpecifier
      | (() => undefined | ServingOpportunitySummaryKeySpecifier);
    fields?: ServingOpportunitySummaryFieldPolicy;
  };
  ServingSnapshots?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ServingSnapshotsKeySpecifier | (() => undefined | ServingSnapshotsKeySpecifier);
    fields?: ServingSnapshotsFieldPolicy;
  };
  ServingSummary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ServingSummaryKeySpecifier | (() => undefined | ServingSummaryKeySpecifier);
    fields?: ServingSummaryFieldPolicy;
  };
  StreamingInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | StreamingInfoKeySpecifier | (() => undefined | StreamingInfoKeySpecifier);
    fields?: StreamingInfoFieldPolicy;
  };
  StringLengthValidationError?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StringLengthValidationErrorKeySpecifier
      | (() => undefined | StringLengthValidationErrorKeySpecifier);
    fields?: StringLengthValidationErrorFieldPolicy;
  };
  SuccessResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SuccessResultKeySpecifier | (() => undefined | SuccessResultKeySpecifier);
    fields?: SuccessResultFieldPolicy;
  };
  TimeZone?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TimeZoneKeySpecifier | (() => undefined | TimeZoneKeySpecifier);
    fields?: TimeZoneFieldPolicy;
  };
  TopCommunityMember?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TopCommunityMemberKeySpecifier | (() => undefined | TopCommunityMemberKeySpecifier);
    fields?: TopCommunityMemberFieldPolicy;
  };
  TopHousehold?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TopHouseholdKeySpecifier | (() => undefined | TopHouseholdKeySpecifier);
    fields?: TopHouseholdFieldPolicy;
  };
  UrlValidationError?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UrlValidationErrorKeySpecifier | (() => undefined | UrlValidationErrorKeySpecifier);
    fields?: UrlValidationErrorFieldPolicy;
  };
  ValidationError?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ValidationErrorKeySpecifier | (() => undefined | ValidationErrorKeySpecifier);
    fields?: ValidationErrorFieldPolicy;
  };
  ValidationErrors?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ValidationErrorsKeySpecifier | (() => undefined | ValidationErrorsKeySpecifier);
    fields?: ValidationErrorsFieldPolicy;
  };
  chms_Campus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | chms_CampusKeySpecifier | (() => undefined | chms_CampusKeySpecifier);
    fields?: chms_CampusFieldPolicy;
  };
  group_participation_query_Mutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | group_participation_query_MutationKeySpecifier
      | (() => undefined | group_participation_query_MutationKeySpecifier);
    fields?: group_participation_query_MutationFieldPolicy;
  };
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;
export const OrganizationAttendanceOverviewHighlightsFragmentDoc = gql`
  fragment OrganizationAttendanceOverviewHighlights on Attendance {
    averageAttendance
    checkinAttendance
    familyAttendance
    firstTimeGuests
    headCountAttendance
    individualAttendance
    servingAttendance
    streamingAttendance
    totalAttendance
    uniqueFamilyAttendance
    uniqueIndividualAttendance
  }
`;
export const OrganizationAttendanceOverviewDataFragmentDoc = gql`
  fragment OrganizationAttendanceOverviewData on EventAttendanceSummary {
    attendanceGroupingNames
    attendanceSummary {
      ...OrganizationAttendanceOverviewHighlights
    }
    campusNames
    startDate
    endDate
    total
    eventNames
    groupNames
    departmentNames
    groupTypeNames
    membershipTypeIds
  }
  ${OrganizationAttendanceOverviewHighlightsFragmentDoc}
`;
export const OrganizationGroupParticipationOverviewDataFragmentDoc = gql`
  fragment OrganizationGroupParticipationOverviewData on GroupParticipationSummary {
    startDate
    endDate
    total
    campusNames
    departmentNames
    groupNames
    groupTypeNames
    membershipTypeIds
  }
`;
export const ExportJobFragmentDoc = gql`
  fragment ExportJob on ExportJob {
    createdAt
    exportJobOptions {
      searchTerm
      sort {
        columnName
        direction
      }
      viewBy
      type {
        attenderListType
        donorListType
        volunteerListType
      }
    }
    exportLink
    exportSizeBytes
    jobKey
    status
  }
`;
export const OrganizationGivingPeriodSummaryFragmentDoc = gql`
  fragment OrganizationGivingPeriodSummary on OrganizationGivingPeriodSummariesResult {
    communityMembersCount
    householdCount
    periods {
      fromDate
      toDate
      paymentsAggregate {
        count
        totalValue {
          amount
          currency
        }
      }
      nonCashGiftsAggregate {
        count
        totalValue {
          amount
          currency
        }
      }
    }
  }
`;
export const OrganizationGivingPeriodSummaryNewFragmentDoc = gql`
  fragment OrganizationGivingPeriodSummaryNew on OrganizationGivingPeriodSummariesNew {
    communityMembersCount
    householdsCount
    periods {
      fromDate
      toDate
      paymentsAggregate {
        count
        totalValue {
          amount
          currency
        }
      }
      nonCashGiftsAggregate {
        count
        totalValue {
          amount
          currency
        }
      }
    }
  }
`;
export const OrganizationServingOverviewDataFragmentDoc = gql`
  fragment OrganizationServingOverviewData on ServingOpportunitySummary {
    startDate
    endDate
    total
    servingSummary {
      uniqueIndividuals
      uniqueHouseholds
      uniqueTeams
      uniquePositions
      totalRolesFilled
    }
    campusNames
    categoryNames
    teamNames
    positionNames
    scheduleStartDateTimes
  }
`;
export const FeatureFlagsFragmentDoc = gql`
  fragment FeatureFlags on Organization {
    featureFlags {
      name
      enabled
    }
  }
`;
export const MeFragmentDoc = gql`
  fragment Me on Identity {
    accountType
    identityKey
    firstName
    lastName
    createdOn
    olsonTimeZone
    timeZoneId
    primaryEmailAddress {
      emailAddress
    }
    allPermissions {
      organizationPermissions {
        organizationKey
        permissions {
          name
        }
        campusPermissions {
          campusKey
          permissions {
            name
          }
        }
      }
    }
    administeredOrganizations {
      name
      key
      logoUrl
    }
  }
`;
export const MassAddToProcessQueueMutationDocument = gql`
  mutation MassAddToProcessQueueMutation($organizationKey: ID!, $processQueueId: ID!, $individualIds: [ID!]!) {
    massAddIndividualsToProcessQueue(
      organizationKey: $organizationKey
      processQueueId: $processQueueId
      individualIds: $individualIds
    ) {
      success
      status
      message
    }
  }
`;

/**
 * __useMassAddToProcessQueueMutationMutation__
 *
 * To run a mutation, you first call `useMassAddToProcessQueueMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMassAddToProcessQueueMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [massAddToProcessQueueMutationMutation, { data, loading, error }] = useMassAddToProcessQueueMutationMutation({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      processQueueId: // value for 'processQueueId'
 *      individualIds: // value for 'individualIds'
 *   },
 * });
 */
export function useMassAddToProcessQueueMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MassAddToProcessQueueMutationMutation,
    MassAddToProcessQueueMutationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MassAddToProcessQueueMutationMutation, MassAddToProcessQueueMutationMutationVariables>(
    MassAddToProcessQueueMutationDocument,
    options
  );
}
export type MassAddToProcessQueueMutationMutationHookResult = ReturnType<
  typeof useMassAddToProcessQueueMutationMutation
>;
export type MassAddToProcessQueueMutationMutationResult = Apollo.MutationResult<MassAddToProcessQueueMutationMutation>;
export type MassAddToProcessQueueMutationMutationOptions = Apollo.BaseMutationOptions<
  MassAddToProcessQueueMutationMutation,
  MassAddToProcessQueueMutationMutationVariables
>;
export const CancelExportJobDocument = gql`
  mutation CancelExportJob($organizationKey: String!, $cancelExportJobInput: CancelExportJobInput!) {
    cancelExportJobMutation(organizationKey: $organizationKey, cancelExportJobInput: $cancelExportJobInput) {
      export {
        ...ExportJob
      }
    }
  }
  ${ExportJobFragmentDoc}
`;

/**
 * __useCancelExportJobMutation__
 *
 * To run a mutation, you first call `useCancelExportJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelExportJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelExportJobMutation, { data, loading, error }] = useCancelExportJobMutation({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      cancelExportJobInput: // value for 'cancelExportJobInput'
 *   },
 * });
 */
export function useCancelExportJobMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelExportJobMutation, CancelExportJobMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelExportJobMutation, CancelExportJobMutationVariables>(
    CancelExportJobDocument,
    options
  );
}
export type CancelExportJobMutationHookResult = ReturnType<typeof useCancelExportJobMutation>;
export type CancelExportJobMutationResult = Apollo.MutationResult<CancelExportJobMutation>;
export type CancelExportJobMutationOptions = Apollo.BaseMutationOptions<
  CancelExportJobMutation,
  CancelExportJobMutationVariables
>;
export const DeleteExportJobDocument = gql`
  mutation DeleteExportJob($organizationKey: String!, $deleteExportJobInput: DeleteExportJobInput!) {
    deleteExportJobMutation(organizationKey: $organizationKey, deleteExportJobInput: $deleteExportJobInput) {
      export {
        ...ExportJob
      }
    }
  }
  ${ExportJobFragmentDoc}
`;

/**
 * __useDeleteExportJobMutation__
 *
 * To run a mutation, you first call `useDeleteExportJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExportJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExportJobMutation, { data, loading, error }] = useDeleteExportJobMutation({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      deleteExportJobInput: // value for 'deleteExportJobInput'
 *   },
 * });
 */
export function useDeleteExportJobMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteExportJobMutation, DeleteExportJobMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteExportJobMutation, DeleteExportJobMutationVariables>(
    DeleteExportJobDocument,
    options
  );
}
export type DeleteExportJobMutationHookResult = ReturnType<typeof useDeleteExportJobMutation>;
export type DeleteExportJobMutationResult = Apollo.MutationResult<DeleteExportJobMutation>;
export type DeleteExportJobMutationOptions = Apollo.BaseMutationOptions<
  DeleteExportJobMutation,
  DeleteExportJobMutationVariables
>;
export const QueueExportJobDocument = gql`
  mutation QueueExportJob($organizationKey: String!, $createExportInput: CreateExportJobInput!) {
    queueExportJob(organizationKey: $organizationKey, createExportInput: $createExportInput) {
      export {
        ...ExportJob
      }
    }
  }
  ${ExportJobFragmentDoc}
`;

/**
 * __useQueueExportJobMutation__
 *
 * To run a mutation, you first call `useQueueExportJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQueueExportJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [queueExportJobMutation, { data, loading, error }] = useQueueExportJobMutation({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      createExportInput: // value for 'createExportInput'
 *   },
 * });
 */
export function useQueueExportJobMutation(
  baseOptions?: Apollo.MutationHookOptions<QueueExportJobMutation, QueueExportJobMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<QueueExportJobMutation, QueueExportJobMutationVariables>(QueueExportJobDocument, options);
}
export type QueueExportJobMutationHookResult = ReturnType<typeof useQueueExportJobMutation>;
export type QueueExportJobMutationResult = Apollo.MutationResult<QueueExportJobMutation>;
export type QueueExportJobMutationOptions = Apollo.BaseMutationOptions<
  QueueExportJobMutation,
  QueueExportJobMutationVariables
>;
export const DashboardSnapshotsDocument = gql`
  query DashboardSnapshots(
    $organizationKey: String!
    $isDisablePQSEnabled: Boolean!
    $paging: CursorPagingInput!
    $attendanceChurnLapsedLastParams: QueryParams!
    $attendanceChurnLapsedPrevParams: QueryParams!
    $attendanceChurnRegularLastParams: QueryParams!
    $attendanceChurnRegularPrevParams: QueryParams!
    $attendanceOverviewInput: AttendanceOverviewInput!
    $donorCurrentTimeRange: GranularTimeRangeInput!
    $donorOrganizationQueryFilter: OrganizationQueryFilterInput!
    $donorRecentNewLastParams: QueryParams!
    $donorRecentNewPrevParams: QueryParams!
    $donorLastQueryParams: QueryParams!
    $donorPreviousQueryParams: QueryParams!
    $lastQueryParams: QueryParams!
    $previousQueryParams: QueryParams!
    $lastServingSnapshotsInput: ServingSnapshotsInput!
    $previousServingSnapshotsInput: ServingSnapshotsInput!
  ) @feature(name: "engagement-analytics") {
    AllInsightEligibleIndividuals: organization(organizationKey: $organizationKey) {
      LastData: communityMembersCursor(paging: $paging, queryParams: $lastQueryParams) {
        aggregations {
          All: count(field: "archived", value: "false")
        }
      }
      PreviousData: communityMembersCursor(paging: $paging, queryParams: $previousQueryParams) {
        aggregations {
          All: count(field: "archived", value: "false")
        }
      }
    }
    AttendanceOverview: organizationAttendanceOverview(organizationKey: $organizationKey) {
      highlights(attendanceOverviewInput: $attendanceOverviewInput) {
        totalAttendance
      }
    }
    Attenders: organization(organizationKey: $organizationKey) {
      AttendanceChurnLapsedLastData: communityMembersCursor(
        paging: $paging
        queryParams: $attendanceChurnLapsedLastParams
      ) {
        aggregations {
          count(field: "pushpay:attendance_status", value: "lapsed", applyWithin: true)
        }
      }
      AttendanceChurnLapsedPrevData: communityMembersCursor(
        paging: $paging
        queryParams: $attendanceChurnLapsedPrevParams
      ) {
        aggregations {
          count(field: "pushpay:attendance_status", value: "lapsed", applyWithin: true)
        }
      }
      AttendanceChurnRegularLastData: communityMembersCursor(
        paging: $paging
        queryParams: $attendanceChurnRegularLastParams
      ) {
        aggregations {
          count(field: "pushpay:attendance_status", value: "regular")
        }
      }
      AttendanceChurnRegularPrevData: communityMembersCursor(
        paging: $paging
        queryParams: $attendanceChurnRegularPrevParams
      ) {
        aggregations {
          count(field: "pushpay:attendance_status", value: "regular")
        }
      }
      LastData: communityMembersCursor(paging: $paging, queryParams: $lastQueryParams) {
        aggregations {
          AtRisk: count(field: "pushpay:attendance_status_transition", value: "at_risk")
          Curious: count(field: "pushpay:attendance_status_transition", value: "curious")
          FirstTime: count(field: "pushpay:attendance_status", value: "first_time")
          RecentNew: count(field: "pushpay:attendance_status_transition", value: "recent_new")
          Regular: count(field: "pushpay:attendance_status", value: "regular")
          Returning: count(field: "pushpay:attendance_status_transition", value: "returning")
          SecondTime: count(field: "pushpay:attendance_status", value: "second_time")
          PotentialInactive: count(field: "potentialInactive", value: "true")
        }
      }
      PreviousData: communityMembersCursor(paging: $paging, queryParams: $previousQueryParams) {
        aggregations {
          AtRisk: count(field: "pushpay:attendance_status_transition", value: "at_risk")
          Curious: count(field: "pushpay:attendance_status_transition", value: "curious")
          RecentNew: count(field: "pushpay:attendance_status_transition", value: "recent_new")
          Returning: count(field: "pushpay:attendance_status_transition", value: "returning")
        }
      }
    }
    Donors: organization(organizationKey: $organizationKey) {
      RecentNewLast: communityMembersCursor(paging: $paging, queryParams: $donorRecentNewLastParams) {
        paging {
          totalItemCount
        }
      }
      RecentNewPrevious: communityMembersCursor(paging: $paging, queryParams: $donorRecentNewPrevParams) {
        paging {
          totalItemCount
        }
      }
      CurrentGivingSummaries: organizationGivingSummariesByPeriodNew(
        timeRange: $donorCurrentTimeRange
        organizationQueryFilter: $donorOrganizationQueryFilter
      ) @skip(if: $isDisablePQSEnabled) {
        periods {
          fromDate
          toDate
          paymentsAggregate {
            count
            totalValue {
              amount
              currency
            }
          }
        }
      }
      LastData: communityMembersCursor(paging: $paging, queryParams: $donorLastQueryParams) {
        aggregations {
          AtRisk: count(field: "pushpay:at_risk", value: "true")
          FirstTime: count(field: "pushpay:giving_status", value: "new")
          Potential: count(field: "potentialDonor", value: "true")
          Recurring: count(field: "pushpay:giving_status", value: "recurring")
          Regular: count(field: "pushpay:giving_status", value: "regular")
          SecondTime: count(field: "pushpay:giving_status", value: "second-time")
          Digital: count(field: "pushpay:main_giving_type", value: "digital")
          Offline: count(field: "pushpay:main_giving_type", value: "offline")
        }
      }
      PreviousData: communityMembersCursor(paging: $paging, queryParams: $donorPreviousQueryParams) {
        aggregations {
          AtRisk: count(field: "pushpay:at_risk", value: "true")
        }
      }
    }
    Volunteers: organization(organizationKey: $organizationKey) {
      LastData: communityMembersCursor(paging: $paging, queryParams: $lastQueryParams) {
        aggregations {
          AtRisk: count(field: "pushpay:serving_status_transition", value: "at_risk")
          Curious: count(field: "pushpay:serving_status_transition", value: "curious")
          FirstTime: count(field: "pushpay:serving_status", value: "first_time")
          Potential: count(field: "potentialVolunteer", value: "true")
          RecentNew: count(field: "pushpay:serving_status_transition", value: "recent_new")
          Regular: count(field: "pushpay:serving_status", value: "regular")
          Returning: count(field: "pushpay:serving_status_transition", value: "returning")
          SecondTime: count(field: "pushpay:serving_status", value: "second_time")
        }
      }
      PreviousData: communityMembersCursor(paging: $paging, queryParams: $previousQueryParams) {
        aggregations {
          AtRisk: count(field: "pushpay:serving_status_transition", value: "at_risk")
          RecentNew: count(field: "pushpay:serving_status_transition", value: "recent_new")
        }
      }
    }
    ServingSnapshots: organizationServingSnapshots(organizationKey: $organizationKey) {
      LastServingSnapshots: snapshots(servingSnapshotsInput: $lastServingSnapshotsInput) {
        availableVolunteers
        servingOpportunities
        servingOpportunitiesUnfilled
      }
      PreviousServingSnapshots: snapshots(servingSnapshotsInput: $previousServingSnapshotsInput) {
        availableVolunteers
        servingOpportunities
        servingOpportunitiesUnfilled
      }
    }
  }
`;

/**
 * __useDashboardSnapshotsQuery__
 *
 * To run a query within a React component, call `useDashboardSnapshotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardSnapshotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardSnapshotsQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      isDisablePQSEnabled: // value for 'isDisablePQSEnabled'
 *      paging: // value for 'paging'
 *      attendanceChurnLapsedLastParams: // value for 'attendanceChurnLapsedLastParams'
 *      attendanceChurnLapsedPrevParams: // value for 'attendanceChurnLapsedPrevParams'
 *      attendanceChurnRegularLastParams: // value for 'attendanceChurnRegularLastParams'
 *      attendanceChurnRegularPrevParams: // value for 'attendanceChurnRegularPrevParams'
 *      attendanceOverviewInput: // value for 'attendanceOverviewInput'
 *      donorCurrentTimeRange: // value for 'donorCurrentTimeRange'
 *      donorOrganizationQueryFilter: // value for 'donorOrganizationQueryFilter'
 *      donorRecentNewLastParams: // value for 'donorRecentNewLastParams'
 *      donorRecentNewPrevParams: // value for 'donorRecentNewPrevParams'
 *      donorLastQueryParams: // value for 'donorLastQueryParams'
 *      donorPreviousQueryParams: // value for 'donorPreviousQueryParams'
 *      lastQueryParams: // value for 'lastQueryParams'
 *      previousQueryParams: // value for 'previousQueryParams'
 *      lastServingSnapshotsInput: // value for 'lastServingSnapshotsInput'
 *      previousServingSnapshotsInput: // value for 'previousServingSnapshotsInput'
 *   },
 * });
 */
export function useDashboardSnapshotsQuery(
  baseOptions: Apollo.QueryHookOptions<DashboardSnapshotsQuery, DashboardSnapshotsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DashboardSnapshotsQuery, DashboardSnapshotsQueryVariables>(
    DashboardSnapshotsDocument,
    options
  );
}
export function useDashboardSnapshotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DashboardSnapshotsQuery, DashboardSnapshotsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DashboardSnapshotsQuery, DashboardSnapshotsQueryVariables>(
    DashboardSnapshotsDocument,
    options
  );
}
export type DashboardSnapshotsQueryHookResult = ReturnType<typeof useDashboardSnapshotsQuery>;
export type DashboardSnapshotsLazyQueryHookResult = ReturnType<typeof useDashboardSnapshotsLazyQuery>;
export type DashboardSnapshotsQueryResult = Apollo.QueryResult<
  DashboardSnapshotsQuery,
  DashboardSnapshotsQueryVariables
>;
export const AttendanceQueryFiltersDocument = gql`
  query AttendanceQueryFilters($organizationKey: String!) {
    attendanceQueryFilters(organizationKey: $organizationKey) {
      attendanceGroupingNames
      campusNames
      departmentNames
      eventNames
      groupNames
      groupTypeNames
      membershipTypeIds
      streamingEventNames
    }
  }
`;

/**
 * __useAttendanceQueryFiltersQuery__
 *
 * To run a query within a React component, call `useAttendanceQueryFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendanceQueryFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendanceQueryFiltersQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *   },
 * });
 */
export function useAttendanceQueryFiltersQuery(
  baseOptions: Apollo.QueryHookOptions<AttendanceQueryFiltersQuery, AttendanceQueryFiltersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AttendanceQueryFiltersQuery, AttendanceQueryFiltersQueryVariables>(
    AttendanceQueryFiltersDocument,
    options
  );
}
export function useAttendanceQueryFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AttendanceQueryFiltersQuery, AttendanceQueryFiltersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AttendanceQueryFiltersQuery, AttendanceQueryFiltersQueryVariables>(
    AttendanceQueryFiltersDocument,
    options
  );
}
export type AttendanceQueryFiltersQueryHookResult = ReturnType<typeof useAttendanceQueryFiltersQuery>;
export type AttendanceQueryFiltersLazyQueryHookResult = ReturnType<typeof useAttendanceQueryFiltersLazyQuery>;
export type AttendanceQueryFiltersQueryResult = Apollo.QueryResult<
  AttendanceQueryFiltersQuery,
  AttendanceQueryFiltersQueryVariables
>;
export const OrganizationAttendanceOverviewWithPreviousDocument = gql`
  query OrganizationAttendanceOverviewWithPrevious(
    $organizationKey: String!
    $attendanceOverviewInput: AttendanceOverviewInput!
    $comparisonDatesInput: ComparisonDatesInput!
    $previousAttendanceOverviewInput: AttendanceOverviewInput!
  ) {
    organizationAttendanceOverview(organizationKey: $organizationKey) {
      highlights(attendanceOverviewInput: $attendanceOverviewInput) {
        ...OrganizationAttendanceOverviewHighlights
      }
      data(attendanceOverviewInput: $attendanceOverviewInput) {
        ...OrganizationAttendanceOverviewData
      }
      previousData: data(attendanceOverviewInput: $previousAttendanceOverviewInput) {
        ...OrganizationAttendanceOverviewData
      }
      comparisonHighlight(
        attendanceOverviewInput: $attendanceOverviewInput
        comparisonDatesInput: $comparisonDatesInput
      ) {
        last
        previous
      }
    }
  }
  ${OrganizationAttendanceOverviewHighlightsFragmentDoc}
  ${OrganizationAttendanceOverviewDataFragmentDoc}
`;

/**
 * __useOrganizationAttendanceOverviewWithPreviousQuery__
 *
 * To run a query within a React component, call `useOrganizationAttendanceOverviewWithPreviousQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationAttendanceOverviewWithPreviousQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationAttendanceOverviewWithPreviousQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      attendanceOverviewInput: // value for 'attendanceOverviewInput'
 *      comparisonDatesInput: // value for 'comparisonDatesInput'
 *      previousAttendanceOverviewInput: // value for 'previousAttendanceOverviewInput'
 *   },
 * });
 */
export function useOrganizationAttendanceOverviewWithPreviousQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizationAttendanceOverviewWithPreviousQuery,
    OrganizationAttendanceOverviewWithPreviousQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganizationAttendanceOverviewWithPreviousQuery,
    OrganizationAttendanceOverviewWithPreviousQueryVariables
  >(OrganizationAttendanceOverviewWithPreviousDocument, options);
}
export function useOrganizationAttendanceOverviewWithPreviousLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationAttendanceOverviewWithPreviousQuery,
    OrganizationAttendanceOverviewWithPreviousQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationAttendanceOverviewWithPreviousQuery,
    OrganizationAttendanceOverviewWithPreviousQueryVariables
  >(OrganizationAttendanceOverviewWithPreviousDocument, options);
}
export type OrganizationAttendanceOverviewWithPreviousQueryHookResult = ReturnType<
  typeof useOrganizationAttendanceOverviewWithPreviousQuery
>;
export type OrganizationAttendanceOverviewWithPreviousLazyQueryHookResult = ReturnType<
  typeof useOrganizationAttendanceOverviewWithPreviousLazyQuery
>;
export type OrganizationAttendanceOverviewWithPreviousQueryResult = Apollo.QueryResult<
  OrganizationAttendanceOverviewWithPreviousQuery,
  OrganizationAttendanceOverviewWithPreviousQueryVariables
>;
export const OrganizationAttendanceOverviewWithoutPreviousDocument = gql`
  query OrganizationAttendanceOverviewWithoutPrevious(
    $organizationKey: String!
    $attendanceOverviewInput: AttendanceOverviewInput!
    $comparisonDatesInput: ComparisonDatesInput!
  ) {
    organizationAttendanceOverview(organizationKey: $organizationKey) {
      highlights(attendanceOverviewInput: $attendanceOverviewInput) {
        ...OrganizationAttendanceOverviewHighlights
      }
      data(attendanceOverviewInput: $attendanceOverviewInput) {
        ...OrganizationAttendanceOverviewData
      }
      comparisonHighlight(
        attendanceOverviewInput: $attendanceOverviewInput
        comparisonDatesInput: $comparisonDatesInput
      ) {
        last
      }
    }
  }
  ${OrganizationAttendanceOverviewHighlightsFragmentDoc}
  ${OrganizationAttendanceOverviewDataFragmentDoc}
`;

/**
 * __useOrganizationAttendanceOverviewWithoutPreviousQuery__
 *
 * To run a query within a React component, call `useOrganizationAttendanceOverviewWithoutPreviousQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationAttendanceOverviewWithoutPreviousQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationAttendanceOverviewWithoutPreviousQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      attendanceOverviewInput: // value for 'attendanceOverviewInput'
 *      comparisonDatesInput: // value for 'comparisonDatesInput'
 *   },
 * });
 */
export function useOrganizationAttendanceOverviewWithoutPreviousQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizationAttendanceOverviewWithoutPreviousQuery,
    OrganizationAttendanceOverviewWithoutPreviousQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganizationAttendanceOverviewWithoutPreviousQuery,
    OrganizationAttendanceOverviewWithoutPreviousQueryVariables
  >(OrganizationAttendanceOverviewWithoutPreviousDocument, options);
}
export function useOrganizationAttendanceOverviewWithoutPreviousLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationAttendanceOverviewWithoutPreviousQuery,
    OrganizationAttendanceOverviewWithoutPreviousQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationAttendanceOverviewWithoutPreviousQuery,
    OrganizationAttendanceOverviewWithoutPreviousQueryVariables
  >(OrganizationAttendanceOverviewWithoutPreviousDocument, options);
}
export type OrganizationAttendanceOverviewWithoutPreviousQueryHookResult = ReturnType<
  typeof useOrganizationAttendanceOverviewWithoutPreviousQuery
>;
export type OrganizationAttendanceOverviewWithoutPreviousLazyQueryHookResult = ReturnType<
  typeof useOrganizationAttendanceOverviewWithoutPreviousLazyQuery
>;
export type OrganizationAttendanceOverviewWithoutPreviousQueryResult = Apollo.QueryResult<
  OrganizationAttendanceOverviewWithoutPreviousQuery,
  OrganizationAttendanceOverviewWithoutPreviousQueryVariables
>;
export const AttendanceSnapshotsDocument = gql`
  query AttendanceSnapshots($organizationKey: String!, $paging: CursorPagingInput!, $queryParams: QueryParams) {
    organization(organizationKey: $organizationKey) {
      communityMembersCursor(paging: $paging, queryParams: $queryParams) {
        aggregations {
          recentNewAttenders: count(field: "pushpay:attendance_status_transition", value: "recent_new")
          atRiskAttenders: count(field: "pushpay:attendance_status_transition", value: "at_risk")
          curiousAttenders: count(field: "pushpay:attendance_status_transition", value: "curious")
          returningAttenders: count(field: "pushpay:attendance_status_transition", value: "returning")
          potentialInactive: count(field: "potentialInactive", value: "true")
        }
      }
    }
  }
`;

/**
 * __useAttendanceSnapshotsQuery__
 *
 * To run a query within a React component, call `useAttendanceSnapshotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendanceSnapshotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendanceSnapshotsQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      paging: // value for 'paging'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useAttendanceSnapshotsQuery(
  baseOptions: Apollo.QueryHookOptions<AttendanceSnapshotsQuery, AttendanceSnapshotsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AttendanceSnapshotsQuery, AttendanceSnapshotsQueryVariables>(
    AttendanceSnapshotsDocument,
    options
  );
}
export function useAttendanceSnapshotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AttendanceSnapshotsQuery, AttendanceSnapshotsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AttendanceSnapshotsQuery, AttendanceSnapshotsQueryVariables>(
    AttendanceSnapshotsDocument,
    options
  );
}
export type AttendanceSnapshotsQueryHookResult = ReturnType<typeof useAttendanceSnapshotsQuery>;
export type AttendanceSnapshotsLazyQueryHookResult = ReturnType<typeof useAttendanceSnapshotsLazyQuery>;
export type AttendanceSnapshotsQueryResult = Apollo.QueryResult<
  AttendanceSnapshotsQuery,
  AttendanceSnapshotsQueryVariables
>;
export const AttendanceStagesDocument = gql`
  query AttendanceStages($organizationKey: String!, $paging: CursorPagingInput!, $queryParams: QueryParams)
  @feature(name: "engagement-analytics") {
    organization(organizationKey: $organizationKey) {
      communityMembersCursor(paging: $paging, queryParams: $queryParams) {
        aggregations {
          firstTimeAttenders: count(field: "pushpay:attendance_status", value: "first_time")
          lapsedAttenders: count(field: "pushpay:attendance_status", value: "lapsed")
          occasionalAttenders: count(field: "pushpay:attendance_status", value: "occasional")
          regularAttenders: count(field: "pushpay:attendance_status", value: "regular")
          secondTimeAttenders: count(field: "pushpay:attendance_status", value: "second_time")
        }
      }
    }
  }
`;

/**
 * __useAttendanceStagesQuery__
 *
 * To run a query within a React component, call `useAttendanceStagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendanceStagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendanceStagesQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      paging: // value for 'paging'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useAttendanceStagesQuery(
  baseOptions: Apollo.QueryHookOptions<AttendanceStagesQuery, AttendanceStagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AttendanceStagesQuery, AttendanceStagesQueryVariables>(AttendanceStagesDocument, options);
}
export function useAttendanceStagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AttendanceStagesQuery, AttendanceStagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AttendanceStagesQuery, AttendanceStagesQueryVariables>(AttendanceStagesDocument, options);
}
export type AttendanceStagesQueryHookResult = ReturnType<typeof useAttendanceStagesQuery>;
export type AttendanceStagesLazyQueryHookResult = ReturnType<typeof useAttendanceStagesLazyQuery>;
export type AttendanceStagesQueryResult = Apollo.QueryResult<AttendanceStagesQuery, AttendanceStagesQueryVariables>;
export const CommunityMemberAttendanceListTotalsDocument = gql`
  query CommunityMemberAttendanceListTotals(
    $organizationKey: String!
    $paging: CursorPagingInput!
    $queryParams: QueryParams
    $campusKeys: [String!]
  ) @feature(name: "engagement-analytics") {
    organization(organizationKey: $organizationKey) {
      communityMembersCursor(paging: $paging, queryParams: $queryParams, campusKeys: $campusKeys) {
        aggregations {
          allPeople: count(field: "archived", value: "false")
          firstTimeAttenders: count(field: "pushpay:attendance_status", value: "first_time")
          lapsedAttenders: count(field: "pushpay:attendance_status", value: "lapsed")
          occasionalAttenders: count(field: "pushpay:attendance_status", value: "occasional")
          regularAttenders: count(field: "pushpay:attendance_status", value: "regular")
          secondTimeAttenders: count(field: "pushpay:attendance_status", value: "second_time")
          recentNewAttenders: count(field: "pushpay:attendance_status_transition", value: "recent_new")
          atRiskAttenders: count(field: "pushpay:attendance_status_transition", value: "at_risk")
          curiousAttenders: count(field: "pushpay:attendance_status_transition", value: "curious")
          returningAttenders: count(field: "pushpay:attendance_status_transition", value: "returning")
          potentialInactive: count(field: "potentialInactive", value: "true")
        }
      }
    }
  }
`;

/**
 * __useCommunityMemberAttendanceListTotalsQuery__
 *
 * To run a query within a React component, call `useCommunityMemberAttendanceListTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityMemberAttendanceListTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityMemberAttendanceListTotalsQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      paging: // value for 'paging'
 *      queryParams: // value for 'queryParams'
 *      campusKeys: // value for 'campusKeys'
 *   },
 * });
 */
export function useCommunityMemberAttendanceListTotalsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CommunityMemberAttendanceListTotalsQuery,
    CommunityMemberAttendanceListTotalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CommunityMemberAttendanceListTotalsQuery, CommunityMemberAttendanceListTotalsQueryVariables>(
    CommunityMemberAttendanceListTotalsDocument,
    options
  );
}
export function useCommunityMemberAttendanceListTotalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CommunityMemberAttendanceListTotalsQuery,
    CommunityMemberAttendanceListTotalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CommunityMemberAttendanceListTotalsQuery,
    CommunityMemberAttendanceListTotalsQueryVariables
  >(CommunityMemberAttendanceListTotalsDocument, options);
}
export type CommunityMemberAttendanceListTotalsQueryHookResult = ReturnType<
  typeof useCommunityMemberAttendanceListTotalsQuery
>;
export type CommunityMemberAttendanceListTotalsLazyQueryHookResult = ReturnType<
  typeof useCommunityMemberAttendanceListTotalsLazyQuery
>;
export type CommunityMemberAttendanceListTotalsQueryResult = Apollo.QueryResult<
  CommunityMemberAttendanceListTotalsQuery,
  CommunityMemberAttendanceListTotalsQueryVariables
>;
export const CommunityMemberDonorListTotalsDocument = gql`
  query CommunityMemberDonorListTotals(
    $organizationKey: String!
    $paging: CursorPagingInput!
    $topDonorsCursor: PQSCursorPagingInput!
    $queryParams: QueryParams
    $allIndividualsQueryParams: QueryParams
    $campusKeys: [String!]
    $topDonorQueryFilter: TopCommunityMemberQueryFilterInput!
    $disablePQS: Boolean!
  ) @feature(name: "engagement-analytics") {
    organization(organizationKey: $organizationKey) {
      AllCount: communityMembersCursor(
        paging: $paging
        queryParams: $allIndividualsQueryParams
        campusKeys: $campusKeys
      ) {
        aggregations {
          allPeople: count(field: "archived", value: "false")
        }
      }
      IsDonorCounts: communityMembersCursor(paging: $paging, queryParams: $queryParams, campusKeys: $campusKeys) {
        aggregations {
          atRiskDonors: count(field: "pushpay:at_risk", value: "true")
          firstTimeDonors: count(field: "pushpay:giving_status", value: "new")
          lapsedDonors: count(field: "pushpay:giving_status", value: "lapsed")
          occasionalDonors: count(field: "pushpay:giving_status", value: "occasional")
          potentialDonors: count(field: "potentialDonor", value: "true")
          recurringDonors: count(field: "pushpay:giving_status", value: "recurring")
          regularDonors: count(field: "pushpay:giving_status", value: "regular")
          secondTimeDonors: count(field: "pushpay:giving_status", value: "second-time")
        }
      }
      TopDonorsCount: organizationCursoredTopCommunityMembers(
        cursor: $topDonorsCursor
        queryFilter: $topDonorQueryFilter
      ) @skip(if: $disablePQS) {
        paging {
          totalItemCount
        }
      }
    }
  }
`;

/**
 * __useCommunityMemberDonorListTotalsQuery__
 *
 * To run a query within a React component, call `useCommunityMemberDonorListTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityMemberDonorListTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityMemberDonorListTotalsQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      paging: // value for 'paging'
 *      topDonorsCursor: // value for 'topDonorsCursor'
 *      queryParams: // value for 'queryParams'
 *      allIndividualsQueryParams: // value for 'allIndividualsQueryParams'
 *      campusKeys: // value for 'campusKeys'
 *      topDonorQueryFilter: // value for 'topDonorQueryFilter'
 *      disablePQS: // value for 'disablePQS'
 *   },
 * });
 */
export function useCommunityMemberDonorListTotalsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CommunityMemberDonorListTotalsQuery,
    CommunityMemberDonorListTotalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CommunityMemberDonorListTotalsQuery, CommunityMemberDonorListTotalsQueryVariables>(
    CommunityMemberDonorListTotalsDocument,
    options
  );
}
export function useCommunityMemberDonorListTotalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CommunityMemberDonorListTotalsQuery,
    CommunityMemberDonorListTotalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CommunityMemberDonorListTotalsQuery, CommunityMemberDonorListTotalsQueryVariables>(
    CommunityMemberDonorListTotalsDocument,
    options
  );
}
export type CommunityMemberDonorListTotalsQueryHookResult = ReturnType<typeof useCommunityMemberDonorListTotalsQuery>;
export type CommunityMemberDonorListTotalsLazyQueryHookResult = ReturnType<
  typeof useCommunityMemberDonorListTotalsLazyQuery
>;
export type CommunityMemberDonorListTotalsQueryResult = Apollo.QueryResult<
  CommunityMemberDonorListTotalsQuery,
  CommunityMemberDonorListTotalsQueryVariables
>;
export const CommunityMemberVolunteerListTotalsDocument = gql`
  query CommunityMemberVolunteerListTotals(
    $organizationKey: String!
    $paging: CursorPagingInput!
    $queryParams: QueryParams
    $campusKeys: [String!]
  ) @feature(name: "engagement-analytics") {
    organization(organizationKey: $organizationKey) {
      communityMembersCursor(paging: $paging, queryParams: $queryParams, campusKeys: $campusKeys) {
        aggregations {
          allPeople: count(field: "archived", value: "false")
          atRiskVolunteers: count(field: "pushpay:serving_status_transition", value: "at_risk")
          curiousVolunteers: count(field: "pushpay:serving_status_transition", value: "curious")
          firstTimeVolunteers: count(field: "pushpay:serving_status", value: "first_time")
          lapsedVolunteers: count(field: "pushpay:serving_status", value: "lapsed")
          occasionalVolunteers: count(field: "pushpay:serving_status", value: "occasional")
          recentNewVolunteers: count(field: "pushpay:serving_status_transition", value: "recent_new")
          regularVolunteers: count(field: "pushpay:serving_status", value: "regular")
          returningVolunteers: count(field: "pushpay:serving_status_transition", value: "returning")
          secondTimeVolunteers: count(field: "pushpay:serving_status", value: "second_time")
        }
      }
    }
  }
`;

/**
 * __useCommunityMemberVolunteerListTotalsQuery__
 *
 * To run a query within a React component, call `useCommunityMemberVolunteerListTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityMemberVolunteerListTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityMemberVolunteerListTotalsQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      paging: // value for 'paging'
 *      queryParams: // value for 'queryParams'
 *      campusKeys: // value for 'campusKeys'
 *   },
 * });
 */
export function useCommunityMemberVolunteerListTotalsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CommunityMemberVolunteerListTotalsQuery,
    CommunityMemberVolunteerListTotalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CommunityMemberVolunteerListTotalsQuery, CommunityMemberVolunteerListTotalsQueryVariables>(
    CommunityMemberVolunteerListTotalsDocument,
    options
  );
}
export function useCommunityMemberVolunteerListTotalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CommunityMemberVolunteerListTotalsQuery,
    CommunityMemberVolunteerListTotalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CommunityMemberVolunteerListTotalsQuery, CommunityMemberVolunteerListTotalsQueryVariables>(
    CommunityMemberVolunteerListTotalsDocument,
    options
  );
}
export type CommunityMemberVolunteerListTotalsQueryHookResult = ReturnType<
  typeof useCommunityMemberVolunteerListTotalsQuery
>;
export type CommunityMemberVolunteerListTotalsLazyQueryHookResult = ReturnType<
  typeof useCommunityMemberVolunteerListTotalsLazyQuery
>;
export type CommunityMemberVolunteerListTotalsQueryResult = Apollo.QueryResult<
  CommunityMemberVolunteerListTotalsQuery,
  CommunityMemberVolunteerListTotalsQueryVariables
>;
export const CommunityServingSnapshotsDocument = gql`
  query CommunityServingSnapshots($organizationKey: String!, $paging: CursorPagingInput!, $queryParams: QueryParams)
  @feature(name: "engagement-analytics") {
    organization(organizationKey: $organizationKey) {
      communityMembersCursor(paging: $paging, queryParams: $queryParams) {
        aggregations {
          atRiskVolunteers: count(field: "pushpay:serving_status_transition", value: "at_risk")
          potentialVolunteers: count(field: "potentialVolunteer", value: "true")
        }
      }
    }
  }
`;

/**
 * __useCommunityServingSnapshotsQuery__
 *
 * To run a query within a React component, call `useCommunityServingSnapshotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityServingSnapshotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityServingSnapshotsQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      paging: // value for 'paging'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useCommunityServingSnapshotsQuery(
  baseOptions: Apollo.QueryHookOptions<CommunityServingSnapshotsQuery, CommunityServingSnapshotsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CommunityServingSnapshotsQuery, CommunityServingSnapshotsQueryVariables>(
    CommunityServingSnapshotsDocument,
    options
  );
}
export function useCommunityServingSnapshotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CommunityServingSnapshotsQuery, CommunityServingSnapshotsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CommunityServingSnapshotsQuery, CommunityServingSnapshotsQueryVariables>(
    CommunityServingSnapshotsDocument,
    options
  );
}
export type CommunityServingSnapshotsQueryHookResult = ReturnType<typeof useCommunityServingSnapshotsQuery>;
export type CommunityServingSnapshotsLazyQueryHookResult = ReturnType<typeof useCommunityServingSnapshotsLazyQuery>;
export type CommunityServingSnapshotsQueryResult = Apollo.QueryResult<
  CommunityServingSnapshotsQuery,
  CommunityServingSnapshotsQueryVariables
>;
export const GivingSnapshotsDocument = gql`
  query GivingSnapshots($organizationKey: String!, $paging: CursorPagingInput!, $queryParams: QueryParams)
  @feature(name: "engagement-analytics") {
    organization(organizationKey: $organizationKey) {
      communityMembersCursor(paging: $paging, queryParams: $queryParams) {
        aggregations {
          atRiskDonors: count(field: "pushpay:at_risk", value: "true")
          potentialDonors: count(field: "potentialDonor", value: "true")
        }
      }
    }
  }
`;

/**
 * __useGivingSnapshotsQuery__
 *
 * To run a query within a React component, call `useGivingSnapshotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGivingSnapshotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGivingSnapshotsQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      paging: // value for 'paging'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useGivingSnapshotsQuery(
  baseOptions: Apollo.QueryHookOptions<GivingSnapshotsQuery, GivingSnapshotsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GivingSnapshotsQuery, GivingSnapshotsQueryVariables>(GivingSnapshotsDocument, options);
}
export function useGivingSnapshotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GivingSnapshotsQuery, GivingSnapshotsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GivingSnapshotsQuery, GivingSnapshotsQueryVariables>(GivingSnapshotsDocument, options);
}
export type GivingSnapshotsQueryHookResult = ReturnType<typeof useGivingSnapshotsQuery>;
export type GivingSnapshotsLazyQueryHookResult = ReturnType<typeof useGivingSnapshotsLazyQuery>;
export type GivingSnapshotsQueryResult = Apollo.QueryResult<GivingSnapshotsQuery, GivingSnapshotsQueryVariables>;
export const GivingStagesDocument = gql`
  query GivingStages(
    $organizationKey: String!
    $paging: CursorPagingInput!
    $queryParams: QueryParams
    $allIndividualsQueryParams: QueryParams
  ) @feature(name: "engagement-analytics") {
    organization(organizationKey: $organizationKey) {
      AllCount: communityMembersCursor(paging: $paging, queryParams: $allIndividualsQueryParams) {
        aggregations {
          allPeople: count(field: "archived", value: "false")
        }
      }
      IsDonorCounts: communityMembersCursor(paging: $paging, queryParams: $queryParams) {
        aggregations {
          firstTimeDonors: count(field: "pushpay:giving_status", value: "new")
          lapsedDonors: count(field: "pushpay:giving_status", value: "lapsed")
          occasionalDonors: count(field: "pushpay:giving_status", value: "occasional")
          recurringDonors: count(field: "pushpay:giving_status", value: "recurring")
          regularDonors: count(field: "pushpay:giving_status", value: "regular")
          secondTimeDonors: count(field: "pushpay:giving_status", value: "second-time")
        }
      }
    }
  }
`;

/**
 * __useGivingStagesQuery__
 *
 * To run a query within a React component, call `useGivingStagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGivingStagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGivingStagesQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      paging: // value for 'paging'
 *      queryParams: // value for 'queryParams'
 *      allIndividualsQueryParams: // value for 'allIndividualsQueryParams'
 *   },
 * });
 */
export function useGivingStagesQuery(
  baseOptions: Apollo.QueryHookOptions<GivingStagesQuery, GivingStagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GivingStagesQuery, GivingStagesQueryVariables>(GivingStagesDocument, options);
}
export function useGivingStagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GivingStagesQuery, GivingStagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GivingStagesQuery, GivingStagesQueryVariables>(GivingStagesDocument, options);
}
export type GivingStagesQueryHookResult = ReturnType<typeof useGivingStagesQuery>;
export type GivingStagesLazyQueryHookResult = ReturnType<typeof useGivingStagesLazyQuery>;
export type GivingStagesQueryResult = Apollo.QueryResult<GivingStagesQuery, GivingStagesQueryVariables>;
export const GivingTypesDocument = gql`
  query GivingTypes($organizationKey: String!, $paging: CursorPagingInput!, $queryParams: QueryParams)
  @feature(name: "engagement-analytics") {
    organization(organizationKey: $organizationKey) {
      communityMembersCursor(paging: $paging, queryParams: $queryParams) {
        aggregations {
          digital: count(field: "pushpay:main_giving_type", value: "digital")
          offline: count(field: "pushpay:main_giving_type", value: "offline")
        }
      }
    }
  }
`;

/**
 * __useGivingTypesQuery__
 *
 * To run a query within a React component, call `useGivingTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGivingTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGivingTypesQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      paging: // value for 'paging'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useGivingTypesQuery(baseOptions: Apollo.QueryHookOptions<GivingTypesQuery, GivingTypesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GivingTypesQuery, GivingTypesQueryVariables>(GivingTypesDocument, options);
}
export function useGivingTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GivingTypesQuery, GivingTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GivingTypesQuery, GivingTypesQueryVariables>(GivingTypesDocument, options);
}
export type GivingTypesQueryHookResult = ReturnType<typeof useGivingTypesQuery>;
export type GivingTypesLazyQueryHookResult = ReturnType<typeof useGivingTypesLazyQuery>;
export type GivingTypesQueryResult = Apollo.QueryResult<GivingTypesQuery, GivingTypesQueryVariables>;
export const HouseholdAttendanceListTotalsDocument = gql`
  query HouseholdAttendanceListTotals(
    $organizationKey: String!
    $paging: CursorPagingInput!
    $queryParams: QueryParams
    $campusKeys: [String!]
  ) @feature(name: "engagement-analytics") {
    organization(organizationKey: $organizationKey) {
      households(paging: $paging, queryParams: $queryParams, campusKeys: $campusKeys) {
        aggregations {
          firstTimeAttenders: count(field: "pushpay:attendance_status", value: "first_time")
          lapsedAttenders: count(field: "pushpay:attendance_status", value: "lapsed")
          occasionalAttenders: count(field: "pushpay:attendance_status", value: "occasional")
          regularAttenders: count(field: "pushpay:attendance_status", value: "regular")
          secondTimeAttenders: count(field: "pushpay:attendance_status", value: "second_time")
          recentNewAttenders: count(field: "pushpay:attendance_status_transition", value: "recent_new")
          atRiskAttenders: count(field: "pushpay:attendance_status_transition", value: "at_risk")
          curiousAttenders: count(field: "pushpay:attendance_status_transition", value: "curious")
          returningAttenders: count(field: "pushpay:attendance_status_transition", value: "returning")
          potentialInactive: count(field: "potentialInactive", value: "true")
        }
      }
    }
  }
`;

/**
 * __useHouseholdAttendanceListTotalsQuery__
 *
 * To run a query within a React component, call `useHouseholdAttendanceListTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHouseholdAttendanceListTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHouseholdAttendanceListTotalsQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      paging: // value for 'paging'
 *      queryParams: // value for 'queryParams'
 *      campusKeys: // value for 'campusKeys'
 *   },
 * });
 */
export function useHouseholdAttendanceListTotalsQuery(
  baseOptions: Apollo.QueryHookOptions<HouseholdAttendanceListTotalsQuery, HouseholdAttendanceListTotalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HouseholdAttendanceListTotalsQuery, HouseholdAttendanceListTotalsQueryVariables>(
    HouseholdAttendanceListTotalsDocument,
    options
  );
}
export function useHouseholdAttendanceListTotalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HouseholdAttendanceListTotalsQuery,
    HouseholdAttendanceListTotalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HouseholdAttendanceListTotalsQuery, HouseholdAttendanceListTotalsQueryVariables>(
    HouseholdAttendanceListTotalsDocument,
    options
  );
}
export type HouseholdAttendanceListTotalsQueryHookResult = ReturnType<typeof useHouseholdAttendanceListTotalsQuery>;
export type HouseholdAttendanceListTotalsLazyQueryHookResult = ReturnType<
  typeof useHouseholdAttendanceListTotalsLazyQuery
>;
export type HouseholdAttendanceListTotalsQueryResult = Apollo.QueryResult<
  HouseholdAttendanceListTotalsQuery,
  HouseholdAttendanceListTotalsQueryVariables
>;
export const HouseholdCountDocument = gql`
  query HouseholdCount(
    $organizationKey: String!
    $paging: CursorPagingInput!
    $queryParams: QueryParams
    $campusKeys: [String!]
  ) @feature(name: "engagement-analytics") {
    organization(organizationKey: $organizationKey) {
      households(paging: $paging, queryParams: $queryParams, campusKeys: $campusKeys) {
        paging {
          totalItemCount
        }
      }
    }
  }
`;

/**
 * __useHouseholdCountQuery__
 *
 * To run a query within a React component, call `useHouseholdCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useHouseholdCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHouseholdCountQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      paging: // value for 'paging'
 *      queryParams: // value for 'queryParams'
 *      campusKeys: // value for 'campusKeys'
 *   },
 * });
 */
export function useHouseholdCountQuery(
  baseOptions: Apollo.QueryHookOptions<HouseholdCountQuery, HouseholdCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HouseholdCountQuery, HouseholdCountQueryVariables>(HouseholdCountDocument, options);
}
export function useHouseholdCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HouseholdCountQuery, HouseholdCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HouseholdCountQuery, HouseholdCountQueryVariables>(HouseholdCountDocument, options);
}
export type HouseholdCountQueryHookResult = ReturnType<typeof useHouseholdCountQuery>;
export type HouseholdCountLazyQueryHookResult = ReturnType<typeof useHouseholdCountLazyQuery>;
export type HouseholdCountQueryResult = Apollo.QueryResult<HouseholdCountQuery, HouseholdCountQueryVariables>;
export const HouseholdDonorListTotalsDocument = gql`
  query HouseholdDonorListTotals(
    $organizationKey: String!
    $paging: CursorPagingInput!
    $topDonorsCursor: PQSCursorPagingInput!
    $queryParams: QueryParams
    $allHouseholdsQueryParams: QueryParams
    $campusKeys: [String!]
    $topDonorQueryFilter: TopCommunityMemberQueryFilterInput!
    $disablePQS: Boolean!
  ) @feature(name: "engagement-analytics") {
    organization(organizationKey: $organizationKey) {
      AllCount: households(paging: $paging, queryParams: $allHouseholdsQueryParams, campusKeys: $campusKeys) {
        paging {
          totalItemCount
        }
      }
      IsDonorCounts: households(paging: $paging, queryParams: $queryParams, campusKeys: $campusKeys) {
        aggregations {
          atRiskDonors: count(field: "pushpay:at_risk", value: "true")
          firstTimeDonors: count(field: "pushpay:giving_status", value: "new")
          lapsedDonors: count(field: "pushpay:giving_status", value: "lapsed")
          occasionalDonors: count(field: "pushpay:giving_status", value: "occasional")
          recurringDonors: count(field: "pushpay:giving_status", value: "recurring")
          regularDonors: count(field: "pushpay:giving_status", value: "regular")
          secondTimeDonors: count(field: "pushpay:giving_status", value: "second-time")
        }
      }
      TopDonorsCount: organizationCursoredTopCommunityMembers(
        cursor: $topDonorsCursor
        queryFilter: $topDonorQueryFilter
      ) @skip(if: $disablePQS) {
        paging {
          totalItemCount
        }
      }
    }
  }
`;

/**
 * __useHouseholdDonorListTotalsQuery__
 *
 * To run a query within a React component, call `useHouseholdDonorListTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHouseholdDonorListTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHouseholdDonorListTotalsQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      paging: // value for 'paging'
 *      topDonorsCursor: // value for 'topDonorsCursor'
 *      queryParams: // value for 'queryParams'
 *      allHouseholdsQueryParams: // value for 'allHouseholdsQueryParams'
 *      campusKeys: // value for 'campusKeys'
 *      topDonorQueryFilter: // value for 'topDonorQueryFilter'
 *      disablePQS: // value for 'disablePQS'
 *   },
 * });
 */
export function useHouseholdDonorListTotalsQuery(
  baseOptions: Apollo.QueryHookOptions<HouseholdDonorListTotalsQuery, HouseholdDonorListTotalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HouseholdDonorListTotalsQuery, HouseholdDonorListTotalsQueryVariables>(
    HouseholdDonorListTotalsDocument,
    options
  );
}
export function useHouseholdDonorListTotalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HouseholdDonorListTotalsQuery, HouseholdDonorListTotalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HouseholdDonorListTotalsQuery, HouseholdDonorListTotalsQueryVariables>(
    HouseholdDonorListTotalsDocument,
    options
  );
}
export type HouseholdDonorListTotalsQueryHookResult = ReturnType<typeof useHouseholdDonorListTotalsQuery>;
export type HouseholdDonorListTotalsLazyQueryHookResult = ReturnType<typeof useHouseholdDonorListTotalsLazyQuery>;
export type HouseholdDonorListTotalsQueryResult = Apollo.QueryResult<
  HouseholdDonorListTotalsQuery,
  HouseholdDonorListTotalsQueryVariables
>;
export const HouseholdGivingStagesDocument = gql`
  query HouseholdGivingStages(
    $organizationKey: String!
    $paging: CursorPagingInput!
    $queryParams: QueryParams
    $allHouseholdsQueryParams: QueryParams
  ) @feature(name: "engagement-analytics") {
    organization(organizationKey: $organizationKey) {
      AllCount: households(paging: $paging, queryParams: $allHouseholdsQueryParams) {
        paging {
          totalItemCount
        }
      }
      IsDonorCounts: households(paging: $paging, queryParams: $queryParams) {
        aggregations {
          firstTimeDonors: count(field: "pushpay:giving_status", value: "new")
          lapsedDonors: count(field: "pushpay:giving_status", value: "lapsed")
          occasionalDonors: count(field: "pushpay:giving_status", value: "occasional")
          recurringDonors: count(field: "pushpay:giving_status", value: "recurring")
          regularDonors: count(field: "pushpay:giving_status", value: "regular")
          secondTimeDonors: count(field: "pushpay:giving_status", value: "second-time")
        }
      }
    }
  }
`;

/**
 * __useHouseholdGivingStagesQuery__
 *
 * To run a query within a React component, call `useHouseholdGivingStagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHouseholdGivingStagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHouseholdGivingStagesQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      paging: // value for 'paging'
 *      queryParams: // value for 'queryParams'
 *      allHouseholdsQueryParams: // value for 'allHouseholdsQueryParams'
 *   },
 * });
 */
export function useHouseholdGivingStagesQuery(
  baseOptions: Apollo.QueryHookOptions<HouseholdGivingStagesQuery, HouseholdGivingStagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HouseholdGivingStagesQuery, HouseholdGivingStagesQueryVariables>(
    HouseholdGivingStagesDocument,
    options
  );
}
export function useHouseholdGivingStagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HouseholdGivingStagesQuery, HouseholdGivingStagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HouseholdGivingStagesQuery, HouseholdGivingStagesQueryVariables>(
    HouseholdGivingStagesDocument,
    options
  );
}
export type HouseholdGivingStagesQueryHookResult = ReturnType<typeof useHouseholdGivingStagesQuery>;
export type HouseholdGivingStagesLazyQueryHookResult = ReturnType<typeof useHouseholdGivingStagesLazyQuery>;
export type HouseholdGivingStagesQueryResult = Apollo.QueryResult<
  HouseholdGivingStagesQuery,
  HouseholdGivingStagesQueryVariables
>;
export const HouseholdVolunteerListTotalsDocument = gql`
  query HouseholdVolunteerListTotals(
    $organizationKey: String!
    $paging: CursorPagingInput!
    $queryParams: QueryParams
    $campusKeys: [String!]
  ) @feature(name: "engagement-analytics") {
    organization(organizationKey: $organizationKey) {
      households(paging: $paging, queryParams: $queryParams, campusKeys: $campusKeys) {
        aggregations {
          atRiskVolunteers: count(field: "pushpay:serving_status_transition", value: "at_risk")
          curiousVolunteers: count(field: "pushpay:serving_status_transition", value: "curious")
          firstTimeVolunteers: count(field: "pushpay:serving_status", value: "first_time")
          lapsedVolunteers: count(field: "pushpay:serving_status", value: "lapsed")
          occasionalVolunteers: count(field: "pushpay:serving_status", value: "occasional")
          recentNewVolunteers: count(field: "pushpay:serving_status_transition", value: "recent_new")
          regularVolunteers: count(field: "pushpay:serving_status", value: "regular")
          returningVolunteers: count(field: "pushpay:serving_status_transition", value: "returning")
          secondTimeVolunteers: count(field: "pushpay:serving_status", value: "second_time")
        }
      }
    }
  }
`;

/**
 * __useHouseholdVolunteerListTotalsQuery__
 *
 * To run a query within a React component, call `useHouseholdVolunteerListTotalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHouseholdVolunteerListTotalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHouseholdVolunteerListTotalsQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      paging: // value for 'paging'
 *      queryParams: // value for 'queryParams'
 *      campusKeys: // value for 'campusKeys'
 *   },
 * });
 */
export function useHouseholdVolunteerListTotalsQuery(
  baseOptions: Apollo.QueryHookOptions<HouseholdVolunteerListTotalsQuery, HouseholdVolunteerListTotalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HouseholdVolunteerListTotalsQuery, HouseholdVolunteerListTotalsQueryVariables>(
    HouseholdVolunteerListTotalsDocument,
    options
  );
}
export function useHouseholdVolunteerListTotalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HouseholdVolunteerListTotalsQuery,
    HouseholdVolunteerListTotalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HouseholdVolunteerListTotalsQuery, HouseholdVolunteerListTotalsQueryVariables>(
    HouseholdVolunteerListTotalsDocument,
    options
  );
}
export type HouseholdVolunteerListTotalsQueryHookResult = ReturnType<typeof useHouseholdVolunteerListTotalsQuery>;
export type HouseholdVolunteerListTotalsLazyQueryHookResult = ReturnType<
  typeof useHouseholdVolunteerListTotalsLazyQuery
>;
export type HouseholdVolunteerListTotalsQueryResult = Apollo.QueryResult<
  HouseholdVolunteerListTotalsQuery,
  HouseholdVolunteerListTotalsQueryVariables
>;
export const LapsedAttenderCountDocument = gql`
  query LapsedAttenderCount($organizationKey: String!, $paging: CursorPagingInput!, $queryParams: QueryParams) {
    organization(organizationKey: $organizationKey) {
      communityMembersCursor(paging: $paging, queryParams: $queryParams) {
        aggregations {
          lapsedAttenders: count(field: "pushpay:attendance_status", value: "lapsed", applyWithin: true)
        }
      }
    }
  }
`;

/**
 * __useLapsedAttenderCountQuery__
 *
 * To run a query within a React component, call `useLapsedAttenderCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useLapsedAttenderCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLapsedAttenderCountQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      paging: // value for 'paging'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useLapsedAttenderCountQuery(
  baseOptions: Apollo.QueryHookOptions<LapsedAttenderCountQuery, LapsedAttenderCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LapsedAttenderCountQuery, LapsedAttenderCountQueryVariables>(
    LapsedAttenderCountDocument,
    options
  );
}
export function useLapsedAttenderCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LapsedAttenderCountQuery, LapsedAttenderCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LapsedAttenderCountQuery, LapsedAttenderCountQueryVariables>(
    LapsedAttenderCountDocument,
    options
  );
}
export type LapsedAttenderCountQueryHookResult = ReturnType<typeof useLapsedAttenderCountQuery>;
export type LapsedAttenderCountLazyQueryHookResult = ReturnType<typeof useLapsedAttenderCountLazyQuery>;
export type LapsedAttenderCountQueryResult = Apollo.QueryResult<
  LapsedAttenderCountQuery,
  LapsedAttenderCountQueryVariables
>;
export const PeopleCountDocument = gql`
  query PeopleCount(
    $organizationKey: String!
    $paging: CursorPagingInput!
    $queryParams: QueryParams
    $campusKeys: [String!]
  ) @feature(name: "engagement-analytics") {
    organization(organizationKey: $organizationKey) {
      communityMembersCursor(paging: $paging, queryParams: $queryParams, campusKeys: $campusKeys) {
        paging {
          totalItemCount
        }
      }
    }
  }
`;

/**
 * __usePeopleCountQuery__
 *
 * To run a query within a React component, call `usePeopleCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeopleCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeopleCountQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      paging: // value for 'paging'
 *      queryParams: // value for 'queryParams'
 *      campusKeys: // value for 'campusKeys'
 *   },
 * });
 */
export function usePeopleCountQuery(baseOptions: Apollo.QueryHookOptions<PeopleCountQuery, PeopleCountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PeopleCountQuery, PeopleCountQueryVariables>(PeopleCountDocument, options);
}
export function usePeopleCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PeopleCountQuery, PeopleCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PeopleCountQuery, PeopleCountQueryVariables>(PeopleCountDocument, options);
}
export type PeopleCountQueryHookResult = ReturnType<typeof usePeopleCountQuery>;
export type PeopleCountLazyQueryHookResult = ReturnType<typeof usePeopleCountLazyQuery>;
export type PeopleCountQueryResult = Apollo.QueryResult<PeopleCountQuery, PeopleCountQueryVariables>;
export const PeopleListByCommunityMemberDocument = gql`
  query PeopleListByCommunityMember(
    $organizationKey: String!
    $campusKeys: [String!]
    $paging: CursorPagingInput!
    $queryParams: QueryParams
    $isPeopleListExtraColumnsEnabled: Boolean!
  ) @feature(name: "engagement-analytics") {
    organization(organizationKey: $organizationKey) {
      communityMembersCursor(campusKeys: $campusKeys, paging: $paging, queryParams: $queryParams) {
        items {
          campusKey
          ccbMembershipTypeId
          ccbIndividual {
            individualId
          }
          communityMemberKey
          email {
            address
          }
          firstName
          lastName
          nickname
          createdOn
          phoneNumber {
            countryCode
            number
          }
          attendanceStatus: tag(name: "pushpay:attendance_status", current: false)
          givingStatus: tag(name: "pushpay:giving_status", current: false)
          servingStatus: tag(name: "pushpay:serving_status", current: false)
          profileImages {
            thumbnailUrl
          }
          lifeTimeGivingTotal: tag(name: "pushpay:lifetime_giving_amount")
            @include(if: $isPeopleListExtraColumnsEnabled)
          lastGiftAmount: tag(name: "pushpay:last_gift_amount") @include(if: $isPeopleListExtraColumnsEnabled)
          lastGiftDate: tag(name: "pushpay:last_gift_date") @include(if: $isPeopleListExtraColumnsEnabled)
          yearToDateGivingTotal: tag(name: "pushpay:year_to_date_giving_amount")
            @include(if: $isPeopleListExtraColumnsEnabled)
          yearToDateLargestGiftAmount: tag(name: "pushpay:year_to_date_largest_gift_amount")
            @include(if: $isPeopleListExtraColumnsEnabled)
          yearToDateLargestGiftDate: tag(name: "pushpay:year_to_date_largest_gift_date")
            @include(if: $isPeopleListExtraColumnsEnabled)
          estimatedAverageMonthlyTotal: tag(name: "pushpay:estimated_average_monthly_total")
            @include(if: $isPeopleListExtraColumnsEnabled)
        }
        paging {
          first
          hasNextPage
          hasPreviousPage
          last
          size
          totalItemCount
        }
      }
    }
  }
`;

/**
 * __usePeopleListByCommunityMemberQuery__
 *
 * To run a query within a React component, call `usePeopleListByCommunityMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeopleListByCommunityMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeopleListByCommunityMemberQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      campusKeys: // value for 'campusKeys'
 *      paging: // value for 'paging'
 *      queryParams: // value for 'queryParams'
 *      isPeopleListExtraColumnsEnabled: // value for 'isPeopleListExtraColumnsEnabled'
 *   },
 * });
 */
export function usePeopleListByCommunityMemberQuery(
  baseOptions: Apollo.QueryHookOptions<PeopleListByCommunityMemberQuery, PeopleListByCommunityMemberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PeopleListByCommunityMemberQuery, PeopleListByCommunityMemberQueryVariables>(
    PeopleListByCommunityMemberDocument,
    options
  );
}
export function usePeopleListByCommunityMemberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PeopleListByCommunityMemberQuery, PeopleListByCommunityMemberQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PeopleListByCommunityMemberQuery, PeopleListByCommunityMemberQueryVariables>(
    PeopleListByCommunityMemberDocument,
    options
  );
}
export type PeopleListByCommunityMemberQueryHookResult = ReturnType<typeof usePeopleListByCommunityMemberQuery>;
export type PeopleListByCommunityMemberLazyQueryHookResult = ReturnType<typeof usePeopleListByCommunityMemberLazyQuery>;
export type PeopleListByCommunityMemberQueryResult = Apollo.QueryResult<
  PeopleListByCommunityMemberQuery,
  PeopleListByCommunityMemberQueryVariables
>;
export const PeopleListByHouseholdDocument = gql`
  query PeopleListByHousehold(
    $organizationKey: String!
    $campusKeys: [String!]
    $paging: CursorPagingInput!
    $queryParams: QueryParams
    $isPeopleListExtraColumnsEnabled: Boolean!
  ) @feature(name: "engagement-analytics") {
    organization(organizationKey: $organizationKey) {
      households(campusKeys: $campusKeys, paging: $paging, queryParams: $queryParams) {
        items {
          campusKey
          email {
            address
          }
          householdKey
          name
          numberOfMembers
          members {
            communityMember {
              ccbIndividual {
                individualId
              }
            }
            primary
          }
          createdOn
          phone {
            countryCode
            number
          }
          attendanceStatus: tag(name: "pushpay:attendance_status", current: false)
          givingStatus: tag(name: "pushpay:giving_status", current: false)
          servingStatus: tag(name: "pushpay:serving_status", current: false)
          lifeTimeGivingTotal: tag(name: "pushpay:lifetime_giving_amount")
            @include(if: $isPeopleListExtraColumnsEnabled)
          lastGiftAmount: tag(name: "pushpay:last_gift_amount") @include(if: $isPeopleListExtraColumnsEnabled)
          lastGiftDate: tag(name: "pushpay:last_gift_date") @include(if: $isPeopleListExtraColumnsEnabled)
          yearToDateGivingTotal: tag(name: "pushpay:year_to_date_giving_amount")
            @include(if: $isPeopleListExtraColumnsEnabled)
          yearToDateLargestGiftAmount: tag(name: "pushpay:year_to_date_largest_gift_amount")
            @include(if: $isPeopleListExtraColumnsEnabled)
          yearToDateLargestGiftDate: tag(name: "pushpay:year_to_date_largest_gift_date")
            @include(if: $isPeopleListExtraColumnsEnabled)
          estimatedAverageMonthlyTotal: tag(name: "pushpay:estimated_average_monthly_total")
            @include(if: $isPeopleListExtraColumnsEnabled)
        }
        paging {
          first
          hasNextPage
          hasPreviousPage
          last
          size
          totalItemCount
        }
      }
    }
  }
`;

/**
 * __usePeopleListByHouseholdQuery__
 *
 * To run a query within a React component, call `usePeopleListByHouseholdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeopleListByHouseholdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeopleListByHouseholdQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      campusKeys: // value for 'campusKeys'
 *      paging: // value for 'paging'
 *      queryParams: // value for 'queryParams'
 *      isPeopleListExtraColumnsEnabled: // value for 'isPeopleListExtraColumnsEnabled'
 *   },
 * });
 */
export function usePeopleListByHouseholdQuery(
  baseOptions: Apollo.QueryHookOptions<PeopleListByHouseholdQuery, PeopleListByHouseholdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PeopleListByHouseholdQuery, PeopleListByHouseholdQueryVariables>(
    PeopleListByHouseholdDocument,
    options
  );
}
export function usePeopleListByHouseholdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PeopleListByHouseholdQuery, PeopleListByHouseholdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PeopleListByHouseholdQuery, PeopleListByHouseholdQueryVariables>(
    PeopleListByHouseholdDocument,
    options
  );
}
export type PeopleListByHouseholdQueryHookResult = ReturnType<typeof usePeopleListByHouseholdQuery>;
export type PeopleListByHouseholdLazyQueryHookResult = ReturnType<typeof usePeopleListByHouseholdLazyQuery>;
export type PeopleListByHouseholdQueryResult = Apollo.QueryResult<
  PeopleListByHouseholdQuery,
  PeopleListByHouseholdQueryVariables
>;
export const RecentNewVolunteersDocument = gql`
  query RecentNewVolunteers($organizationKey: String!, $paging: CursorPagingInput!, $queryParams: QueryParams)
  @feature(name: "engagement-analytics") {
    organization(organizationKey: $organizationKey) {
      communityMembersCursor(paging: $paging, queryParams: $queryParams) {
        aggregations {
          recentNewVolunteers: count(field: "pushpay:serving_status_transition", value: "recent_new")
        }
      }
    }
  }
`;

/**
 * __useRecentNewVolunteersQuery__
 *
 * To run a query within a React component, call `useRecentNewVolunteersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentNewVolunteersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentNewVolunteersQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      paging: // value for 'paging'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useRecentNewVolunteersQuery(
  baseOptions: Apollo.QueryHookOptions<RecentNewVolunteersQuery, RecentNewVolunteersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RecentNewVolunteersQuery, RecentNewVolunteersQueryVariables>(
    RecentNewVolunteersDocument,
    options
  );
}
export function useRecentNewVolunteersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RecentNewVolunteersQuery, RecentNewVolunteersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RecentNewVolunteersQuery, RecentNewVolunteersQueryVariables>(
    RecentNewVolunteersDocument,
    options
  );
}
export type RecentNewVolunteersQueryHookResult = ReturnType<typeof useRecentNewVolunteersQuery>;
export type RecentNewVolunteersLazyQueryHookResult = ReturnType<typeof useRecentNewVolunteersLazyQuery>;
export type RecentNewVolunteersQueryResult = Apollo.QueryResult<
  RecentNewVolunteersQuery,
  RecentNewVolunteersQueryVariables
>;
export const RegularAttenderCountDocument = gql`
  query RegularAttenderCount($organizationKey: String!, $paging: CursorPagingInput!, $queryParams: QueryParams) {
    organization(organizationKey: $organizationKey) {
      communityMembersCursor(paging: $paging, queryParams: $queryParams) {
        aggregations {
          regularAttenders: count(field: "pushpay:attendance_status", value: "regular")
        }
      }
    }
  }
`;

/**
 * __useRegularAttenderCountQuery__
 *
 * To run a query within a React component, call `useRegularAttenderCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegularAttenderCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegularAttenderCountQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      paging: // value for 'paging'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useRegularAttenderCountQuery(
  baseOptions: Apollo.QueryHookOptions<RegularAttenderCountQuery, RegularAttenderCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RegularAttenderCountQuery, RegularAttenderCountQueryVariables>(
    RegularAttenderCountDocument,
    options
  );
}
export function useRegularAttenderCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RegularAttenderCountQuery, RegularAttenderCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RegularAttenderCountQuery, RegularAttenderCountQueryVariables>(
    RegularAttenderCountDocument,
    options
  );
}
export type RegularAttenderCountQueryHookResult = ReturnType<typeof useRegularAttenderCountQuery>;
export type RegularAttenderCountLazyQueryHookResult = ReturnType<typeof useRegularAttenderCountLazyQuery>;
export type RegularAttenderCountQueryResult = Apollo.QueryResult<
  RegularAttenderCountQuery,
  RegularAttenderCountQueryVariables
>;
export const VolunteerStagesDocument = gql`
  query VolunteerStages($organizationKey: String!, $paging: CursorPagingInput!, $queryParams: QueryParams)
  @feature(name: "engagement-analytics") {
    organization(organizationKey: $organizationKey) {
      communityMembersCursor(paging: $paging, queryParams: $queryParams) {
        aggregations {
          firstTimeVolunteers: count(field: "pushpay:serving_status", value: "first_time")
          lapsedVolunteers: count(field: "pushpay:serving_status", value: "lapsed")
          occasionalVolunteers: count(field: "pushpay:serving_status", value: "occasional")
          regularVolunteers: count(field: "pushpay:serving_status", value: "regular")
          secondTimeVolunteers: count(field: "pushpay:serving_status", value: "second_time")
        }
      }
    }
  }
`;

/**
 * __useVolunteerStagesQuery__
 *
 * To run a query within a React component, call `useVolunteerStagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVolunteerStagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVolunteerStagesQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      paging: // value for 'paging'
 *      queryParams: // value for 'queryParams'
 *   },
 * });
 */
export function useVolunteerStagesQuery(
  baseOptions: Apollo.QueryHookOptions<VolunteerStagesQuery, VolunteerStagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VolunteerStagesQuery, VolunteerStagesQueryVariables>(VolunteerStagesDocument, options);
}
export function useVolunteerStagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VolunteerStagesQuery, VolunteerStagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VolunteerStagesQuery, VolunteerStagesQueryVariables>(VolunteerStagesDocument, options);
}
export type VolunteerStagesQueryHookResult = ReturnType<typeof useVolunteerStagesQuery>;
export type VolunteerStagesLazyQueryHookResult = ReturnType<typeof useVolunteerStagesLazyQuery>;
export type VolunteerStagesQueryResult = Apollo.QueryResult<VolunteerStagesQuery, VolunteerStagesQueryVariables>;
export const AttendanceDashboardCardDocument = gql`
  query AttendanceDashboardCard(
    $organizationKey: String!
    $paging: CursorPagingInput!
    $lastQueryParams: QueryParams!
    $previousQueryParams: QueryParams!
  ) @feature(name: "engagement-analytics") {
    AllInsightEligibleIndividuals: organization(organizationKey: $organizationKey) {
      communityMembersCursor(paging: $paging, queryParams: $lastQueryParams) {
        aggregations {
          All: count(field: "archived", value: "false")
        }
      }
    }
    Attenders: organization(organizationKey: $organizationKey) {
      LastData: communityMembersCursor(paging: $paging, queryParams: $lastQueryParams) {
        aggregations {
          AtRisk: count(field: "pushpay:attendance_status_transition", value: "at_risk")
          Curious: count(field: "pushpay:attendance_status_transition", value: "curious")
          FirstTime: count(field: "pushpay:attendance_status", value: "first_time")
          RecentNew: count(field: "pushpay:attendance_status_transition", value: "recent_new")
          Regular: count(field: "pushpay:attendance_status", value: "regular")
          Returning: count(field: "pushpay:attendance_status_transition", value: "returning")
          SecondTime: count(field: "pushpay:attendance_status", value: "second_time")
        }
      }
      PreviousData: communityMembersCursor(paging: $paging, queryParams: $previousQueryParams) {
        aggregations {
          AtRisk: count(field: "pushpay:attendance_status_transition", value: "at_risk")
          Curious: count(field: "pushpay:attendance_status_transition", value: "curious")
          RecentNew: count(field: "pushpay:attendance_status_transition", value: "recent_new")
          Returning: count(field: "pushpay:attendance_status_transition", value: "returning")
        }
      }
    }
  }
`;

/**
 * __useAttendanceDashboardCardQuery__
 *
 * To run a query within a React component, call `useAttendanceDashboardCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendanceDashboardCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendanceDashboardCardQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      paging: // value for 'paging'
 *      lastQueryParams: // value for 'lastQueryParams'
 *      previousQueryParams: // value for 'previousQueryParams'
 *   },
 * });
 */
export function useAttendanceDashboardCardQuery(
  baseOptions: Apollo.QueryHookOptions<AttendanceDashboardCardQuery, AttendanceDashboardCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AttendanceDashboardCardQuery, AttendanceDashboardCardQueryVariables>(
    AttendanceDashboardCardDocument,
    options
  );
}
export function useAttendanceDashboardCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AttendanceDashboardCardQuery, AttendanceDashboardCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AttendanceDashboardCardQuery, AttendanceDashboardCardQueryVariables>(
    AttendanceDashboardCardDocument,
    options
  );
}
export type AttendanceDashboardCardQueryHookResult = ReturnType<typeof useAttendanceDashboardCardQuery>;
export type AttendanceDashboardCardLazyQueryHookResult = ReturnType<typeof useAttendanceDashboardCardLazyQuery>;
export type AttendanceDashboardCardQueryResult = Apollo.QueryResult<
  AttendanceDashboardCardQuery,
  AttendanceDashboardCardQueryVariables
>;
export const AttendanceDashboardTilesDocument = gql`
  query AttendanceDashboardTiles(
    $organizationKey: String!
    $paging: CursorPagingInput!
    $attendanceChurnLapsedLastParams: QueryParams!
    $attendanceChurnLapsedPrevParams: QueryParams!
    $attendanceChurnRegularLastParams: QueryParams!
    $attendanceChurnRegularPrevParams: QueryParams!
    $potentialInactiveQueryParams: QueryParams!
  ) @feature(name: "engagement-analytics") {
    organization(organizationKey: $organizationKey) {
      AttendanceChurnLapsedLastData: communityMembersCursor(
        paging: $paging
        queryParams: $attendanceChurnLapsedLastParams
      ) {
        aggregations {
          count(field: "pushpay:attendance_status", value: "lapsed", applyWithin: true)
        }
      }
      AttendanceChurnLapsedPrevData: communityMembersCursor(
        paging: $paging
        queryParams: $attendanceChurnLapsedPrevParams
      ) {
        aggregations {
          count(field: "pushpay:attendance_status", value: "lapsed", applyWithin: true)
        }
      }
      AttendanceChurnRegularLastData: communityMembersCursor(
        paging: $paging
        queryParams: $attendanceChurnRegularLastParams
      ) {
        aggregations {
          count(field: "pushpay:attendance_status", value: "regular")
        }
      }
      AttendanceChurnRegularPrevData: communityMembersCursor(
        paging: $paging
        queryParams: $attendanceChurnRegularPrevParams
      ) {
        aggregations {
          count(field: "pushpay:attendance_status", value: "regular")
        }
      }
      PotentialInactive: communityMembersCursor(paging: $paging, queryParams: $potentialInactiveQueryParams) {
        aggregations {
          count(field: "potentialInactive", value: "true")
        }
      }
    }
  }
`;

/**
 * __useAttendanceDashboardTilesQuery__
 *
 * To run a query within a React component, call `useAttendanceDashboardTilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendanceDashboardTilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendanceDashboardTilesQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      paging: // value for 'paging'
 *      attendanceChurnLapsedLastParams: // value for 'attendanceChurnLapsedLastParams'
 *      attendanceChurnLapsedPrevParams: // value for 'attendanceChurnLapsedPrevParams'
 *      attendanceChurnRegularLastParams: // value for 'attendanceChurnRegularLastParams'
 *      attendanceChurnRegularPrevParams: // value for 'attendanceChurnRegularPrevParams'
 *      potentialInactiveQueryParams: // value for 'potentialInactiveQueryParams'
 *   },
 * });
 */
export function useAttendanceDashboardTilesQuery(
  baseOptions: Apollo.QueryHookOptions<AttendanceDashboardTilesQuery, AttendanceDashboardTilesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AttendanceDashboardTilesQuery, AttendanceDashboardTilesQueryVariables>(
    AttendanceDashboardTilesDocument,
    options
  );
}
export function useAttendanceDashboardTilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AttendanceDashboardTilesQuery, AttendanceDashboardTilesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AttendanceDashboardTilesQuery, AttendanceDashboardTilesQueryVariables>(
    AttendanceDashboardTilesDocument,
    options
  );
}
export type AttendanceDashboardTilesQueryHookResult = ReturnType<typeof useAttendanceDashboardTilesQuery>;
export type AttendanceDashboardTilesLazyQueryHookResult = ReturnType<typeof useAttendanceDashboardTilesLazyQuery>;
export type AttendanceDashboardTilesQueryResult = Apollo.QueryResult<
  AttendanceDashboardTilesQuery,
  AttendanceDashboardTilesQueryVariables
>;
export const GivingDashboardCardDocument = gql`
  query GivingDashboardCard(
    $organizationKey: String!
    $paging: CursorPagingInput!
    $donorRecentNewLastParams: QueryParams!
    $donorRecentNewPrevParams: QueryParams!
    $donorLastQueryParams: QueryParams!
    $donorPreviousQueryParams: QueryParams!
    $allIndividualsQueryParams: QueryParams!
  ) @feature(name: "engagement-analytics") {
    AllInsightEligibleIndividuals: organization(organizationKey: $organizationKey) {
      communityMembersCursor(paging: $paging, queryParams: $allIndividualsQueryParams) {
        aggregations {
          All: count(field: "archived", value: "false")
        }
      }
    }
    Donors: organization(organizationKey: $organizationKey) {
      RecentNewLast: communityMembersCursor(paging: $paging, queryParams: $donorRecentNewLastParams) {
        paging {
          totalItemCount
        }
      }
      RecentNewPrevious: communityMembersCursor(paging: $paging, queryParams: $donorRecentNewPrevParams) {
        paging {
          totalItemCount
        }
      }
      LastData: communityMembersCursor(paging: $paging, queryParams: $donorLastQueryParams) {
        aggregations {
          AtRisk: count(field: "pushpay:at_risk", value: "true")
          FirstTime: count(field: "pushpay:giving_status", value: "new")
          Potential: count(field: "potentialDonor", value: "true")
          Recurring: count(field: "pushpay:giving_status", value: "recurring")
          Regular: count(field: "pushpay:giving_status", value: "regular")
          SecondTime: count(field: "pushpay:giving_status", value: "second-time")
        }
      }
      PreviousData: communityMembersCursor(paging: $paging, queryParams: $donorPreviousQueryParams) {
        aggregations {
          AtRisk: count(field: "pushpay:at_risk", value: "true")
        }
      }
    }
  }
`;

/**
 * __useGivingDashboardCardQuery__
 *
 * To run a query within a React component, call `useGivingDashboardCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGivingDashboardCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGivingDashboardCardQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      paging: // value for 'paging'
 *      donorRecentNewLastParams: // value for 'donorRecentNewLastParams'
 *      donorRecentNewPrevParams: // value for 'donorRecentNewPrevParams'
 *      donorLastQueryParams: // value for 'donorLastQueryParams'
 *      donorPreviousQueryParams: // value for 'donorPreviousQueryParams'
 *      allIndividualsQueryParams: // value for 'allIndividualsQueryParams'
 *   },
 * });
 */
export function useGivingDashboardCardQuery(
  baseOptions: Apollo.QueryHookOptions<GivingDashboardCardQuery, GivingDashboardCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GivingDashboardCardQuery, GivingDashboardCardQueryVariables>(
    GivingDashboardCardDocument,
    options
  );
}
export function useGivingDashboardCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GivingDashboardCardQuery, GivingDashboardCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GivingDashboardCardQuery, GivingDashboardCardQueryVariables>(
    GivingDashboardCardDocument,
    options
  );
}
export type GivingDashboardCardQueryHookResult = ReturnType<typeof useGivingDashboardCardQuery>;
export type GivingDashboardCardLazyQueryHookResult = ReturnType<typeof useGivingDashboardCardLazyQuery>;
export type GivingDashboardCardQueryResult = Apollo.QueryResult<
  GivingDashboardCardQuery,
  GivingDashboardCardQueryVariables
>;
export const GivingDashboardTilesDocument = gql`
  query GivingDashboardTiles(
    $organizationKey: String!
    $isDisablePQSEnabled: Boolean!
    $paging: CursorPagingInput!
    $attendanceOverviewInput: AttendanceOverviewInput!
    $donorCurrentTimeRange: GranularTimeRangeInput!
    $donorOrganizationQueryFilter: OrganizationQueryFilterInput!
    $donorLastQueryParams: QueryParams!
  ) @feature(name: "engagement-analytics") {
    organizationAttendanceOverview(organizationKey: $organizationKey) {
      highlights(attendanceOverviewInput: $attendanceOverviewInput) {
        totalAttendance
      }
    }
    Donors: organization(organizationKey: $organizationKey) {
      CurrentGivingSummaries: organizationGivingSummariesByPeriodNew(
        timeRange: $donorCurrentTimeRange
        organizationQueryFilter: $donorOrganizationQueryFilter
      ) @skip(if: $isDisablePQSEnabled) {
        periods {
          fromDate
          toDate
          paymentsAggregate {
            count
            totalValue {
              amount
              currency
            }
          }
        }
      }
      communityMembersCursor(paging: $paging, queryParams: $donorLastQueryParams) {
        aggregations {
          Digital: count(field: "pushpay:main_giving_type", value: "digital")
          Offline: count(field: "pushpay:main_giving_type", value: "offline")
        }
      }
    }
  }
`;

/**
 * __useGivingDashboardTilesQuery__
 *
 * To run a query within a React component, call `useGivingDashboardTilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGivingDashboardTilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGivingDashboardTilesQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      isDisablePQSEnabled: // value for 'isDisablePQSEnabled'
 *      paging: // value for 'paging'
 *      attendanceOverviewInput: // value for 'attendanceOverviewInput'
 *      donorCurrentTimeRange: // value for 'donorCurrentTimeRange'
 *      donorOrganizationQueryFilter: // value for 'donorOrganizationQueryFilter'
 *      donorLastQueryParams: // value for 'donorLastQueryParams'
 *   },
 * });
 */
export function useGivingDashboardTilesQuery(
  baseOptions: Apollo.QueryHookOptions<GivingDashboardTilesQuery, GivingDashboardTilesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GivingDashboardTilesQuery, GivingDashboardTilesQueryVariables>(
    GivingDashboardTilesDocument,
    options
  );
}
export function useGivingDashboardTilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GivingDashboardTilesQuery, GivingDashboardTilesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GivingDashboardTilesQuery, GivingDashboardTilesQueryVariables>(
    GivingDashboardTilesDocument,
    options
  );
}
export type GivingDashboardTilesQueryHookResult = ReturnType<typeof useGivingDashboardTilesQuery>;
export type GivingDashboardTilesLazyQueryHookResult = ReturnType<typeof useGivingDashboardTilesLazyQuery>;
export type GivingDashboardTilesQueryResult = Apollo.QueryResult<
  GivingDashboardTilesQuery,
  GivingDashboardTilesQueryVariables
>;
export const GivingHouseholdDashboardCardDocument = gql`
  query GivingHouseholdDashboardCard(
    $organizationKey: String!
    $paging: CursorPagingInput!
    $donorRecentNewLastParams: QueryParams!
    $donorRecentNewPrevParams: QueryParams!
    $donorLastQueryParams: QueryParams!
    $donorPreviousQueryParams: QueryParams!
    $allHouseholdsQueryParams: QueryParams!
    $donorPotentialQueryParams: QueryParams!
  ) @feature(name: "engagement-analytics") {
    AllInsightEligibleHouseholds: organization(organizationKey: $organizationKey) {
      households(paging: $paging, queryParams: $allHouseholdsQueryParams) {
        paging {
          totalItemCount
        }
      }
    }
    Donors: organization(organizationKey: $organizationKey) {
      RecentNewLast: households(paging: $paging, queryParams: $donorRecentNewLastParams) {
        paging {
          totalItemCount
        }
      }
      RecentNewPrevious: households(paging: $paging, queryParams: $donorRecentNewPrevParams) {
        paging {
          totalItemCount
        }
      }
      LastData: households(paging: $paging, queryParams: $donorLastQueryParams) {
        aggregations {
          AtRisk: count(field: "pushpay:at_risk", value: "true")
          FirstTime: count(field: "pushpay:giving_status", value: "new")
          Recurring: count(field: "pushpay:giving_status", value: "recurring")
          Regular: count(field: "pushpay:giving_status", value: "regular")
          SecondTime: count(field: "pushpay:giving_status", value: "second-time")
        }
      }
      Potential: households(paging: $paging, queryParams: $donorPotentialQueryParams) {
        paging {
          totalItemCount
        }
      }
      PreviousData: households(paging: $paging, queryParams: $donorPreviousQueryParams) {
        aggregations {
          AtRisk: count(field: "pushpay:at_risk", value: "true")
        }
      }
    }
  }
`;

/**
 * __useGivingHouseholdDashboardCardQuery__
 *
 * To run a query within a React component, call `useGivingHouseholdDashboardCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGivingHouseholdDashboardCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGivingHouseholdDashboardCardQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      paging: // value for 'paging'
 *      donorRecentNewLastParams: // value for 'donorRecentNewLastParams'
 *      donorRecentNewPrevParams: // value for 'donorRecentNewPrevParams'
 *      donorLastQueryParams: // value for 'donorLastQueryParams'
 *      donorPreviousQueryParams: // value for 'donorPreviousQueryParams'
 *      allHouseholdsQueryParams: // value for 'allHouseholdsQueryParams'
 *      donorPotentialQueryParams: // value for 'donorPotentialQueryParams'
 *   },
 * });
 */
export function useGivingHouseholdDashboardCardQuery(
  baseOptions: Apollo.QueryHookOptions<GivingHouseholdDashboardCardQuery, GivingHouseholdDashboardCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GivingHouseholdDashboardCardQuery, GivingHouseholdDashboardCardQueryVariables>(
    GivingHouseholdDashboardCardDocument,
    options
  );
}
export function useGivingHouseholdDashboardCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GivingHouseholdDashboardCardQuery,
    GivingHouseholdDashboardCardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GivingHouseholdDashboardCardQuery, GivingHouseholdDashboardCardQueryVariables>(
    GivingHouseholdDashboardCardDocument,
    options
  );
}
export type GivingHouseholdDashboardCardQueryHookResult = ReturnType<typeof useGivingHouseholdDashboardCardQuery>;
export type GivingHouseholdDashboardCardLazyQueryHookResult = ReturnType<
  typeof useGivingHouseholdDashboardCardLazyQuery
>;
export type GivingHouseholdDashboardCardQueryResult = Apollo.QueryResult<
  GivingHouseholdDashboardCardQuery,
  GivingHouseholdDashboardCardQueryVariables
>;
export const ServingDashboardCardDocument = gql`
  query ServingDashboardCard(
    $organizationKey: String!
    $paging: CursorPagingInput!
    $lastQueryParams: QueryParams!
    $previousQueryParams: QueryParams!
  ) @feature(name: "engagement-analytics") {
    AllInsightEligibleIndividuals: organization(organizationKey: $organizationKey) {
      communityMembersCursor(paging: $paging, queryParams: $lastQueryParams) {
        aggregations {
          All: count(field: "archived", value: "false")
        }
      }
    }
    Volunteers: organization(organizationKey: $organizationKey) {
      LastData: communityMembersCursor(paging: $paging, queryParams: $lastQueryParams) {
        aggregations {
          AtRisk: count(field: "pushpay:serving_status_transition", value: "at_risk")
          Curious: count(field: "pushpay:serving_status_transition", value: "curious")
          FirstTime: count(field: "pushpay:serving_status", value: "first_time")
          Potential: count(field: "potentialVolunteer", value: "true")
          RecentNew: count(field: "pushpay:serving_status_transition", value: "recent_new")
          Regular: count(field: "pushpay:serving_status", value: "regular")
          Returning: count(field: "pushpay:serving_status_transition", value: "returning")
          SecondTime: count(field: "pushpay:serving_status", value: "second_time")
        }
      }
      PreviousData: communityMembersCursor(paging: $paging, queryParams: $previousQueryParams) {
        aggregations {
          AtRisk: count(field: "pushpay:serving_status_transition", value: "at_risk")
          RecentNew: count(field: "pushpay:serving_status_transition", value: "recent_new")
        }
      }
    }
  }
`;

/**
 * __useServingDashboardCardQuery__
 *
 * To run a query within a React component, call `useServingDashboardCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useServingDashboardCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServingDashboardCardQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      paging: // value for 'paging'
 *      lastQueryParams: // value for 'lastQueryParams'
 *      previousQueryParams: // value for 'previousQueryParams'
 *   },
 * });
 */
export function useServingDashboardCardQuery(
  baseOptions: Apollo.QueryHookOptions<ServingDashboardCardQuery, ServingDashboardCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServingDashboardCardQuery, ServingDashboardCardQueryVariables>(
    ServingDashboardCardDocument,
    options
  );
}
export function useServingDashboardCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ServingDashboardCardQuery, ServingDashboardCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServingDashboardCardQuery, ServingDashboardCardQueryVariables>(
    ServingDashboardCardDocument,
    options
  );
}
export type ServingDashboardCardQueryHookResult = ReturnType<typeof useServingDashboardCardQuery>;
export type ServingDashboardCardLazyQueryHookResult = ReturnType<typeof useServingDashboardCardLazyQuery>;
export type ServingDashboardCardQueryResult = Apollo.QueryResult<
  ServingDashboardCardQuery,
  ServingDashboardCardQueryVariables
>;
export const ServingDashboardTilesDocument = gql`
  query ServingDashboardTiles(
    $organizationKey: String!
    $lastServingSnapshotsInput: ServingSnapshotsInput!
    $previousServingSnapshotsInput: ServingSnapshotsInput!
  ) @feature(name: "engagement-analytics") {
    organizationServingSnapshots(organizationKey: $organizationKey) {
      LastData: snapshots(servingSnapshotsInput: $lastServingSnapshotsInput) {
        availableVolunteers
        servingOpportunities
        servingOpportunitiesUnfilled
      }
      PreviousData: snapshots(servingSnapshotsInput: $previousServingSnapshotsInput) {
        availableVolunteers
        servingOpportunities
        servingOpportunitiesUnfilled
      }
    }
  }
`;

/**
 * __useServingDashboardTilesQuery__
 *
 * To run a query within a React component, call `useServingDashboardTilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServingDashboardTilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServingDashboardTilesQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      lastServingSnapshotsInput: // value for 'lastServingSnapshotsInput'
 *      previousServingSnapshotsInput: // value for 'previousServingSnapshotsInput'
 *   },
 * });
 */
export function useServingDashboardTilesQuery(
  baseOptions: Apollo.QueryHookOptions<ServingDashboardTilesQuery, ServingDashboardTilesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServingDashboardTilesQuery, ServingDashboardTilesQueryVariables>(
    ServingDashboardTilesDocument,
    options
  );
}
export function useServingDashboardTilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ServingDashboardTilesQuery, ServingDashboardTilesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServingDashboardTilesQuery, ServingDashboardTilesQueryVariables>(
    ServingDashboardTilesDocument,
    options
  );
}
export type ServingDashboardTilesQueryHookResult = ReturnType<typeof useServingDashboardTilesQuery>;
export type ServingDashboardTilesLazyQueryHookResult = ReturnType<typeof useServingDashboardTilesLazyQuery>;
export type ServingDashboardTilesQueryResult = Apollo.QueryResult<
  ServingDashboardTilesQuery,
  ServingDashboardTilesQueryVariables
>;
export const GroupParticipationFilterValuesDocument = gql`
  query GroupParticipationFilterValues($organizationKey: String!) {
    groupParticipationFilterValues(organizationKey: $organizationKey) {
      campusNames
      departmentNames
      groupNames
      activeGroupNames
      inactiveGroups {
        groupName
        inactivatedDate
      }
      groupTypeNames
      membershipTypeIds
    }
  }
`;

/**
 * __useGroupParticipationFilterValuesQuery__
 *
 * To run a query within a React component, call `useGroupParticipationFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupParticipationFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupParticipationFilterValuesQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *   },
 * });
 */
export function useGroupParticipationFilterValuesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupParticipationFilterValuesQuery,
    GroupParticipationFilterValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupParticipationFilterValuesQuery, GroupParticipationFilterValuesQueryVariables>(
    GroupParticipationFilterValuesDocument,
    options
  );
}
export function useGroupParticipationFilterValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupParticipationFilterValuesQuery,
    GroupParticipationFilterValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupParticipationFilterValuesQuery, GroupParticipationFilterValuesQueryVariables>(
    GroupParticipationFilterValuesDocument,
    options
  );
}
export type GroupParticipationFilterValuesQueryHookResult = ReturnType<typeof useGroupParticipationFilterValuesQuery>;
export type GroupParticipationFilterValuesLazyQueryHookResult = ReturnType<
  typeof useGroupParticipationFilterValuesLazyQuery
>;
export type GroupParticipationFilterValuesQueryResult = Apollo.QueryResult<
  GroupParticipationFilterValuesQuery,
  GroupParticipationFilterValuesQueryVariables
>;
export const OrganizationGroupParticipationOverviewDocument = gql`
  query OrganizationGroupParticipationOverview(
    $organizationKey: String!
    $groupParticipationOverviewInput: GroupParticipationOverviewInput
    $comparisonDatesInput: ComparisonDatesInput
  ) {
    organizationGroupParticipationOverview(organizationKey: $organizationKey) {
      comparisonHighlight(
        groupParticipationOverviewInput: $groupParticipationOverviewInput
        comparisonDatesInput: $comparisonDatesInput
      ) {
        last
      }
      highlights(groupParticipationOverviewInput: $groupParticipationOverviewInput) {
        groupLeadersTotal
        groupMembersTotal
        groupParticipantHouseholdsTotal
        groupParticipantHouseholdsUnique
        groupParticipantsAvg
        groupParticipantsAvgMedian
        groupParticipantsTotal
        groupsTotal
      }
      data(groupParticipationOverviewInput: $groupParticipationOverviewInput) {
        ...OrganizationGroupParticipationOverviewData
      }
    }
  }
  ${OrganizationGroupParticipationOverviewDataFragmentDoc}
`;

/**
 * __useOrganizationGroupParticipationOverviewQuery__
 *
 * To run a query within a React component, call `useOrganizationGroupParticipationOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationGroupParticipationOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationGroupParticipationOverviewQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      groupParticipationOverviewInput: // value for 'groupParticipationOverviewInput'
 *      comparisonDatesInput: // value for 'comparisonDatesInput'
 *   },
 * });
 */
export function useOrganizationGroupParticipationOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizationGroupParticipationOverviewQuery,
    OrganizationGroupParticipationOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganizationGroupParticipationOverviewQuery,
    OrganizationGroupParticipationOverviewQueryVariables
  >(OrganizationGroupParticipationOverviewDocument, options);
}
export function useOrganizationGroupParticipationOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationGroupParticipationOverviewQuery,
    OrganizationGroupParticipationOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationGroupParticipationOverviewQuery,
    OrganizationGroupParticipationOverviewQueryVariables
  >(OrganizationGroupParticipationOverviewDocument, options);
}
export type OrganizationGroupParticipationOverviewQueryHookResult = ReturnType<
  typeof useOrganizationGroupParticipationOverviewQuery
>;
export type OrganizationGroupParticipationOverviewLazyQueryHookResult = ReturnType<
  typeof useOrganizationGroupParticipationOverviewLazyQuery
>;
export type OrganizationGroupParticipationOverviewQueryResult = Apollo.QueryResult<
  OrganizationGroupParticipationOverviewQuery,
  OrganizationGroupParticipationOverviewQueryVariables
>;
export const OrganizationGroupParticipationOverviewWithPreviousDocument = gql`
  query OrganizationGroupParticipationOverviewWithPrevious(
    $organizationKey: String!
    $groupParticipationOverviewInput: GroupParticipationOverviewInput
    $groupParticipationOverviewInputPrevious: GroupParticipationOverviewInput
    $comparisonDatesInput: ComparisonDatesInput
  ) {
    organizationGroupParticipationOverview(organizationKey: $organizationKey) {
      comparisonHighlight(
        groupParticipationOverviewInput: $groupParticipationOverviewInput
        comparisonDatesInput: $comparisonDatesInput
      ) {
        last
        previous
      }
      highlights(groupParticipationOverviewInput: $groupParticipationOverviewInput) {
        groupLeadersTotal
        groupMembersTotal
        groupParticipantHouseholdsTotal
        groupParticipantHouseholdsUnique
        groupParticipantsAvg
        groupParticipantsAvgMedian
        groupParticipantsTotal
        groupsTotal
      }
      data(groupParticipationOverviewInput: $groupParticipationOverviewInput) {
        ...OrganizationGroupParticipationOverviewData
      }
      previousData: data(groupParticipationOverviewInput: $groupParticipationOverviewInputPrevious) {
        ...OrganizationGroupParticipationOverviewData
      }
    }
  }
  ${OrganizationGroupParticipationOverviewDataFragmentDoc}
`;

/**
 * __useOrganizationGroupParticipationOverviewWithPreviousQuery__
 *
 * To run a query within a React component, call `useOrganizationGroupParticipationOverviewWithPreviousQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationGroupParticipationOverviewWithPreviousQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationGroupParticipationOverviewWithPreviousQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      groupParticipationOverviewInput: // value for 'groupParticipationOverviewInput'
 *      groupParticipationOverviewInputPrevious: // value for 'groupParticipationOverviewInputPrevious'
 *      comparisonDatesInput: // value for 'comparisonDatesInput'
 *   },
 * });
 */
export function useOrganizationGroupParticipationOverviewWithPreviousQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizationGroupParticipationOverviewWithPreviousQuery,
    OrganizationGroupParticipationOverviewWithPreviousQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganizationGroupParticipationOverviewWithPreviousQuery,
    OrganizationGroupParticipationOverviewWithPreviousQueryVariables
  >(OrganizationGroupParticipationOverviewWithPreviousDocument, options);
}
export function useOrganizationGroupParticipationOverviewWithPreviousLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationGroupParticipationOverviewWithPreviousQuery,
    OrganizationGroupParticipationOverviewWithPreviousQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationGroupParticipationOverviewWithPreviousQuery,
    OrganizationGroupParticipationOverviewWithPreviousQueryVariables
  >(OrganizationGroupParticipationOverviewWithPreviousDocument, options);
}
export type OrganizationGroupParticipationOverviewWithPreviousQueryHookResult = ReturnType<
  typeof useOrganizationGroupParticipationOverviewWithPreviousQuery
>;
export type OrganizationGroupParticipationOverviewWithPreviousLazyQueryHookResult = ReturnType<
  typeof useOrganizationGroupParticipationOverviewWithPreviousLazyQuery
>;
export type OrganizationGroupParticipationOverviewWithPreviousQueryResult = Apollo.QueryResult<
  OrganizationGroupParticipationOverviewWithPreviousQuery,
  OrganizationGroupParticipationOverviewWithPreviousQueryVariables
>;
export const ExportJobsDocument = gql`
  query ExportJobs($organizationKey: String!) {
    exportJobs(organizationKey: $organizationKey) {
      createdAt
      exportJobOptions {
        searchTerm
        sort {
          columnName
          direction
        }
        type {
          attenderListType
          donorListType
          volunteerListType
        }
        viewBy
      }
      filename
      exportLink
      exportSizeBytes
      jobKey
      status
    }
  }
`;

/**
 * __useExportJobsQuery__
 *
 * To run a query within a React component, call `useExportJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportJobsQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *   },
 * });
 */
export function useExportJobsQuery(baseOptions: Apollo.QueryHookOptions<ExportJobsQuery, ExportJobsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExportJobsQuery, ExportJobsQueryVariables>(ExportJobsDocument, options);
}
export function useExportJobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExportJobsQuery, ExportJobsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExportJobsQuery, ExportJobsQueryVariables>(ExportJobsDocument, options);
}
export type ExportJobsQueryHookResult = ReturnType<typeof useExportJobsQuery>;
export type ExportJobsLazyQueryHookResult = ReturnType<typeof useExportJobsLazyQuery>;
export type ExportJobsQueryResult = Apollo.QueryResult<ExportJobsQuery, ExportJobsQueryVariables>;
export const InsightsExportPingDocument = gql`
  query InsightsExportPing($organizationKey: String!) {
    insightsExportPing(organizationKey: $organizationKey)
  }
`;

/**
 * __useInsightsExportPingQuery__
 *
 * To run a query within a React component, call `useInsightsExportPingQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsExportPingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsExportPingQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *   },
 * });
 */
export function useInsightsExportPingQuery(
  baseOptions: Apollo.QueryHookOptions<InsightsExportPingQuery, InsightsExportPingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InsightsExportPingQuery, InsightsExportPingQueryVariables>(
    InsightsExportPingDocument,
    options
  );
}
export function useInsightsExportPingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InsightsExportPingQuery, InsightsExportPingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InsightsExportPingQuery, InsightsExportPingQueryVariables>(
    InsightsExportPingDocument,
    options
  );
}
export type InsightsExportPingQueryHookResult = ReturnType<typeof useInsightsExportPingQuery>;
export type InsightsExportPingLazyQueryHookResult = ReturnType<typeof useInsightsExportPingLazyQuery>;
export type InsightsExportPingQueryResult = Apollo.QueryResult<
  InsightsExportPingQuery,
  InsightsExportPingQueryVariables
>;
export const OrganizationGivingSummariesByPeriodDocument = gql`
  query OrganizationGivingSummariesByPeriod(
    $organizationKey: String!
    $currentTimeRange: GranularTimeRangeInput!
    $previousTimeRange: GranularTimeRangeInput!
    $organizationQueryFilter: OrganizationQueryFilterInput!
    $disablePQS: Boolean!
  ) @feature(name: "engagement-analytics") {
    organization(organizationKey: $organizationKey) {
      current: organizationGivingSummariesByPeriod(
        organizationQueryFilter: $organizationQueryFilter
        timeRange: $currentTimeRange
      ) @skip(if: $disablePQS) {
        ...OrganizationGivingPeriodSummary
      }
      previous: organizationGivingSummariesByPeriod(
        organizationQueryFilter: $organizationQueryFilter
        timeRange: $previousTimeRange
      ) @skip(if: $disablePQS) {
        ...OrganizationGivingPeriodSummary
      }
    }
  }
  ${OrganizationGivingPeriodSummaryFragmentDoc}
`;

/**
 * __useOrganizationGivingSummariesByPeriodQuery__
 *
 * To run a query within a React component, call `useOrganizationGivingSummariesByPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationGivingSummariesByPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationGivingSummariesByPeriodQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      currentTimeRange: // value for 'currentTimeRange'
 *      previousTimeRange: // value for 'previousTimeRange'
 *      organizationQueryFilter: // value for 'organizationQueryFilter'
 *      disablePQS: // value for 'disablePQS'
 *   },
 * });
 */
export function useOrganizationGivingSummariesByPeriodQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizationGivingSummariesByPeriodQuery,
    OrganizationGivingSummariesByPeriodQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationGivingSummariesByPeriodQuery, OrganizationGivingSummariesByPeriodQueryVariables>(
    OrganizationGivingSummariesByPeriodDocument,
    options
  );
}
export function useOrganizationGivingSummariesByPeriodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationGivingSummariesByPeriodQuery,
    OrganizationGivingSummariesByPeriodQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationGivingSummariesByPeriodQuery,
    OrganizationGivingSummariesByPeriodQueryVariables
  >(OrganizationGivingSummariesByPeriodDocument, options);
}
export type OrganizationGivingSummariesByPeriodQueryHookResult = ReturnType<
  typeof useOrganizationGivingSummariesByPeriodQuery
>;
export type OrganizationGivingSummariesByPeriodLazyQueryHookResult = ReturnType<
  typeof useOrganizationGivingSummariesByPeriodLazyQuery
>;
export type OrganizationGivingSummariesByPeriodQueryResult = Apollo.QueryResult<
  OrganizationGivingSummariesByPeriodQuery,
  OrganizationGivingSummariesByPeriodQueryVariables
>;
export const OrganizationGivingSummariesByPeriodNewDocument = gql`
  query organizationGivingSummariesByPeriodNew(
    $organizationKey: String!
    $currentTimeRange: GranularTimeRangeInput!
    $previousTimeRange: GranularTimeRangeInput!
    $organizationQueryFilter: OrganizationQueryFilterInput!
    $disablePQS: Boolean!
  ) @feature(name: "engagement-analytics") {
    organization(organizationKey: $organizationKey) {
      current: organizationGivingSummariesByPeriodNew(
        organizationQueryFilter: $organizationQueryFilter
        timeRange: $currentTimeRange
      ) @skip(if: $disablePQS) {
        ...OrganizationGivingPeriodSummaryNew
      }
      previous: organizationGivingSummariesByPeriodNew(
        organizationQueryFilter: $organizationQueryFilter
        timeRange: $previousTimeRange
      ) @skip(if: $disablePQS) {
        ...OrganizationGivingPeriodSummaryNew
      }
    }
  }
  ${OrganizationGivingPeriodSummaryNewFragmentDoc}
`;

/**
 * __useOrganizationGivingSummariesByPeriodNewQuery__
 *
 * To run a query within a React component, call `useOrganizationGivingSummariesByPeriodNewQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationGivingSummariesByPeriodNewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationGivingSummariesByPeriodNewQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      currentTimeRange: // value for 'currentTimeRange'
 *      previousTimeRange: // value for 'previousTimeRange'
 *      organizationQueryFilter: // value for 'organizationQueryFilter'
 *      disablePQS: // value for 'disablePQS'
 *   },
 * });
 */
export function useOrganizationGivingSummariesByPeriodNewQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizationGivingSummariesByPeriodNewQuery,
    OrganizationGivingSummariesByPeriodNewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganizationGivingSummariesByPeriodNewQuery,
    OrganizationGivingSummariesByPeriodNewQueryVariables
  >(OrganizationGivingSummariesByPeriodNewDocument, options);
}
export function useOrganizationGivingSummariesByPeriodNewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationGivingSummariesByPeriodNewQuery,
    OrganizationGivingSummariesByPeriodNewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationGivingSummariesByPeriodNewQuery,
    OrganizationGivingSummariesByPeriodNewQueryVariables
  >(OrganizationGivingSummariesByPeriodNewDocument, options);
}
export type OrganizationGivingSummariesByPeriodNewQueryHookResult = ReturnType<
  typeof useOrganizationGivingSummariesByPeriodNewQuery
>;
export type OrganizationGivingSummariesByPeriodNewLazyQueryHookResult = ReturnType<
  typeof useOrganizationGivingSummariesByPeriodNewLazyQuery
>;
export type OrganizationGivingSummariesByPeriodNewQueryResult = Apollo.QueryResult<
  OrganizationGivingSummariesByPeriodNewQuery,
  OrganizationGivingSummariesByPeriodNewQueryVariables
>;
export const TopCommunityMemberDonorSummaryDocument = gql`
  query TopCommunityMemberDonorSummary(
    $organizationKey: String!
    $cursor: PQSCursorPagingInput!
    $queryFilter: TopCommunityMemberQueryFilterInput!
    $startDate: Instant!
    $endDate: Instant!
    $disablePQS: Boolean!
  ) @feature(name: "engagement-analytics") {
    organization(organizationKey: $organizationKey) {
      organizationCursoredTopCommunityMembers(cursor: $cursor, queryFilter: $queryFilter) @skip(if: $disablePQS) {
        paging {
          totalItemCount
          first
          hasNextPage
          hasPreviousPage
          last
          size
        }
        items {
          communityMemberKey
          communityMember(startDate: $startDate, endDate: $endDate) {
            communityMemberKey
            yearToDateLargestGiftFundKey
            ytdLargestGiftFund {
              name
            }
            lastGiftFundKey
            lastGiftFund {
              name
            }
            yearToDateLargestGiftMerchantIntegerId
            ytdLargestGiftMerchant {
              name
            }
            lastGiftMerchantIntegerId
            lastGiftMerchant {
              name
            }
            firstName
            lastName
            nickname
            ccbIndividual {
              individualId
            }
            ccbMembershipTypeId
            email {
              address
            }
            phoneNumber {
              number
            }
            createdOn
            attendanceStatus: tag(name: "pushpay:attendance_status", current: false)
            givingStatus: tag(name: "pushpay:giving_status", current: false)
            servingStatus: tag(name: "pushpay:serving_status", current: false)
            estimatedAverageMonthlyTotal: tag(name: "pushpay:estimated_average_monthly_total")
            lifeTimeGivingTotal: tag(name: "pushpay:lifetime_giving_amount")
            lastGiftAmount: tag(name: "pushpay:last_gift_amount")
            lastGiftDate: tag(name: "pushpay:last_gift_date")
            yearToDateGivingTotal: tag(name: "pushpay:year_to_date_giving_amount")
            yearToDateLargestGiftAmount: tag(name: "pushpay:year_to_date_largest_gift_amount")
            yearToDateLargestGiftDate: tag(name: "pushpay:year_to_date_largest_gift_date")
            profileImages {
              thumbnailUrl
            }
            campusKey
          }
          giftAmount {
            amount
          }
          nonCashCount
          paymentCount
        }
      }
    }
  }
`;

/**
 * __useTopCommunityMemberDonorSummaryQuery__
 *
 * To run a query within a React component, call `useTopCommunityMemberDonorSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopCommunityMemberDonorSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopCommunityMemberDonorSummaryQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      cursor: // value for 'cursor'
 *      queryFilter: // value for 'queryFilter'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      disablePQS: // value for 'disablePQS'
 *   },
 * });
 */
export function useTopCommunityMemberDonorSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    TopCommunityMemberDonorSummaryQuery,
    TopCommunityMemberDonorSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TopCommunityMemberDonorSummaryQuery, TopCommunityMemberDonorSummaryQueryVariables>(
    TopCommunityMemberDonorSummaryDocument,
    options
  );
}
export function useTopCommunityMemberDonorSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TopCommunityMemberDonorSummaryQuery,
    TopCommunityMemberDonorSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TopCommunityMemberDonorSummaryQuery, TopCommunityMemberDonorSummaryQueryVariables>(
    TopCommunityMemberDonorSummaryDocument,
    options
  );
}
export type TopCommunityMemberDonorSummaryQueryHookResult = ReturnType<typeof useTopCommunityMemberDonorSummaryQuery>;
export type TopCommunityMemberDonorSummaryLazyQueryHookResult = ReturnType<
  typeof useTopCommunityMemberDonorSummaryLazyQuery
>;
export type TopCommunityMemberDonorSummaryQueryResult = Apollo.QueryResult<
  TopCommunityMemberDonorSummaryQuery,
  TopCommunityMemberDonorSummaryQueryVariables
>;
export const TopHouseholdDonorSummaryDocument = gql`
  query TopHouseholdDonorSummary(
    $organizationKey: String!
    $cursor: PQSCursorPagingInput!
    $queryFilter: TopHouseholdQueryFilterInput!
    $startDate: Instant!
    $endDate: Instant!
    $disablePQS: Boolean!
  ) @feature(name: "engagement-analytics") {
    organization(organizationKey: $organizationKey) {
      organizationCursoredTopHouseholds(cursor: $cursor, queryFilter: $queryFilter) @skip(if: $disablePQS) {
        paging {
          totalItemCount
          first
          hasNextPage
          hasPreviousPage
          last
          size
        }
        items {
          householdKey
          household(startDate: $startDate, endDate: $endDate) {
            yearToDateLargestGiftFundKey
            ytdLargestGiftFund {
              name
            }
            lastGiftFundKey
            lastGiftFund {
              name
            }
            yearToDateLargestGiftMerchantIntegerId
            ytdLargestGiftMerchant {
              name
            }
            lastGiftMerchantIntegerId
            lastGiftMerchant {
              name
            }
            householdKey
            campusKey
            listName
            name
            numberOfMembers
            members {
              communityMember {
                ccbIndividual {
                  individualId
                }
              }
              primary
            }
            email {
              address
            }
            phoneNumber: phone {
              number
            }
            createdOn
            attendanceStatus: tag(name: "pushpay:attendance_status", current: false)
            givingStatus: tag(name: "pushpay:giving_status", current: false)
            servingStatus: tag(name: "pushpay:serving_status", current: false)
            estimatedAverageMonthlyTotal: tag(name: "pushpay:estimated_average_monthly_total")
            lifeTimeGivingTotal: tag(name: "pushpay:lifetime_giving_amount")
            lastGiftAmount: tag(name: "pushpay:last_gift_amount")
            lastGiftDate: tag(name: "pushpay:last_gift_date")
            yearToDateGivingTotal: tag(name: "pushpay:year_to_date_giving_amount")
            yearToDateLargestGiftAmount: tag(name: "pushpay:year_to_date_largest_gift_amount")
            yearToDateLargestGiftDate: tag(name: "pushpay:year_to_date_largest_gift_date")
          }
          giftAmount {
            amount
          }
          nonCashCount
          paymentCount
        }
      }
    }
  }
`;

/**
 * __useTopHouseholdDonorSummaryQuery__
 *
 * To run a query within a React component, call `useTopHouseholdDonorSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopHouseholdDonorSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopHouseholdDonorSummaryQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      cursor: // value for 'cursor'
 *      queryFilter: // value for 'queryFilter'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      disablePQS: // value for 'disablePQS'
 *   },
 * });
 */
export function useTopHouseholdDonorSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<TopHouseholdDonorSummaryQuery, TopHouseholdDonorSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TopHouseholdDonorSummaryQuery, TopHouseholdDonorSummaryQueryVariables>(
    TopHouseholdDonorSummaryDocument,
    options
  );
}
export function useTopHouseholdDonorSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TopHouseholdDonorSummaryQuery, TopHouseholdDonorSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TopHouseholdDonorSummaryQuery, TopHouseholdDonorSummaryQueryVariables>(
    TopHouseholdDonorSummaryDocument,
    options
  );
}
export type TopHouseholdDonorSummaryQueryHookResult = ReturnType<typeof useTopHouseholdDonorSummaryQuery>;
export type TopHouseholdDonorSummaryLazyQueryHookResult = ReturnType<typeof useTopHouseholdDonorSummaryLazyQuery>;
export type TopHouseholdDonorSummaryQueryResult = Apollo.QueryResult<
  TopHouseholdDonorSummaryQuery,
  TopHouseholdDonorSummaryQueryVariables
>;
export const ProcessesDocument = gql`
  query Processes($organizationKey: String!, $paging: chms_CursorPagingInput!) @feature(name: "engagement-analytics") {
    organization(organizationKey: $organizationKey) {
      processes(paging: $paging) {
        items {
          id
          name
          campus {
            id
            name
          }
        }
        paging {
          hasNextPage
          last
        }
      }
    }
  }
`;

/**
 * __useProcessesQuery__
 *
 * To run a query within a React component, call `useProcessesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcessesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcessesQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useProcessesQuery(baseOptions: Apollo.QueryHookOptions<ProcessesQuery, ProcessesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProcessesQuery, ProcessesQueryVariables>(ProcessesDocument, options);
}
export function useProcessesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProcessesQuery, ProcessesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProcessesQuery, ProcessesQueryVariables>(ProcessesDocument, options);
}
export type ProcessesQueryHookResult = ReturnType<typeof useProcessesQuery>;
export type ProcessesLazyQueryHookResult = ReturnType<typeof useProcessesLazyQuery>;
export type ProcessesQueryResult = Apollo.QueryResult<ProcessesQuery, ProcessesQueryVariables>;
export const QueuesByProcessIdDocument = gql`
  query QueuesByProcessId($organizationKey: String!, $processId: Int!, $paging: chms_CursorPagingInput!)
  @feature(name: "engagement-analytics") {
    organization(organizationKey: $organizationKey) {
      queues(paging: $paging, processQueueSearchInput: { processId: $processId }) {
        items {
          id
          name
        }
        paging {
          hasNextPage
          last
        }
      }
    }
  }
`;

/**
 * __useQueuesByProcessIdQuery__
 *
 * To run a query within a React component, call `useQueuesByProcessIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueuesByProcessIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueuesByProcessIdQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      processId: // value for 'processId'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useQueuesByProcessIdQuery(
  baseOptions: Apollo.QueryHookOptions<QueuesByProcessIdQuery, QueuesByProcessIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QueuesByProcessIdQuery, QueuesByProcessIdQueryVariables>(QueuesByProcessIdDocument, options);
}
export function useQueuesByProcessIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QueuesByProcessIdQuery, QueuesByProcessIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QueuesByProcessIdQuery, QueuesByProcessIdQueryVariables>(
    QueuesByProcessIdDocument,
    options
  );
}
export type QueuesByProcessIdQueryHookResult = ReturnType<typeof useQueuesByProcessIdQuery>;
export type QueuesByProcessIdLazyQueryHookResult = ReturnType<typeof useQueuesByProcessIdLazyQuery>;
export type QueuesByProcessIdQueryResult = Apollo.QueryResult<QueuesByProcessIdQuery, QueuesByProcessIdQueryVariables>;
export const OrganizationServingOverviewDocument = gql`
  query OrganizationServingOverview(
    $organizationKey: String!
    $servingOverviewInput: ServingOverviewInput
    $comparisonDatesInput: ComparisonDatesInput
  ) {
    organizationServingOverview(organizationKey: $organizationKey) {
      comparisonHighlight(servingOverviewInput: $servingOverviewInput, comparisonDatesInput: $comparisonDatesInput) {
        last
      }
      highlights(servingOverviewInput: $servingOverviewInput) {
        uniqueIndividuals
        uniqueHouseholds
        uniqueTeams
        uniquePositions
        averageServingOpportunities
      }
      data(servingOverviewInput: $servingOverviewInput) {
        ...OrganizationServingOverviewData
      }
    }
  }
  ${OrganizationServingOverviewDataFragmentDoc}
`;

/**
 * __useOrganizationServingOverviewQuery__
 *
 * To run a query within a React component, call `useOrganizationServingOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationServingOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationServingOverviewQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      servingOverviewInput: // value for 'servingOverviewInput'
 *      comparisonDatesInput: // value for 'comparisonDatesInput'
 *   },
 * });
 */
export function useOrganizationServingOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<OrganizationServingOverviewQuery, OrganizationServingOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationServingOverviewQuery, OrganizationServingOverviewQueryVariables>(
    OrganizationServingOverviewDocument,
    options
  );
}
export function useOrganizationServingOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganizationServingOverviewQuery, OrganizationServingOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationServingOverviewQuery, OrganizationServingOverviewQueryVariables>(
    OrganizationServingOverviewDocument,
    options
  );
}
export type OrganizationServingOverviewQueryHookResult = ReturnType<typeof useOrganizationServingOverviewQuery>;
export type OrganizationServingOverviewLazyQueryHookResult = ReturnType<typeof useOrganizationServingOverviewLazyQuery>;
export type OrganizationServingOverviewQueryResult = Apollo.QueryResult<
  OrganizationServingOverviewQuery,
  OrganizationServingOverviewQueryVariables
>;
export const OrganizationServingOverviewWithPreviousDocument = gql`
  query OrganizationServingOverviewWithPrevious(
    $organizationKey: String!
    $servingOverviewInput: ServingOverviewInput
    $servingOverviewInputPrevious: ServingOverviewInput
    $comparisonDatesInput: ComparisonDatesInput
  ) {
    organizationServingOverview(organizationKey: $organizationKey) {
      comparisonHighlight(servingOverviewInput: $servingOverviewInput, comparisonDatesInput: $comparisonDatesInput) {
        previous
        last
      }
      highlights(servingOverviewInput: $servingOverviewInput) {
        uniqueIndividuals
        uniqueHouseholds
        uniqueTeams
        uniquePositions
        averageServingOpportunities
      }
      data(servingOverviewInput: $servingOverviewInput) {
        ...OrganizationServingOverviewData
      }
      previousData: data(servingOverviewInput: $servingOverviewInputPrevious) {
        ...OrganizationServingOverviewData
      }
    }
  }
  ${OrganizationServingOverviewDataFragmentDoc}
`;

/**
 * __useOrganizationServingOverviewWithPreviousQuery__
 *
 * To run a query within a React component, call `useOrganizationServingOverviewWithPreviousQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationServingOverviewWithPreviousQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationServingOverviewWithPreviousQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      servingOverviewInput: // value for 'servingOverviewInput'
 *      servingOverviewInputPrevious: // value for 'servingOverviewInputPrevious'
 *      comparisonDatesInput: // value for 'comparisonDatesInput'
 *   },
 * });
 */
export function useOrganizationServingOverviewWithPreviousQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrganizationServingOverviewWithPreviousQuery,
    OrganizationServingOverviewWithPreviousQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrganizationServingOverviewWithPreviousQuery,
    OrganizationServingOverviewWithPreviousQueryVariables
  >(OrganizationServingOverviewWithPreviousDocument, options);
}
export function useOrganizationServingOverviewWithPreviousLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationServingOverviewWithPreviousQuery,
    OrganizationServingOverviewWithPreviousQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrganizationServingOverviewWithPreviousQuery,
    OrganizationServingOverviewWithPreviousQueryVariables
  >(OrganizationServingOverviewWithPreviousDocument, options);
}
export type OrganizationServingOverviewWithPreviousQueryHookResult = ReturnType<
  typeof useOrganizationServingOverviewWithPreviousQuery
>;
export type OrganizationServingOverviewWithPreviousLazyQueryHookResult = ReturnType<
  typeof useOrganizationServingOverviewWithPreviousLazyQuery
>;
export type OrganizationServingOverviewWithPreviousQueryResult = Apollo.QueryResult<
  OrganizationServingOverviewWithPreviousQuery,
  OrganizationServingOverviewWithPreviousQueryVariables
>;
export const OrganizationServingSnapshotsDocument = gql`
  query OrganizationServingSnapshots($organizationKey: String!, $servingSnapshotsInput: ServingSnapshotsInput!)
  @feature(name: "engagement-analytics") {
    organizationServingSnapshots(organizationKey: $organizationKey) {
      snapshots(servingSnapshotsInput: $servingSnapshotsInput) {
        availableVolunteers
        servingOpportunities
        servingOpportunitiesUnfilled
      }
    }
  }
`;

/**
 * __useOrganizationServingSnapshotsQuery__
 *
 * To run a query within a React component, call `useOrganizationServingSnapshotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationServingSnapshotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationServingSnapshotsQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      servingSnapshotsInput: // value for 'servingSnapshotsInput'
 *   },
 * });
 */
export function useOrganizationServingSnapshotsQuery(
  baseOptions: Apollo.QueryHookOptions<OrganizationServingSnapshotsQuery, OrganizationServingSnapshotsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationServingSnapshotsQuery, OrganizationServingSnapshotsQueryVariables>(
    OrganizationServingSnapshotsDocument,
    options
  );
}
export function useOrganizationServingSnapshotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationServingSnapshotsQuery,
    OrganizationServingSnapshotsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationServingSnapshotsQuery, OrganizationServingSnapshotsQueryVariables>(
    OrganizationServingSnapshotsDocument,
    options
  );
}
export type OrganizationServingSnapshotsQueryHookResult = ReturnType<typeof useOrganizationServingSnapshotsQuery>;
export type OrganizationServingSnapshotsLazyQueryHookResult = ReturnType<
  typeof useOrganizationServingSnapshotsLazyQuery
>;
export type OrganizationServingSnapshotsQueryResult = Apollo.QueryResult<
  OrganizationServingSnapshotsQuery,
  OrganizationServingSnapshotsQueryVariables
>;
export const ServingFilterValuesDocument = gql`
  query ServingFilterValues($organizationKey: String!) {
    servingFilterValues(organizationKey: $organizationKey) {
      campusNames
      categoryNames
      positionNames
      teamNames
    }
  }
`;

/**
 * __useServingFilterValuesQuery__
 *
 * To run a query within a React component, call `useServingFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServingFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServingFilterValuesQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *   },
 * });
 */
export function useServingFilterValuesQuery(
  baseOptions: Apollo.QueryHookOptions<ServingFilterValuesQuery, ServingFilterValuesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ServingFilterValuesQuery, ServingFilterValuesQueryVariables>(
    ServingFilterValuesDocument,
    options
  );
}
export function useServingFilterValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ServingFilterValuesQuery, ServingFilterValuesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ServingFilterValuesQuery, ServingFilterValuesQueryVariables>(
    ServingFilterValuesDocument,
    options
  );
}
export type ServingFilterValuesQueryHookResult = ReturnType<typeof useServingFilterValuesQuery>;
export type ServingFilterValuesLazyQueryHookResult = ReturnType<typeof useServingFilterValuesLazyQuery>;
export type ServingFilterValuesQueryResult = Apollo.QueryResult<
  ServingFilterValuesQuery,
  ServingFilterValuesQueryVariables
>;
export const MeDocument = gql`
  query Me @critical @feature(name: "shell") {
    me {
      ...Me
    }
  }
  ${MeFragmentDoc}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MyOrganizationDocument = gql`
  query MyOrganization($organizationKey: String!) @critical @feature(name: "shell") {
    organization(organizationKey: $organizationKey) {
      key
      membershipTypes {
        id
        name
      }
      details {
        productInformation {
          chmsInformation {
            tenantUrl
            isProvisioned
          }
          streamingInformation {
            id
          }
        }
        campuses {
          key
          name
        }
        name
        ecgId
        entitlements {
          key
          displayName
        }
        catholicEntity
        catholicSoftwareStatus
        isGivingPlatformActive
        isMasAppPresent
        isSalesforceCustomAppPresent
      }
      ...FeatureFlags
    }
  }
  ${FeatureFlagsFragmentDoc}
`;

/**
 * __useMyOrganizationQuery__
 *
 * To run a query within a React component, call `useMyOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOrganizationQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *   },
 * });
 */
export function useMyOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<MyOrganizationQuery, MyOrganizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyOrganizationQuery, MyOrganizationQueryVariables>(MyOrganizationDocument, options);
}
export function useMyOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyOrganizationQuery, MyOrganizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyOrganizationQuery, MyOrganizationQueryVariables>(MyOrganizationDocument, options);
}
export type MyOrganizationQueryHookResult = ReturnType<typeof useMyOrganizationQuery>;
export type MyOrganizationLazyQueryHookResult = ReturnType<typeof useMyOrganizationLazyQuery>;
export type MyOrganizationQueryResult = Apollo.QueryResult<MyOrganizationQuery, MyOrganizationQueryVariables>;
export const ValidatePendoGuideDocument = gql`
  query ValidatePendoGuide($hash: String!) @feature(name: "shell") {
    isGuideValid(hash: $hash)
  }
`;

/**
 * __useValidatePendoGuideQuery__
 *
 * To run a query within a React component, call `useValidatePendoGuideQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidatePendoGuideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidatePendoGuideQuery({
 *   variables: {
 *      hash: // value for 'hash'
 *   },
 * });
 */
export function useValidatePendoGuideQuery(
  baseOptions: Apollo.QueryHookOptions<ValidatePendoGuideQuery, ValidatePendoGuideQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ValidatePendoGuideQuery, ValidatePendoGuideQueryVariables>(
    ValidatePendoGuideDocument,
    options
  );
}
export function useValidatePendoGuideLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ValidatePendoGuideQuery, ValidatePendoGuideQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ValidatePendoGuideQuery, ValidatePendoGuideQueryVariables>(
    ValidatePendoGuideDocument,
    options
  );
}
export type ValidatePendoGuideQueryHookResult = ReturnType<typeof useValidatePendoGuideQuery>;
export type ValidatePendoGuideLazyQueryHookResult = ReturnType<typeof useValidatePendoGuideLazyQuery>;
export type ValidatePendoGuideQueryResult = Apollo.QueryResult<
  ValidatePendoGuideQuery,
  ValidatePendoGuideQueryVariables
>;

export enum TypeName {
  Boolean = 'Boolean',
  DateTime = 'DateTime',
  FileUpload = 'FileUpload',
  Float = 'Float',
  GUID = 'GUID',
  ID = 'ID',
  IdentityKey = 'IdentityKey',
  Instant = 'Instant',
  Int = 'Int',
  JsonString = 'JsonString',
  Key = 'Key',
  LocalDate = 'LocalDate',
  Long = 'Long',
  Name = 'Name',
  Offset = 'Offset',
  OlsonTimeZone = 'OlsonTimeZone',
  String = 'String',
  WindowsTimeZone = 'WindowsTimeZone',
  Address = 'Address',
  AddressArguments = 'AddressArguments',
  AdministeredOrganization = 'AdministeredOrganization',
  Aggregations = 'Aggregations',
  AllIdentityPermissions = 'AllIdentityPermissions',
  Attendance = 'Attendance',
  AttendanceComparisonHighlight = 'AttendanceComparisonHighlight',
  AttendanceOverviewInput = 'AttendanceOverviewInput',
  AttendanceQueryFilters = 'AttendanceQueryFilters',
  AttendanceQueryFiltersOutput = 'AttendanceQueryFiltersOutput',
  Campus = 'Campus',
  CampusPermissions = 'CampusPermissions',
  CampusesPagedOutput = 'CampusesPagedOutput',
  CancelExportJobInput = 'CancelExportJobInput',
  CcbIndividual = 'CcbIndividual',
  CcbMembershipTypeFilterInput = 'CcbMembershipTypeFilterInput',
  ChMSOrganization = 'ChMSOrganization',
  ChmsInfo = 'ChmsInfo',
  ClientField = 'ClientField',
  Community = 'Community',
  CommunityMember = 'CommunityMember',
  CommunityMemberCollectionResult = 'CommunityMemberCollectionResult',
  CommunityMemberDetailsArguments = 'CommunityMemberDetailsArguments',
  CommunityMemberEngagement = 'CommunityMemberEngagement',
  CommunityMemberResult = 'CommunityMemberResult',
  ComparisonDatesInput = 'ComparisonDatesInput',
  CreateExportJobInput = 'CreateExportJobInput',
  CursorPagedOutputOfTopCommunityMember = 'CursorPagedOutputOfTopCommunityMember',
  CursorPagedOutputOfTopHousehold = 'CursorPagedOutputOfTopHousehold',
  CursorPagingInput = 'CursorPagingInput',
  CursorPagingOutput = 'CursorPagingOutput',
  CursorPagingOutputOfCommunityMember = 'CursorPagingOutputOfCommunityMember',
  CursorPagingOutputOfHousehold = 'CursorPagingOutputOfHousehold',
  DateRangeInput = 'DateRangeInput',
  DateStamp = 'DateStamp',
  DateStampArguments = 'DateStampArguments',
  DateStampOfBirth = 'DateStampOfBirth',
  DateStampOfBirthArguments = 'DateStampOfBirthArguments',
  DateStampOfDeath = 'DateStampOfDeath',
  DateStampOfDeathArguments = 'DateStampOfDeathArguments',
  DeleteExportJobInput = 'DeleteExportJobInput',
  Email = 'Email',
  EmailAddressValidationError = 'EmailAddressValidationError',
  EmailArguments = 'EmailArguments',
  EmailContact = 'EmailContact',
  Entitlement = 'Entitlement',
  EventAttendance = 'EventAttendance',
  EventAttendanceSummary = 'EventAttendanceSummary',
  EventAttendanceSummaryDataInput = 'EventAttendanceSummaryDataInput',
  EventStartTimeInput = 'EventStartTimeInput',
  ExportJob = 'ExportJob',
  ExportJobCanceledOutput = 'ExportJobCanceledOutput',
  ExportJobDeletedOutput = 'ExportJobDeletedOutput',
  ExportJobOptions = 'ExportJobOptions',
  ExportJobSort = 'ExportJobSort',
  ExportJobSortInput = 'ExportJobSortInput',
  ExportQueuedOutput = 'ExportQueuedOutput',
  ExportTypeInput = 'ExportTypeInput',
  ExportTypeOutput = 'ExportTypeOutput',
  ExternalId = 'ExternalId',
  FeatureFlag = 'FeatureFlag',
  Filter = 'Filter',
  FormerHouseholdMember = 'FormerHouseholdMember',
  FormerLastName = 'FormerLastName',
  FormerLastNameArguments = 'FormerLastNameArguments',
  Fund = 'Fund',
  GivingOrganization = 'GivingOrganization',
  GranularTimeRangeInput = 'GranularTimeRangeInput',
  GroupParticipationComparisonHighlight = 'GroupParticipationComparisonHighlight',
  GroupParticipationFilterInput = 'GroupParticipationFilterInput',
  GroupParticipationFilterValuesOutput = 'GroupParticipationFilterValuesOutput',
  GroupParticipationHighlights = 'GroupParticipationHighlights',
  GroupParticipationOverviewInput = 'GroupParticipationOverviewInput',
  GroupParticipationSuccessResult = 'GroupParticipationSuccessResult',
  GroupParticipationSummary = 'GroupParticipationSummary',
  Household = 'Household',
  HouseholdContact = 'HouseholdContact',
  HouseholdMember = 'HouseholdMember',
  HouseholdMoveOutStatus = 'HouseholdMoveOutStatus',
  HouseholdResult = 'HouseholdResult',
  Identity = 'Identity',
  ImageSizeInput = 'ImageSizeInput',
  InactiveGroup = 'InactiveGroup',
  MassAddIndividualsToProcessQueueResult = 'MassAddIndividualsToProcessQueueResult',
  MaxLengthValidationError = 'MaxLengthValidationError',
  MembershipType = 'MembershipType',
  MembershipTypeIdsInput = 'MembershipTypeIdsInput',
  MembershipTypeIdsOutput = 'MembershipTypeIdsOutput',
  Merchant = 'Merchant',
  MinLengthValidationError = 'MinLengthValidationError',
  Money = 'Money',
  MoneyAggregate = 'MoneyAggregate',
  Organization = 'Organization',
  OrganizationAttendanceOverview = 'OrganizationAttendanceOverview',
  OrganizationDetails = 'OrganizationDetails',
  OrganizationGivingPeriodSummariesNew = 'OrganizationGivingPeriodSummariesNew',
  OrganizationGivingPeriodSummariesResult = 'OrganizationGivingPeriodSummariesResult',
  OrganizationGivingPeriodSummary = 'OrganizationGivingPeriodSummary',
  OrganizationGivingPeriodSummaryNew = 'OrganizationGivingPeriodSummaryNew',
  OrganizationGroupParticipationOverview = 'OrganizationGroupParticipationOverview',
  OrganizationPermissions = 'OrganizationPermissions',
  OrganizationQueryFilterInput = 'OrganizationQueryFilterInput',
  OrganizationServingOverview = 'OrganizationServingOverview',
  OrganizationServingSnapshots = 'OrganizationServingSnapshots',
  PQSCursorPagingInput = 'PQSCursorPagingInput',
  PagingInput = 'PagingInput',
  PagingOutput = 'PagingOutput',
  Permission = 'Permission',
  PhoneNumber = 'PhoneNumber',
  PhoneNumberArguments = 'PhoneNumberArguments',
  Process = 'Process',
  ProcessQueue = 'ProcessQueue',
  ProcessQueueSearchInput = 'ProcessQueueSearchInput',
  ProcessQueuesPagedOutput = 'ProcessQueuesPagedOutput',
  ProcessSearchInput = 'ProcessSearchInput',
  ProcessesPagedOutput = 'ProcessesPagedOutput',
  ProductInfo = 'ProductInfo',
  ProfileImages = 'ProfileImages',
  QueryParams = 'QueryParams',
  RangeValidationError = 'RangeValidationError',
  RegularExpressionValidationError = 'RegularExpressionValidationError',
  RequiredValidationError = 'RequiredValidationError',
  ServingComparisonHighlight = 'ServingComparisonHighlight',
  ServingFilterValuesOutput = 'ServingFilterValuesOutput',
  ServingHighlights = 'ServingHighlights',
  ServingOpportunitySummary = 'ServingOpportunitySummary',
  ServingOverviewFiltersInput = 'ServingOverviewFiltersInput',
  ServingOverviewInput = 'ServingOverviewInput',
  ServingSnapshots = 'ServingSnapshots',
  ServingSnapshotsFiltersInput = 'ServingSnapshotsFiltersInput',
  ServingSnapshotsInput = 'ServingSnapshotsInput',
  ServingSummary = 'ServingSummary',
  SortingInput = 'SortingInput',
  StreamingInfo = 'StreamingInfo',
  StringLengthValidationError = 'StringLengthValidationError',
  SuccessResult = 'SuccessResult',
  TimeZone = 'TimeZone',
  TopCommunityMember = 'TopCommunityMember',
  TopCommunityMemberQueryFilterInput = 'TopCommunityMemberQueryFilterInput',
  TopHousehold = 'TopHousehold',
  TopHouseholdQueryFilterInput = 'TopHouseholdQueryFilterInput',
  UrlValidationError = 'UrlValidationError',
  ValidationError = 'ValidationError',
  ValidationErrors = 'ValidationErrors',
  chms_Campus = 'chms_Campus',
  chms_CursorPagingInput = 'chms_CursorPagingInput',
  group_participation_query_Mutation = 'group_participation_query_Mutation',
  payment_DateRangeInput = 'payment_DateRangeInput',
}

export const TypeMapper = {
  Address: {
    city: 'String',
    country: 'String',
    createdOn: 'Instant',
    line1: 'String',
    line2: 'String',
    postcode: 'String',
    primary: 'Boolean!',
    state: 'String',
    type: 'AddressType!',
  },

  AddressArguments: {
    city: 'String',
    country: 'String',
    line1: 'String',
    line2: 'String',
    postcode: 'String',
    primary: 'Boolean',
    state: 'String',
    type: 'AddressType',
  },

  AdministeredOrganization: {
    isMerchantAdmin: 'Boolean!',
    key: 'String!',
    logoUrl: 'String!',
    name: 'String!',
  },

  Aggregations: {
    avg: 'Float',
    count: 'Int',
    max: 'Float',
    min: 'Float',
    sum: 'Float',
  },

  AllIdentityPermissions: {
    organizationPermissions: '[OrganizationPermissions!]!',
    permissions: '[Permission!]!',
  },

  Attendance: {
    averageAttendance: 'Int',
    checkinAttendance: 'Int!',
    familyAttendance: 'Int!',
    firstTimeGuests: 'Int!',
    headCountAttendance: 'Int!',
    individualAttendance: 'Int!',
    servingAttendance: 'Int!',
    streamingAttendance: 'Int!',
    totalAttendance: 'Int!',
    uniqueFamilyAttendance: 'Int!',
    uniqueIndividualAttendance: 'Int!',
  },

  AttendanceComparisonHighlight: {
    last: 'Int!',
    previous: 'Int',
  },

  AttendanceOverviewInput: {
    attendanceQueryFilters: 'AttendanceQueryFilters',
    dateRange: 'DateRangeInput!',
    granularity: 'Granularity!',
  },

  AttendanceQueryFilters: {
    attendanceGroupingNames: '[String!]',
    attendanceType: '[AttendanceType!]',
    campusNames: '[String!]',
    departmentNames: '[String!]',
    eventDays: '[String!]',
    eventNames: '[String!]',
    eventStartTimes: 'EventStartTimeInput',
    groupNames: '[String!]',
    groupTypeNames: '[String!]',
    membershipTypeIds: 'MembershipTypeIdsInput',
  },

  AttendanceQueryFiltersOutput: {
    attendanceGroupingNames: '[String!]!',
    campusNames: '[String!]!',
    departmentNames: '[String!]!',
    eventNames: '[String!]!',
    groupNames: '[String!]!',
    groupTypeNames: '[String!]!',
    membershipTypeIds: '[Int!]!',
    streamingEventNames: '[String!]!',
  },

  Campus: {
    key: 'String!',
    name: 'String!',
  },

  CampusPermissions: {
    campusKey: 'Key!',
    permissions: '[Permission!]!',
  },

  CampusesPagedOutput: {
    items: '[chms_Campus!]!',
    paging: 'CursorPagingOutput!',
  },

  CancelExportJobInput: {
    jobKey: 'String!',
  },

  CcbIndividual: {
    deepLinkUrl: 'String!',
    individualId: 'Int!',
  },

  CcbMembershipTypeFilterInput: {
    ccbMembershipTypeIds: '[Long!]',
    filterMethod: 'FilterMethod!',
  },

  ChMSOrganization: {
    campuses: 'CampusesPagedOutput!',
    chmsOrgId: 'Int!',
    membershipTypes: '[MembershipType!]!',
    processes: 'ProcessesPagedOutput!',
    queues: 'ProcessQueuesPagedOutput!',
  },

  ChmsInfo: {
    contractStatus: 'String',
    id: 'ID',
    isProvisioned: 'Boolean!',
    tenantUrl: 'String',
  },

  ClientField: {
    field: 'String!',
  },

  Community: {
    ageOfIndependence: 'Int!',
    isInSync: 'Boolean!',
    isSyncEnabled: 'Boolean!',
    isSyncRunning: 'Boolean!',
  },

  CommunityMember: {
    address: 'Address',
    addresses: '[Address!]!',
    age: 'Int',
    ageCategory: 'AgeCategory',
    archived: 'Boolean!',
    archivedOn: 'Instant',
    campusKey: 'String',
    ccbIndividual: 'CcbIndividual',
    ccbMembershipTypeId: 'Int',
    community: 'Community!',
    communityMemberKey: 'String!',
    communityMemberType: 'CommunityMemberType',
    createdOn: 'Instant',
    dateOfBirth: 'DateStamp',
    dateOfDeath: 'DateStamp',
    dateStampOfBirth: 'DateStampOfBirth',
    dateStampOfDeath: 'DateStampOfDeath',
    deceased: 'Boolean!',
    deceasedSetOn: 'Instant',
    dependent: 'Boolean',
    detailsUpdatedOn: 'Instant',
    donor: 'Boolean',
    email: 'Email',
    emailAddress: 'String',
    emails: '[Email!]!',
    engagement: 'CommunityMemberEngagement!',
    externalIds: '[ExternalId!]!',
    externalReference: 'String',
    firstName: 'String',
    formerLastNames: '[FormerLastName!]!',
    gender: 'Gender',
    householdMoveOutStatus: 'HouseholdMoveOutStatus!',
    households: '[Household!]!',
    lastActiveOn: 'Instant',
    lastGiftFund: 'Fund',
    lastGiftFundKey: 'String',
    lastGiftMerchant: 'Merchant',
    lastGiftMerchantId: 'String',
    lastGiftMerchantIntegerId: 'Long',
    lastName: 'String',
    lastPaymentOn: 'Instant',
    legalFirstName: 'String',
    legalFullName: 'String',
    lifetimeLargestGiftFundKey: 'String',
    lifetimeLargestGiftMerchantId: 'String',
    middleNames: 'String',
    mobileNumber: 'String',
    nickname: 'String',
    phoneNumber: 'PhoneNumber',
    phoneNumbers: '[PhoneNumber!]!',
    preferredFirstName: 'String',
    preferredFullName: 'String',
    preferredFullNameWithNickname: 'String',
    preferredName: 'String',
    prefix: 'String',
    primaryCustomerInputExternalId: 'String',
    primaryHousehold: 'Household',
    profileCreatedOn: 'Instant',
    profileImages: 'ProfileImages',
    suffix: 'String',
    tag: 'String',
    timeZoneId: 'String',
    updatedOn: 'Instant',
    yearToDateLargestGiftFundKey: 'String',
    yearToDateLargestGiftMerchantId: 'String',
    yearToDateLargestGiftMerchantIntegerId: 'Long',
    ytdLargestGiftFund: 'Fund',
    ytdLargestGiftMerchant: 'Merchant',
  },

  CommunityMemberCollectionResult: {
    communityMembers: '[CommunityMember!]',
    success: 'Boolean',
  },

  CommunityMemberDetailsArguments: {
    addresses: '[AddressArguments!]!',
    age: 'Int',
    ageCategory: 'AgeCategory',
    dateOfBirth: 'DateStampArguments',
    dateOfDeath: 'DateStampArguments',
    dateStampOfBirth: 'DateStampOfBirthArguments',
    dateStampOfDeath: 'DateStampOfDeathArguments',
    deceased: 'Boolean',
    dependent: 'Boolean',
    emails: '[EmailArguments!]!',
    externalReference: 'String',
    formerLastNames: '[FormerLastNameArguments!]!',
    gender: 'Gender',
    lastName: 'String!',
    legalFirstName: 'String!',
    middleNames: 'String',
    nickname: 'String',
    phoneNumbers: '[PhoneNumberArguments!]!',
    preferredFirstName: 'String',
    prefix: 'String',
    primaryCustomerInputExternalId: 'String',
    suffix: 'String',
  },

  CommunityMemberEngagement: {
    isEngaged: 'Boolean!',
    lastActiveOn: 'Instant',
    lastAppActivity: 'Instant',
    lastPayment: 'Instant',
  },

  CommunityMemberResult: {
    communityMember: 'CommunityMember',
  },

  ComparisonDatesInput: {
    last: 'DateRangeInput!',
    previous: 'DateRangeInput',
  },

  CreateExportJobInput: {
    attendanceStatus: '[AttendanceStatus!]',
    campusKeys: '[ID!]',
    donorStatus: '[DonorStatus!]',
    membershipTypeIds: 'MembershipTypeIdsInput',
    searchTerm: 'String',
    sort: 'ExportJobSortInput',
    timeZoneId: 'String',
    type: 'ExportTypeInput!',
    viewBy: 'ExportJobViewBy!',
    volunteerStatus: '[VolunteerStatus!]',
  },

  CursorPagedOutputOfTopCommunityMember: {
    items: '[TopCommunityMember!]!',
    paging: 'CursorPagingOutput!',
  },

  CursorPagedOutputOfTopHousehold: {
    items: '[TopHousehold!]!',
    paging: 'CursorPagingOutput!',
  },

  CursorPagingInput: {
    after: 'String',
    before: 'String',
    size: 'Int!',
    sortings: '[SortingInput]',
  },

  CursorPagingOutput: {
    first: 'String',
    hasNextPage: 'Boolean!',
    hasPreviousPage: 'Boolean!',
    last: 'String',
    size: 'Int!',
    totalItemCount: 'Int!',
  },

  CursorPagingOutputOfCommunityMember: {
    aggregations: 'Aggregations!',
    items: '[CommunityMember!]!',
    paging: 'CursorPagingOutput!',
  },

  CursorPagingOutputOfHousehold: {
    aggregations: 'Aggregations!',
    items: '[Household!]!',
    paging: 'CursorPagingOutput!',
  },

  DateRangeInput: {
    endDate: 'DateTime!',
    startDate: 'DateTime!',
    timeZoneId: 'String!',
  },

  DateStamp: {
    day: 'Int',
    month: 'MonthOfYear',
    year: 'Int',
  },

  DateStampArguments: {
    day: 'Int',
    month: 'MonthOfYear',
    year: 'Int',
  },

  DateStampOfBirth: {
    day: 'Int!',
    month: 'MonthOfYear!',
    year: 'Int',
  },

  DateStampOfBirthArguments: {
    day: 'Int!',
    month: 'MonthOfYear!',
    year: 'Int',
  },

  DateStampOfDeath: {
    day: 'Int',
    month: 'MonthOfYear',
    year: 'Int!',
  },

  DateStampOfDeathArguments: {
    day: 'Int',
    month: 'MonthOfYear',
    year: 'Int!',
  },

  DeleteExportJobInput: {
    jobKey: 'String!',
  },

  Email: {
    address: 'String!',
    createdOn: 'Instant',
    primary: 'Boolean!',
    type: 'EmailType!',
    verified: 'Boolean!',
  },

  EmailAddressValidationError: {
    fieldPath: '[String!]!',
  },

  EmailArguments: {
    address: 'String!',
    primary: 'Boolean!',
    type: 'EmailType!',
  },

  EmailContact: {
    emailAddress: 'String',
    verified: 'Boolean',
  },

  Entitlement: {
    displayName: 'String!',
    key: 'EntitlementKey!',
  },

  EventAttendance: {
    attendance: 'Attendance!',
    attendanceGroupingName: 'String',
    campusName: 'String',
    departmentId: 'Int',
    departmentName: 'String',
    eventId: 'String',
    eventName: 'String!',
    groupId: 'Int',
    groupName: 'String',
    groupTypeName: 'String',
    membershipTypeIds: '[Int!]',
    occurrence: 'DateTime!',
  },

  EventAttendanceSummary: {
    attendanceGroupingNames: '[String!]!',
    attendanceSummary: 'Attendance!',
    campusNames: '[String!]!',
    departmentNames: '[String!]!',
    endDate: 'DateTime!',
    eventNames: '[String!]!',
    groupNames: '[String!]!',
    groupTypeNames: '[String!]!',
    membershipTypeIds: '[Int!]!',
    startDate: 'DateTime!',
    total: 'Int!',
  },

  EventAttendanceSummaryDataInput: {
    attendanceQueryFilters: 'AttendanceQueryFilters',
    dateRange: 'DateRangeInput!',
  },

  EventStartTimeInput: {
    maxTime: 'String',
    minTime: 'String',
    operator: 'TimeFilterOperator!',
    timeZoneId: 'String!',
    times: '[String!]',
  },

  ExportJob: {
    createdAt: 'Float!',
    exportJobOptions: 'ExportJobOptions!',
    exportLink: 'String',
    exportSizeBytes: 'Float',
    filename: 'String!',
    jobKey: 'String!',
    status: 'ExportJobStatus!',
  },

  ExportJobCanceledOutput: {
    export: 'ExportJob!',
  },

  ExportJobDeletedOutput: {
    export: 'ExportJob!',
  },

  ExportJobOptions: {
    attendanceStatus: '[AttendanceStatus!]',
    campusKeys: '[ID!]',
    donorStatus: '[DonorStatus!]',
    membershipTypeIds: 'MembershipTypeIdsOutput',
    searchTerm: 'String',
    sort: 'ExportJobSort',
    type: 'ExportTypeOutput!',
    viewBy: 'ExportJobViewBy!',
    volunteerStatus: '[VolunteerStatus!]',
  },

  ExportJobSort: {
    columnName: 'String!',
    direction: 'String!',
  },

  ExportJobSortInput: {
    columnName: 'ExportJobSortColumns!',
    direction: 'ExportJobSortDirection!',
  },

  ExportQueuedOutput: {
    export: 'ExportJob!',
  },

  ExportTypeInput: {
    attenderListType: 'AttenderListType',
    donorListType: 'DonorListType',
    volunteerListType: 'VolunteerListType',
  },

  ExportTypeOutput: {
    attenderListType: 'AttenderListType',
    donorListType: 'DonorListType',
    volunteerListType: 'VolunteerListType',
  },

  ExternalId: {
    createdOn: 'Instant!',
    primary: 'Boolean!',
    type: 'ExternalIdType!',
    value: 'String!',
  },

  FeatureFlag: {
    enabled: 'Boolean!',
    name: 'String!',
  },

  Filter: {
    applyWithin: 'Boolean',
    field: 'String!',
    isEmpty: 'Boolean',
    max: 'String',
    min: 'String',
    value: 'String',
    values: '[String!]',
  },

  FormerHouseholdMember: {
    communityMember: 'CommunityMember!',
    createdOn: 'Instant!',
    formerHouseholdMemberPosition: 'HouseholdMemberPosition!',
  },

  FormerLastName: {
    changedAwayOn: 'Instant',
    createdOn: 'Instant!',
    lastName: 'String!',
  },

  FormerLastNameArguments: {
    changedAwayOn: 'Instant',
    lastName: 'String!',
  },

  Fund: {
    name: 'String',
  },

  GivingOrganization: {
    fund: 'Fund!',
    merchant: 'Merchant!',
  },

  GranularTimeRangeInput: {
    fromDate: 'String!',
    granularity: 'PaymentQueryGranularity!',
    startDay: 'StartOfWeek!',
    timeZoneId: 'String',
    toDate: 'String!',
  },

  GroupParticipationComparisonHighlight: {
    last: 'Int!',
    previous: 'Int',
  },

  GroupParticipationFilterInput: {
    campusNames: '[String!]',
    departmentNames: '[String!]',
    groupInteractionTypes: '[String!]',
    groupNames: '[String!]',
    groupTypeNames: '[String!]',
    membershipTypeIds: 'MembershipTypeIdsInput',
  },

  GroupParticipationFilterValuesOutput: {
    activeGroupNames: '[String!]!',
    campusNames: '[String!]!',
    departmentNames: '[String!]!',
    groupNames: '[String!]!',
    groupTypeNames: '[String!]!',
    inactiveGroups: '[InactiveGroup!]!',
    membershipTypeIds: '[Int!]!',
  },

  GroupParticipationHighlights: {
    groupLeadersTotal: 'Int!',
    groupMembersTotal: 'Int!',
    groupParticipantHouseholdsTotal: 'Int!',
    groupParticipantHouseholdsUnique: 'Int!',
    groupParticipantsAvg: 'Int!',
    groupParticipantsAvgMedian: 'Int!',
    groupParticipantsTotal: 'Int!',
    groupsTotal: 'Int!',
  },

  GroupParticipationOverviewInput: {
    dateRange: 'DateRangeInput!',
    filters: 'GroupParticipationFilterInput',
    granularity: 'Granularity!',
  },

  GroupParticipationSuccessResult: {
    success: 'Boolean!',
  },

  GroupParticipationSummary: {
    campusNames: '[String!]!',
    departmentNames: '[String!]!',
    endDate: 'DateTime!',
    groupNames: '[String!]!',
    groupTypeNames: '[String!]!',
    membershipTypeIds: '[String!]!',
    startDate: 'DateTime!',
    total: 'Int!',
  },

  Household: {
    address: 'Address',
    archived: 'Boolean!',
    archivedOn: 'Instant',
    campusKey: 'String',
    community: 'Community!',
    createdOn: 'Instant!',
    donor: 'Boolean!',
    email: 'Email',
    externalIds: '[ExternalId!]!',
    formerMembers: '[FormerHouseholdMember!]!',
    householdKey: 'String!',
    lastGiftFund: 'Fund',
    lastGiftFundKey: 'String',
    lastGiftMerchant: 'Merchant',
    lastGiftMerchantId: 'String',
    lastGiftMerchantIntegerId: 'Long',
    lifetimeLargestGiftFundKey: 'String',
    lifetimeLargestGiftMerchantId: 'String',
    listName: 'String!',
    members: '[HouseholdMember!]!',
    name: 'String!',
    numberOfMembers: 'Int!',
    phone: 'PhoneNumber',
    primaryContact: 'HouseholdContact',
    secondaryContact: 'HouseholdContact',
    tag: 'String',
    updatedOn: 'Instant!',
    yearToDateLargestGiftFundKey: 'String',
    yearToDateLargestGiftMerchantId: 'String',
    yearToDateLargestGiftMerchantIntegerId: 'Long',
    yourHouseholdId: 'String',
    ytdLargestGiftFund: 'Fund',
    ytdLargestGiftMerchant: 'Merchant',
  },

  HouseholdContact: {
    firstName: 'String',
    lastName: 'String',
  },

  HouseholdMember: {
    communityMember: 'CommunityMember!',
    createdOn: 'Instant!',
    household: 'Household!',
    householdKey: 'String!',
    position: 'HouseholdMemberPosition!',
    primary: 'Boolean!',
    updatedOn: 'Instant!',
  },

  HouseholdMoveOutStatus: {
    moveOutAttempted: 'Boolean!',
    moveOutDate: 'Instant',
  },

  HouseholdResult: {
    household: 'Household',
  },

  Identity: {
    accountType: 'AccountType',
    administeredOrganizations: '[AdministeredOrganization!]',
    allPermissions: 'AllIdentityPermissions',
    createdOn: 'String',
    emailAddresses: '[EmailContact]!',
    firstName: 'String',
    identityKey: 'IdentityKey!',
    lastName: 'String',
    olsonTimeZone: 'OlsonTimeZone',
    primaryEmailAddress: 'EmailContact',
    timeZoneId: 'WindowsTimeZone',
  },

  ImageSizeInput: {
    height: 'Int!',
    width: 'Int!',
  },

  InactiveGroup: {
    groupName: 'String!',
    inactivatedDate: 'String!',
  },

  MassAddIndividualsToProcessQueueResult: {
    message: 'String',
    status: 'String!',
    success: 'Boolean!',
  },

  MaxLengthValidationError: {
    fieldPath: '[String!]!',
  },

  MembershipType: {
    id: 'ID!',
    name: 'String!',
  },

  MembershipTypeIdsInput: {
    isEmpty: 'Boolean',
    values: '[Int!]!',
  },

  MembershipTypeIdsOutput: {
    isEmpty: 'Boolean!',
    values: '[Int!]!',
  },

  Merchant: {
    name: 'String!',
  },

  MinLengthValidationError: {
    fieldPath: '[String!]!',
  },

  Money: {
    amount: 'String!',
    currency: 'Currency!',
  },

  MoneyAggregate: {
    count: 'Int!',
    totalValue: 'Money!',
  },

  Organization: {
    communityMembersCursor: 'CursorPagingOutputOfCommunityMember',
    details: 'OrganizationDetails',
    featureFlags: '[FeatureFlag!]!',
    fund: 'Fund!',
    households: 'CursorPagingOutputOfHousehold',
    key: 'String!',
    membershipTypes: '[MembershipType!]',
    organizationCursoredTopCommunityMembers: 'CursorPagedOutputOfTopCommunityMember',
    organizationCursoredTopHouseholds: 'CursorPagedOutputOfTopHousehold',
    organizationGivingSummariesByPeriod: 'OrganizationGivingPeriodSummariesResult',
    organizationGivingSummariesByPeriodNew: 'OrganizationGivingPeriodSummariesNew',
    processes: 'ProcessesPagedOutput',
    queues: 'ProcessQueuesPagedOutput',
    topCommunityMemberDonorsCursor: 'CursorPagedOutputOfTopCommunityMember',
    topHouseholdDonors: 'CursorPagedOutputOfTopHousehold',
  },

  OrganizationAttendanceOverview: {
    comparisonHighlight: 'AttendanceComparisonHighlight',
    data: '[EventAttendanceSummary!]!',
    highlights: 'Attendance!',
  },

  OrganizationDetails: {
    campuses: '[Campus!]!',
    catholicEntity: 'CatholicEntity!',
    catholicSoftwareStatus: 'Boolean!',
    ecgId: 'String',
    entitlements: '[Entitlement!]!',
    isGivingPlatformActive: 'Boolean',
    isMasAppPresent: 'Boolean',
    isSalesforceCustomAppPresent: 'Boolean',
    name: 'String',
    productInformation: 'ProductInfo!',
  },

  OrganizationGivingPeriodSummariesNew: {
    communityMembersCount: 'Int!',
    householdsCount: 'Int!',
    periods: '[OrganizationGivingPeriodSummaryNew!]!',
  },

  OrganizationGivingPeriodSummariesResult: {
    communityMembersCount: 'Int!',
    householdCount: 'Int!',
    periods: '[OrganizationGivingPeriodSummary!]!',
  },

  OrganizationGivingPeriodSummary: {
    fromDate: 'LocalDate!',
    nonCashGiftsAggregate: 'MoneyAggregate!',
    paymentsAggregate: 'MoneyAggregate!',
    toDate: 'LocalDate!',
  },

  OrganizationGivingPeriodSummaryNew: {
    fromDate: 'LocalDate!',
    nonCashGiftsAggregate: 'MoneyAggregate!',
    paymentsAggregate: 'MoneyAggregate!',
    toDate: 'LocalDate!',
  },

  OrganizationGroupParticipationOverview: {
    comparisonHighlight: 'GroupParticipationComparisonHighlight',
    data: '[GroupParticipationSummary!]!',
    highlights: 'GroupParticipationHighlights!',
  },

  OrganizationPermissions: {
    campusPermissions: '[CampusPermissions!]!',
    organization: 'OrganizationDetails',
    organizationKey: 'Key!',
    permissions: '[Permission!]!',
  },

  OrganizationQueryFilterInput: {
    ccbMembershipTypeFilter: 'CcbMembershipTypeFilterInput',
    donorPlatformMerchantIds: '[Long!]',
  },

  OrganizationServingOverview: {
    comparisonHighlight: 'ServingComparisonHighlight',
    data: '[ServingOpportunitySummary!]!',
    highlights: 'ServingHighlights!',
  },

  OrganizationServingSnapshots: {
    snapshots: 'ServingSnapshots!',
  },

  PQSCursorPagingInput: {
    after: 'String',
    before: 'String',
    size: 'Int!',
  },

  PagingInput: {
    page: 'Int',
    pageSize: 'Int',
    sortings: '[SortingInput]',
  },

  PagingOutput: {
    page: 'Int!',
    pageSize: 'Int!',
    totalCount: 'Int!',
  },

  Permission: {
    name: 'String!',
    permissionKey: 'Key!',
  },

  PhoneNumber: {
    countryCode: 'String',
    createdOn: 'Instant',
    nationalNumber: 'String',
    number: 'String',
    plainNationalNumber: 'String',
    primary: 'Boolean!',
    type: 'PhoneType!',
    verified: 'Boolean!',
    verifiedOn: 'Instant',
  },

  PhoneNumberArguments: {
    countryCode: 'String',
    number: 'String!',
    primary: 'Boolean!',
    type: 'PhoneType!',
  },

  Process: {
    campus: 'chms_Campus!',
    id: 'ID!',
    name: 'String!',
  },

  ProcessQueue: {
    id: 'ID!',
    name: 'String!',
  },

  ProcessQueueSearchInput: {
    processId: 'Int!',
  },

  ProcessQueuesPagedOutput: {
    items: '[ProcessQueue!]!',
    paging: 'CursorPagingOutput!',
  },

  ProcessSearchInput: {
    id: '[Int!]',
  },

  ProcessesPagedOutput: {
    items: '[Process!]!',
    paging: 'CursorPagingOutput!',
  },

  ProductInfo: {
    chmsInformation: 'ChmsInfo!',
    streamingInformation: 'StreamingInfo!',
  },

  ProfileImages: {
    fullUrl: 'String',
    thumbnailUrl: 'String',
  },

  QueryParams: {
    crossCampusQuery: 'Boolean',
    endDate: 'Instant',
    entityKeys: '[String!]',
    filters: '[Filter!]',
    search: 'String',
    startDate: 'Instant',
  },

  RangeValidationError: {
    fieldPath: '[String!]!',
  },

  RegularExpressionValidationError: {
    fieldPath: '[String!]!',
  },

  RequiredValidationError: {
    fieldPath: '[String!]!',
  },

  ServingComparisonHighlight: {
    last: 'Int!',
    previous: 'Int',
  },

  ServingFilterValuesOutput: {
    campusNames: '[String!]!',
    categoryNames: '[String!]!',
    membershipTypeIds: '[Int!]!',
    positionNames: '[String!]!',
    teamNames: '[String!]!',
  },

  ServingHighlights: {
    averageServingOpportunities: 'Int!',
    uniqueHouseholds: 'Int!',
    uniqueIndividuals: 'Int!',
    uniquePositions: 'Int!',
    uniqueTeams: 'Int!',
  },

  ServingOpportunitySummary: {
    campusNames: '[String!]!',
    categoryNames: '[String!]!',
    endDate: 'DateTime!',
    positionNames: '[String!]!',
    scheduleStartDateTimes: '[String!]!',
    servingSummary: 'ServingSummary!',
    startDate: 'DateTime!',
    teamNames: '[String!]!',
    total: 'Int!',
  },

  ServingOverviewFiltersInput: {
    campusNames: '[String!]',
    categoryNames: '[String!]',
    membershipTypeIds: 'MembershipTypeIdsInput',
    positionNames: '[String!]',
    teamNames: '[String!]',
  },

  ServingOverviewInput: {
    dateRange: 'DateRangeInput!',
    filters: 'ServingOverviewFiltersInput',
    granularity: 'Granularity!',
  },

  ServingSnapshots: {
    availableVolunteers: 'Int!',
    servingOpportunities: 'Int!',
    servingOpportunitiesUnfilled: 'Int!',
  },

  ServingSnapshotsFiltersInput: {
    membershipTypeIds: 'MembershipTypeIdsInput',
  },

  ServingSnapshotsInput: {
    dateRange: 'DateRangeInput!',
    filters: 'ServingSnapshotsFiltersInput',
  },

  ServingSummary: {
    totalRolesFilled: 'Int!',
    uniqueHouseholds: 'Int!',
    uniqueIndividuals: 'Int!',
    uniquePositions: 'Int!',
    uniqueTeams: 'Int!',
  },

  SortingInput: {
    field: 'String!',
    order: 'SortOrder!',
  },

  StreamingInfo: {
    id: 'ID',
  },

  StringLengthValidationError: {
    fieldPath: '[String!]!',
  },

  SuccessResult: {
    success: 'Boolean',
  },

  TimeZone: {
    olsonName: 'String!',
    windowsName: 'String!',
  },

  TopCommunityMember: {
    communityMember: 'CommunityMember',
    communityMemberKey: 'String!',
    giftAmount: 'Money!',
    nonCashCount: 'Int!',
    paymentCount: 'Int!',
  },

  TopCommunityMemberQueryFilterInput: {
    dateRange: 'payment_DateRangeInput!',
  },

  TopHousehold: {
    giftAmount: 'Money!',
    household: 'Household',
    householdKey: 'String!',
    nonCashCount: 'Int!',
    paymentCount: 'Int!',
  },

  TopHouseholdQueryFilterInput: {
    dateRange: 'payment_DateRangeInput!',
  },

  UrlValidationError: {
    fieldPath: '[String!]!',
  },

  ValidationError: {
    fieldPath: '[String!]!',
  },

  ValidationErrors: {
    errors: '[IValidationError!]!',
  },

  chms_Campus: {
    id: 'ID!',
    name: 'String!',
  },

  chms_CursorPagingInput: {
    after: 'String',
    before: 'String',
    size: 'Int!',
  },

  group_participation_query_Mutation: {
    groupParticipationDeleteLastProcessCursors: 'GroupParticipationSuccessResult!',
  },

  payment_DateRangeInput: {
    endDate: 'String!',
    startDate: 'String!',
    timeZoneId: 'String',
  },
};

import * as React from 'react';

import { NoAccessIllustration } from '@pushpay/illustration';
import { Text } from '@pushpay/layout';
import { Link } from '@pushpay/link';
import { ComponentProps } from '@pushpay/types';

import { useInitializePendo } from '@src/pendo';
import useTranslation from '@src/translations';
import { getAppSettings } from '@src/utils';

import { useStyles } from './accessDeniedStyles';

type TargetLevel = 'insights' | 'page';

export interface AccessDeniedProps {
  target?: TargetLevel;
}

export type AccessDeniedComponentProps = ComponentProps<AccessDeniedProps, typeof useStyles>;

export const AccessDenied = ({ classes: classesProps, target = 'insights' }: AccessDeniedComponentProps) => {
  const classes = useStyles(classesProps);
  const { translate } = useTranslation();
  const { contactSupportUrl } = getAppSettings();
  const { initializePendo } = useInitializePendo();

  React.useEffect(() => {
    initializePendo(null);
  }, [initializePendo]);

  return (
    <div className={classes.pageContainer}>
      <div className={classes.pageContent}>
        <NoAccessIllustration classes={{ root: classes.illustration }} />
        <Text className={classes.title} type="text-4">
          {translate('errors.accessDenied')}
        </Text>
        <Text className={classes.errorText} type="text-4">
          {translate(`contactSupport.noPermissions.${target}`)}
        </Text>
        <Text className={classes.errorText} type="text-4">
          {translate('contactSupport.linkTextPrefix')}
          <Link className={classes.link} href={contactSupportUrl}>
            {translate('contactSupport.linkText')}
          </Link>
          {translate('contactSupport.linkTextSuffix')}
        </Text>
      </div>
    </div>
  );
};

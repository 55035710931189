import { useEffect, useState } from 'react';

import { featureFlagManager } from './featureFlagManager';
import { FeatureFlag } from '../../settings/featureFlags';

/**
 * Gets a feature flag value. Will trigger a re-render if that value changes.
 */
export function useFeature(featureName: FeatureFlag) {
  const [featureValue, setFeatureValue] = useState(featureFlagManager.computedFeatureValue(featureName));

  useEffect(
    function listenToFeatureFlagValueChanges() {
      return featureFlagManager.listen(() => setFeatureValue(featureFlagManager.computedFeatureValue(featureName)));
    },
    [featureName]
  );

  return featureValue;
}
